import axios from './index'
console.log('lang123');
//用户跳转过来获取指定模型文件信息
const getJumpModelFileInfo = (modelFileIds) => {
  console.log('modelFileIds',modelFileIds);
  return axios.post('app/slicePreview/getModelFileList', {
    modelFileIds
  })
}
//获取用户打印机型及配置列表
const getUserPrintInfo = () => {
  // console.log('获取用户打印机型及配置列表');
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getCurrentUserPrinterTypeList', {
    lang
  })
}

//用户新增自定义机型
const addPrinterType = (form) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/addPrinterType',{...form,lang} )
}

//上传自定义机型图片
const addPrinterPic = (formData) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/common/uploadToServer',formData)
}

//用户删除自定义机型
const delMachine = (id) => {
  return axios.get(`app/slicePreview/deletePrinterType/${id}`)
}

//用户更新自定义机型参数
const updatePrinterType = (form) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/updatePrinterType', {...form,lang})
}

//用户新增自定义配置
const addPrinterConfiguration = (form) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/addPrinterConfiguration', {...form,lang})
}

//用户删除自定义配置
const deletePrinterConfiguration = (id) => {
  return axios.get(`app/slicePreview/deletePrinterConfiguration?id=${id}`)
}

//搜索机型
const searchMachine = () => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('system/dict/data/list',{
    lang
  })
}

//获取当前用户可用模型文件列表
const getCurrentUserModelFileList = (id) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getCurrentUserModelFileList', {
    modelId: 8595072,
    lang
  })
}

// 获取当前模型相关模型文件列表
const getCurrentModelList = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getCurrentModelOtherFileList', {
    ...parmars,
    lang
  })
}

//根据模型名字搜索模型
const searchModelsByName = (search) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/searchModelFileList', {
    search,
    lang,
    pageNum: 1,
    pageSize: 10
  })
}

//上传切片时候的图片
const uploadSlicePic = (formData) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/common/uploadToServer',formData)
}

//开始切片
const slice = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/startSlice',{...parmars,lang})
}

//从oss下载获取模型
const uploadModelFromOss = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('http://120.77.245.96:9888/app/common/downloadFromServer',{parmars,lang})
}

export {
  slice,
  uploadSlicePic,
  getJumpModelFileInfo, searchModelsByName,
  getUserPrintInfo, addPrinterType, addPrinterPic, delMachine,
  updatePrinterType, addPrinterConfiguration, deletePrinterConfiguration,
  searchMachine, getCurrentUserModelFileList, getCurrentModelList,uploadModelFromOss
}