export default {
  preview: {
    modelLoading: 'Model Loading',
    setting: 'Setting',
    fold: 'Fold',
    color: 'Color',
    displayStyle: 'Material',
    default: 'Default',
    transparent: 'Transparent',
    dashed: 'Wireframe',
    background: 'Background',
    dark: 'Dark',
    light: 'Light',
    displayGrid: 'Grid',
    frontView: 'Front View',
    backView: 'Back View',
    leftSideView: 'Left Side View',
    rightSideView: 'Right Side View',
    topView: 'Top View',
    bottomView: 'Bottom View',
    close: 'Close',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    reset: 'Reset',
    markSize: 'MarkSize',
    degreeeDisplay360: 'Rotate',
    fullscreen: 'Fullscreen',
    exitFullscreen: 'ExitFullscreen',
    slices: 'Slices',
    modelSize: 'Model Size'
  },
  slicer: {
    selectSingleModel: 'Please select a single model first',
    devSel: 'Model Selection',
    resSel: 'Resin Selection',
    slicConfig: 'Slicing Configuration',
    set: 'Settings',
    move: 'Move',
    scale: 'Scale',
    rotate: 'Rotate',
    autoLayout: 'Auto Layout',
    copy: 'Copy',
    slicer: 'Slice',
    modelList: 'Model List',
    loadedModel: 'Loaded',
    total: 'Total',
    addModel:'Add Model',
    modelSink: ' Model Sink',
    center: 'Center',
    reset: 'Reset',
    actual: 'Actual',
    percentage: 'Percentage',
    lockRatio: 'Lock Ratio',
    scaleToFit: 'Scale to Fit',
    quantity: 'Quantity',
    apply: 'Apply',
    modelLoading: 'Model Loading',
    autoLayoutInPro: 'Auto Layout in Progress',
    autoLayoutComp: 'Auto Layout Complete'
  },
  printerSelect: {
    yes: 'Yes',
    no: 'No',
    title: '3D Printer Selection',
    choosePrinter: 'Choose Your 3D Printer or Add New',
    manageMachines: 'Manage Models',
    addDefineMachines: 'Add Custom Model',
    msg1: 'Custom model name cannot be empty',
    msg2: ' X-axis cannot be empty',
    msg3: 'Y-axis cannot be empty',
    msg4: 'Z-axis cannot be empty',
    msg5: 'Horizontal resolution cannot be empty',
    msg6: 'Vertical resolution cannot be empty',
    msg7: 'Please add model image',
    picSizeLimit: 'The size of the uploaded avatar image cannot exceed 2MB!',
    updateSuc: 'Update successful',
    updateFail: 'Update failed',
    truPicFormat: 'Please upload the correct image format',
    delSuc: 'Deletion successful',
    delFail: 'Deletion failed',
    addSuc: 'Addition successful',
    addFail: 'Addition failed',
    resinOptions: [
      'Standard Rigid Resin','ABS-Like Resin','Water Washable Resin','Plant-Based Resin'
    ],
    confileDialog: {
      selectConfigFile: 'Select Configuration File',
      selected: 'Selected',
      defaultConfig: 'Default Configuration',
      defineConfig: 'Custom Configuration',
      resin: 'Resin',
      layThick: 'Layer Thickness',
      allPars: 'All Parameters',
      machineType: 'Model'
    },
    resinSlicePar: {
      label: 'Resin-Slicing Parameters',
      value: 'Set Your Resin and Printing Configuration',
      saveAs: 'Save As',
    },
    printSet: {
      title: 'Print Settings',
      resinType: {
        label: 'Resin Type',
        value: ''
      }, 
      layThick: {
        label: 'Layer Thickness',
        value: ''
      },
      singLayExpTime: 'Single Layer Exposure Time',
      bottomLayExpTime: 'Bottom Layer Exposure Time',
      expDelay: 'Exposure Delay',
      bottomLayExpLay: 'Bottom Layer Exposure Layers',
      bottomLayExpTranLay: 'Bottom Lay-Exposure Transition Layers',
      liftHeight1: 'Lift Height 1',
      lifgSpeed1: ' Lift Speed 1',
      liftHeight2: 'Lift Height 2',
      lifgSpeed2: ' Lift Speed 2',
      lowerSpeed: 'Lowering Speed',
      zAxLiftAfterPrint: 'Z-Axis Lift After Print'
    },
    highSet: {
      fine: 'Fine',
      medium: 'Medium',
      coarse: 'Coarse',
      modelLiftHeight: 'Modoel Lifting Height',
      supportType: 'Support Type',
      title: 'Advanced Settings',
      innerDiaComp: 'Inner Diameter Compensation',
      outDiaComp: 'Outer Diameter Compensation',
      xAxisScaleComp: 'X-Axis Scale Compensation',
      yAxisScaleComp: 'Y-Axis Scale Compensation',
      zAxisScaleComp: 'Z-Axis Scale Compensation',
      antial: {
        label: 'Antialiasing',
        value: 'No Antialiasing at Level "1"'
      },
      autoSup: 'Automatic Supports',
      antDisAtLevel1: 'No Antialiasing at Level "1"',
      grayLevel: 'Grayscale Level',
      imgBlurPx: 'Image Blur Pixels',
      supDensity: 'Support Density',
      supDensityTip: 'The larger the value, the denser the supports added',
      addBase: 'Add Base',
      open: 'Open',
      close: 'Close'
    },
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: {
      title: 'Parameter Configuration Naming',
      placeholder: 'Enter Configuration Name within 30 Characters'
    },
    newAddMachine: 'Add Custom Model',
    machineName: 'Model Name',
    machineNamePlaceholder: 'Enter a model name within 30 characters',
    addMachinePic: 'Add Model Image',
    newAddMachineSave: 'Save',
    platSize: 'Platform Size',
    resolution: 'Resolution',
    illustration: 'Illustration',
    itHaveMonitoring: 'Monitoring Functionality',
    newAddDefineMachine: {
      title: 'Add Custom Model',
      addMachinePic: 'Add Model Image'
    },
    define: 'Customize',
    delete: 'Delete',
    updatePic: 'Change Image',
    config: {
      label: 'Select Configuration File',
      value: 'Selected',
      resin: 'Resin',
      layThick: 'Layer Thickness',
      defaultConfig: 'Default Configuration',
      defineConfig: 'Custom Configuration',
      allPar: 'All Parameters',
      machineType: 'Model',
      cancel: 'Cancel'
    },
    delDialog : {
      tip: 'Tip',
      txt: 'Do you want to delete this configuration parameter? The deletion will not be recoverable',
      confirm: 'Confirm',
      cancel: 'Cancel'
    },
    outOfBoundDialog : {
      tip: 'Tip',
      txt: 'The model is out of printer boundaries. Adjust the position or size of the model.(Adding supports to the model may potentially exceed the printing boundaries)',
      know: 'Have known'
    },
    adaptModelDialog : {
      tip: 'Tip',
      txt: 'Some models are too large or too small. Should I adjust them to the appropriate size?',
      confirm: 'Confirm',
      cancel: 'Cancel'
    },
    addModelDialog : {
       modelList: {
        label: 'Model list',
        value: 'Add a model by dragging it to the model list'
       },
       modelBase: 'Model base',
       searchModel: 'Search model',
       noResults: 'No search results are available'
    },
    excludeModelDialog: {
      tip: 'Tip',
      txt: 'Some models exceed the printer boundaries. Do you want to exclude these models during slicing?',
      confirm: 'Yes, proceed with slicing',
      cancel: 'Cancel'
    },
    delFinalSingleModelDialog: {
      tip: 'Tip',
      txt: 'Deleting will exit the slice preview. Do you want to continue?',
      yes: 'Yes',
      no: 'No'
    },
    modelHoverDialog : {
      tip: 'Tip',
      txt: 'The model is suspended, which may cause printing errors. Do you want to continue?',
      yes: 'Yes',
      no: 'No'
    }
  }
}
