<template>
  <div
    class="hello"
    ref="hello"
    v-loading="loadingCircle"
    :element-loading-text="$t('slicer.modelLoading')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="mask" v-if="isPackLayout">
      <span class="txt">{{ $t("slicer.autoLayoutInPro") }}...</span>
    </div>
    <!-- element-loading-background="rgba(0, 0, 0, 0.3)"
    v-loading="loading"
    element-loading-text="模型加载中" -->
    <!-- <el-progress
      type="circle"
      :percentage="percentage"
      v-if="isShowLoad"
    ></el-progress> -->
    <div class="print-setting">
      <!-- 机型选择 -->
      <div class="type common">
        <div class="left">
          <i class="iconfont icon-a-jixingxuanze11"></i>
          <span class="name">{{ $t("slicer.devSel") }}</span>
        </div>
        <div class="right">
          <span class="name">{{
            parmarters.configuration.printerTypeName
          }}</span>
        </div>
      </div>
      <!-- 树脂选择 -->
      <div class="resin common">
        <div class="left">
          <i class="iconfont icon-a-shuzhixuanze1"></i>
          <span class="name">{{ $t("slicer.resSel") }}</span>
        </div>
        <div class="right">
          <span class="name">{{ parmarters.configuration.resinType }}</span>
        </div>
      </div>
      <!-- 切片配置 -->
      <div class="slicer common">
        <div class="left">
          <div class="total">
            <i class="iconfont icon-qiepianpeizhi"></i>
            <span class="name">{{ $t("slicer.slicConfig") }}</span>
          </div>
          <span class="mm"
            >{{ parmarters.configuration.confName}}</span
          >
        </div>
        <div class="right">
          <!--  <div class="support">
            <span class="name">支撑</span>
            <el-switch
              v-model="form.isSupport"
              active-color="#D71518"
              inactive-color="#fff"
            >
            </el-switch>
          </div> -->
          <div class="setting" @click="isShowsetPrinter = true">
            <i class="iconfont icon-a-shezhi1"></i>
            <span class="name">{{ $t("slicer.set") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <ul>
        <!-- 移动 -->
        <li>
          <div
            class="move common-control"
            :class="{ activeControls: controls.isShowMove }"
            @click="toggleContorls('isShowMove')"
          >
            <i class="iconfont icon-yidong"></i>
            <span class="txt">{{ $t("slicer.move") }}</span>
          </div>
          <div
            class="dialog-move"
            v-show="controls.isShowMove"
            ref="moveDialog"
          >
            <div class="name">{{ $t("slicer.move") }}</div>
            <div class="move-control">
              <ul>
                <li>
                  <span class="axes">X:</span>
                  <div class="input">
                    <el-input-number
                      v-model="copyPosition.x"
                      controls-position="right"
                      @change="handleChange('X', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="mm">mm</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="handleChange('+X', $event)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="handleChange('-X', $event)"
                    ></i>
                  </div>
                </li>
                <li>
                  <span class="axes">Y:</span>
                  <div class="input">
                    <el-input-number
                      v-model="copyPosition.y"
                      controls-position="right"
                      @change="handleChange('Y', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="mm">mm</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="handleChange('+Y', $event)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="handleChange('-Y', $event)"
                    ></i>
                  </div>
                </li>
                <li>
                  <span class="axes">Z:</span>
                  <div class="input">
                    <el-input-number
                      v-model="copyPosition.z"
                      controls-position="right"
                      @change="handleChange('Z', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="mm">mm</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="handleChange('+Z', $event)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="handleChange('-Z', $event)"
                    ></i>
                  </div>
                </li>
              </ul>
            </div>
            <div class="btns">
              <div class="sink" @click="movesModel('sink')" ref="sink">
                {{ $t("slicer.modelSink") }}
              </div>
              <div class="center" @click="movesModel('center')">
                {{ $t("slicer.center") }}
              </div>
              <div class="reset" @click="movesModel('reset')">
                {{ $t("slicer.reset") }}
              </div>
            </div>
          </div>
        </li>
        <!-- 缩放 -->
        <li>
          <div
            class="scale common-control"
            :class="{ activeControls: controls.isShowScale }"
            @click="toggleContorls('isShowScale')"
          >
            <i class="iconfont icon-suofang"></i>
            <span class="txt">{{ $t("slicer.scale") }}</span>
          </div>
          <div class="dialog-scale" v-if="controls.isShowScale">
            <div class="name">{{ $t("slicer.scale") }}</div>
            <div class="scale-control-btns">
              <div
                class="real"
                @click.stop="toggleRotateSizeStyle(true)"
                :style="{
                  background: rotateControls.isShowReal ? '#fff' : '#68696D',
                  color: rotateControls.isShowReal ? '#111' : '#fff',
                }"
              >
                {{ $t("slicer.actual") }}
              </div>
              <div
                class="perct"
                @click.stop="toggleRotateSizeStyle(false)"
                :style="{
                  background: !rotateControls.isShowReal ? '#fff' : '#68696D',
                  color: !rotateControls.isShowReal ? '#111' : '#fff',
                }"
              >
                {{ $t("slicer.percentage") }}
              </div>
            </div>
            <div class="scale-control" v-if="rotateControls.isShowReal">
              <ul>
                <li>
                  <span class="axes">X:</span>
                  <div class="input">
                    <el-input-number
                      v-model="rotate.size.x"
                      controls-position="right"
                      @change="scaleChange('X', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="mm">mm</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="scaleChange('+X', rotate.size.x)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="scaleChange('-X', rotate.size.x)"
                    ></i>
                  </div>
                </li>
                <li>
                  <span class="axes">Y:</span>
                  <div class="input">
                    <el-input-number
                      v-model="rotate.size.y"
                      controls-position="right"
                      @change="scaleChange('Y', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="mm">mm</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="scaleChange('+Y', rotate.size.y)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="scaleChange('-Y', rotate.size.y)"
                    ></i>
                  </div>
                </li>
                <li>
                  <span class="axes">Z:</span>
                  <div class="input">
                    <el-input-number
                      v-model="rotate.size.z"
                      controls-position="right"
                      @change="scaleChange('Z', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="mm">mm</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="scaleChange('+Z', rotate.size.z)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="scaleChange('-Z', rotate.size.z)"
                    ></i>
                  </div>
                </li>
              </ul>
            </div>
            <div class="scale-control" v-else>
              <ul>
                <li>
                  <span class="axes">X:</span>
                  <div class="input">
                    <el-input-number
                      v-model="rotate.per.x"
                      controls-position="right"
                      @change="scalePerChange('X', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="per">%</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="scalePerChange('+X', rotate.per.x)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="scalePerChange('-X', rotate.per.x)"
                    ></i>
                  </div>
                </li>
                <li>
                  <span class="axes">Y:</span>
                  <div class="input">
                    <el-input-number
                      v-model="rotate.per.y"
                      controls-position="right"
                      @change="scalePerChange('Y', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="per">%</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="scalePerChange('+Y', rotate.per.y)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="scalePerChange('-Y', rotate.per.y)"
                    ></i>
                  </div>
                </li>
                <li>
                  <span class="axes">Z:</span>
                  <div class="input">
                    <el-input-number
                      v-model="rotate.per.z"
                      controls-position="right"
                      @change="scalePerChange('Z', $event)"
                      :precision="2"
                    ></el-input-number>
                    <span class="per">%</span>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="scalePerChange('+Z', rotate.per.z)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="scalePerChange('-Z', rotate.per.z)"
                    ></i>
                  </div>
                </li>
              </ul>
            </div>
            <div class="locking-ratio">
              <span class="name">{{ $t("slicer.lockRatio") }}</span>
              <el-switch
                v-model="switchControls.rotate"
                active-color="#626267"
                inactive-color="#fff"
                :class="{ round: switchControls.rotate }"
              >
              </el-switch>
            </div>
            <div class="btns">
              <div class="scale-adapt" @click="scaleAdapt">
                {{ $t("slicer.scaleToFit") }}
              </div>
              <div class="reset" @click="scaleReset">
                {{ $t("slicer.reset") }}
              </div>
            </div>
          </div>
        </li>
        <!-- 旋转 -->
        <li>
          <div
            class="rotate common-control"
            :class="{ activeControls: controls.isShowRotate }"
            @click="toggleContorls('isShowRotate')"
          >
            <i class="iconfont icon-xuanzhuan"></i>
            <span class="txt">{{ $t("slicer.rotate") }}</span>
          </div>
          <div class="dialog-rotate" v-show="controls.isShowRotate">
            <div class="name">{{ $t("slicer.rotate") }}</div>
            <div class="rotate-control">
              <ul>
                <li>
                  <span class="axes">X:</span>
                  <div class="input">
                    <el-input-number
                      v-model="spin.x"
                      controls-position="right"
                      @change="spinChange('X', $event)"
                      :precision="2"
                    ></el-input-number>
                    <!-- <span class="angle"></span> -->
                    <i
                      class="iconfont icon-shangjiantou special"
                      @click="spinChange('+X', spin.x + 1)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="spinChange('-X', spin.x - 1)"
                    ></i>
                    <span
                      class="add45 common"
                      @click="spinChange('+X', spin.x + 45)"
                      >+45&deg;</span
                    >
                    <span
                      class="sub45 common"
                      @click="spinChange('-X', spin.x - 45)"
                      >-45&deg;</span
                    >
                  </div>
                </li>
                <li>
                  <span class="axes">Y:</span>
                  <div class="input">
                    <el-input-number
                      v-model="spin.y"
                      controls-position="right"
                      @change="spinChange('Y', $event)"
                      :precision="2"
                    ></el-input-number>
                    <!-- <span class="angle"></span> -->
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="spinChange('+Y', spin.y + 1)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="spinChange('-Y', spin.y - 1)"
                    ></i>
                    <span
                      class="add45 common"
                      @click="spinChange('+Y', spin.y + 45)"
                      >+45&deg;</span
                    >
                    <span
                      class="sub45 common"
                      @click="spinChange('-Y', spin.y - 45)"
                      >-45&deg;</span
                    >
                  </div>
                </li>
                <li>
                  <span class="axes">Z:</span>
                  <div class="input">
                    <el-input-number
                      v-model="spin.z"
                      controls-position="right"
                      @change="spinChange('Z', $event)"
                      :precision="2"
                    ></el-input-number>
                    <!-- <span class="angle"></span> -->
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="spinChange('+Z', spin.z + 1)"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="spinChange('-Z', spin.z - 1)"
                    ></i>
                    <span
                      class="add45 common"
                      @click="spinChange('+Z', 45 + spin.z)"
                      >+45&deg;</span
                    >
                    <span
                      class="sub45 common"
                      @click="spinChange('-Z', spin.z - 45)"
                      >-45&deg;</span
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div class="reset" @click="spinReset">{{ $t("slicer.reset") }}</div>
          </div>
        </li>
        <!-- 自动布局 -->
        <li @click="packLayout()">
          <div
            class="layout common-control"
            :class="{ activeControls: controls.isShowLayout }"
            @click="toggleContorls('isShowLayout')"
          >
            <i class="iconfont icon-a-zidongbuju1"></i>
            <span class="txt">{{ $t("slicer.autoLayout") }}</span>
          </div>
        </li>
        <!-- 复制 -->
        <li>
          <div
            class="clone common-control"
            :class="{ activeControls: controls.isShowClone }"
            @click="toggleContorls('isShowClone')"
          >
            <i class="iconfont icon-fuzhi"></i>
            <span class="txt">{{ $t("slicer.copy") }}</span>
          </div>
          <div class="dialog-clone" v-show="controls.isShowClone">
            <div class="name">{{ $t("slicer.copy") }}</div>
            <div class="clone-control">
              <ul>
                <li>
                  <span class="axes">{{ $t("slicer.quantity") }}:</span>
                  <div class="input">
                    <el-input-number
                      v-model="clone.number"
                      controls-position="right"
                      @change="clone.number = $event"
                      :min="1"
                      :max="9"
                      :precision="0"
                    ></el-input-number>
                    <i
                      class="iconfont icon-shangjiantou"
                      @click="clone.number++"
                    ></i>
                    <i
                      class="iconfont icon-xiajiantou"
                      @click="clone.number--"
                    ></i>
                  </div>
                </li>
              </ul>
            </div>
            <div class="apply" @click="cloneModel">
              {{ $t("slicer.apply") }}
            </div>
          </div>
        </li>
        <!--   <li @click="selectAllModels">选中全部模型</li>
        <li @click="cancelSelectModels">取消选中的模型</li> -->
      </ul>
    </div>
    <div class="model-list" ref="modelList">
      <div class="top">
        <div class="left">
          <i class="iconfont icon-a-moxingliebiao1"></i>
          <span class="name">{{ $t("slicer.modelList") }}</span>
        </div>
        <div class="center">
          {{ $t("slicer.loadedModel") }} {{ this.objects.length }}
        </div>
        <div class="right">
          <i
            class="el-icon-arrow-up"
            v-if="isShowModelList"
            @click="toggleShowModelList(false)"
          ></i>
          <i
            class="el-icon-arrow-down"
            v-else
            @click="toggleShowModelList(true)"
          ></i>
        </div>
        <!-- <span @click="addModel">+ 添加</span> -->
      </div>
      <ul ref="ul">
        <li
          v-for="(obj, index) in objects"
          :key="index"
          :style="{
            background: obj.isSelected ? '#868585' : 'rgba(0,0,0,0)',
          }"
          @click="selectModel(index)"
        >
          <div class="icon">
            <span v-if="obj.isShow" @click.stop="showModel(obj)"
              ><i
                class="iconfont icon-bxs-show"
                :style="{
                  color: obj.isSelected ? '#fff' : '#ccc',
                }"
              ></i
            ></span>
            <span v-else @click.stop="hiddenModel(obj)"
              ><i
                class="iconfont icon-hideinvisiblehidden"
                :style="{
                  color: obj.isSelected ? '#fff' : '#ccc',
                }"
              ></i
            ></span>
          </div>
          <div
            class="pic"
            :style="{
              background: obj.isSelected ? '#fff' : 'rgba(224,222,227,0.8)',
            }"
          >
            <img :src="obj.modelInfo.pathThumb" alt="" />
          </div>
          <span
            class="name"
            :style="{
              color: obj.isSelected ? '#fff' : '#ccc',
            }"
            >{{ obj.modelInfo.name }}</span
          >
        </li>
      </ul>
      <div class="bottom" ref="addModel">
        <div class="addModel" @click="addModel">
          + {{ $t("slicer.addModel") }}
        </div>
      </div>
    </div>
    <div class="exit-edit" @click="back">
      <i class="iconfont icon-a-Frame8"></i>
    </div>
    <div class="canvas" ref="canvasDom"></div>
    <!-- <div class="setting-dialog">12345</div> -->
    <set-printer
      v-show="isShowsetPrinter"
      :dialogVisible="isShowsetPrinter"
      :activeMachineIndex="activeMachineIndex"
      @closeSetPrinterDialog="closeSetPrinterDialog"
    ></set-printer>
    <div class="slicer-btn" @click="slicer" v-if="isShowSlicerBtn">
      <i class="iconfont icon-a-qiepianpeizhi4"></i>
      <span>{{ $t("slicer.slicer") }}</span>
    </div>
    <div class="addModel">
      <el-dialog
        :visible.sync="isShowAddModelsDialog"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
      >
        <i
          class="el-icon-close"
          @click="
            isShowAddModelsDialog = false;
            searchModelName = '';
            draggedModels = [];
          "
        ></i>
        <div class="top">
          <div class="label">
            <span class="txt1">
              {{ $t("printerSelect.addModelDialog.modelList.label") }}
            </span>
            <span class="txt2">{{
              $t("printerSelect.addModelDialog.modelList.value")
            }}</span>
          </div>
          <div class="value">
            <ul ref="dragModelList">
              <li v-for="(obj, index) in objects" :key="index">
                <div class="pic">
                  <img :src="obj.modelInfo.pathThumb" alt="" />
                </div>
                <div class="des">
                  <span class="name">{{ obj.modelInfo.name }}</span>
                  <span class="size">{{ obj.modelInfo.size | sizeToKb }}</span>
                </div>
                <i class="el-icon-delete" @click="delModel(index)"></i>
              </li>
            </ul>
          </div>
        </div>
        <div class="model-base">
          <div class="title">
            {{ $t("printerSelect.addModelDialog.modelBase") }}
          </div>
          <el-input
            prefix-icon="el-icon-search"
            v-model="searchModelName"
            :placeholder="$t('printerSelect.addModelDialog.searchModel')"
            @input="searchAddModels"
          ></el-input>
          <div class="corr-models" v-if="!isShowSearchModelList">
            <ul>
              <li
                v-for="(model, index) in currentModelList"
                :key="model.id"
                ref="modelItem"
                draggable="true"
                @dragend="dragModelItem(index, model, $event)"
                :data-model-id="model.id"
              >
                <div class="pic">
                  <img :src="model.pathThumb" draggable="false" />
                </div>
                <div class="des">
                  <span class="name" draggable="false">{{ model.name }}</span>
                  <span class="size" draggable="false">{{
                    model.size | sizeToKb
                  }}</span>
                </div>
                <i class="icon-drag_2 iconfont" draggable="false"></i>
              </li>
            </ul>
          </div>
          <div class="searchList" v-else>
            <ul v-if="searchModelsList.length">
              <li
                v-for="(item, index) in searchModelsList"
                :key="index"
                @click="
                  currentModelList = item.fileRspList;
                  isShowSearchModelList = false;
                "
              >
                <div class="pic">
                  <img :src="item.modelDisplayPicUrl" alt="" />
                </div>
                <div class="modelInfo">
                  <div class="modelName">{{ item.modelName }}</div>
                  <div class="author">
                    <div class="avat">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <span class="name">{{ item.authorNickname }}</span>
                  </div>
                  <div class="like">
                    <span class="iconfont icon-icon_collect"></span>
                    <span class="count">{{ item.enjoy }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="empty" v-else>
              {{ $t("printerSelect.addModelDialog.noResults") }}
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button @click="loadAddModel">{{
            $t("printerSelect.confirm")
          }}</el-button>
        </div>
      </el-dialog>
    </div>
    <div
      class="mask"
      v-if="
        isShowModelSizeDialog ||
        isShowModelBeyondBorderDialog ||
        isShowExcludeModelDialog ||
        delFinalSingleModelDialog ||
        modelHoverDialog
      "
    >
      <!-- 模型过大或过小弹窗 -->
      <div
        class="dialog-modelSize"
        ref="modelSizeDialog"
        v-if="isShowModelSizeDialog"
      >
        <div class="tip">
          {{ $t("printerSelect.adaptModelDialog.tip") }}
          <i
            class="icon-a-guanbi2 iconfont"
            id="closeXModelSize"
            @click="isShowModelSizeDialog = false"
          ></i>
        </div>
        <div class="txt">{{ $t("printerSelect.adaptModelDialog.txt") }}</div>
        <div class="btns">
          <span class="confirm" id="confirmButtonModelSize">{{
            $t("printerSelect.adaptModelDialog.confirm")
          }}</span>
          <span class="cancel" id="closeButtonModelSize">{{
            $t("printerSelect.adaptModelDialog.cancel")
          }}</span>
        </div>
      </div>
      <!-- 有模型部分超出弹窗 -->
      <div
        class="dialog-bounding"
        ref="modelBeyondBorderDialog"
        v-if="isShowModelBeyondBorderDialog"
      >
        <div class="tip">
          {{ $t("printerSelect.outOfBoundDialog.tip") }}
          <i
            class="icon-a-guanbi2 iconfont"
            @click="isShowModelBeyondBorderDialog = false"
          ></i>
        </div>
        <div class="txt">{{ $t("printerSelect.outOfBoundDialog.txt") }}</div>
        <div class="btns">
          <span class="know" @click="isShowModelBeyondBorderDialog = false">{{
            $t("printerSelect.outOfBoundDialog.know")
          }}</span>
        </div>
      </div>
      <!-- 有模型全部超出弹窗 -->
      <div
        class="dialog-excludeModel"
        ref="excludeModelDialog"
        v-if="isShowExcludeModelDialog"
      >
        <div class="tip">
          {{ $t("printerSelect.excludeModelDialog.tip") }}
          <i
            class="icon-a-guanbi2 iconfont"
            id="closeXModelSize"
            @click="isShowExcludeModelDialog = false"
          ></i>
        </div>
        <div class="txt">{{ $t("printerSelect.excludeModelDialog.txt") }}</div>
        <div class="btns">
          <span class="confirm" id="confirmButtonisShowExcludeModelDialog">{{
            $t("printerSelect.excludeModelDialog.confirm")
          }}</span>
          <span class="cancel" id="closeButtonisShowExcludeModelDialog">{{
            $t("printerSelect.excludeModelDialog.cancel")
          }}</span>
        </div>
      </div>
      <!-- 删除只剩一个模型时提示退出弹窗 -->
      <div class="delFinalSingleModelDialog" v-if="delFinalSingleModelDialog">
        <div class="tip">
          {{ $t("printerSelect.delFinalSingleModelDialog.tip") }}
          <i
            class="icon-a-guanbi2 iconfont"
            @click="delFinalSingleModelDialog = false"
          ></i>
        </div>
        <div class="txt">
          {{ $t("printerSelect.delFinalSingleModelDialog.txt") }}
        </div>
        <div class="btns">
          <div class="confirm" @click="back()">
            {{ $t("printerSelect.delFinalSingleModelDialog.yes") }}
          </div>
          <div class="cancel" @click="delFinalSingleModelDialog = false">
            {{ $t("printerSelect.delFinalSingleModelDialog.no") }}
          </div>
        </div>
      </div>
      <div class="modelHoverDialog" v-if="modelHoverDialog">
        <div class="tip">
          {{ $t("printerSelect.modelHoverDialog.tip") }}
          <i class="icon-a-guanbi2 iconfont" ref="closeModelHoverDialog"></i>
        </div>
        <div class="txt">{{ $t("printerSelect.modelHoverDialog.txt") }}</div>
        <div class="btns">
          <div class="confirm" ref="modelHoverConfirm">
            {{ $t("printerSelect.modelHoverDialog.yes") }}
          </div>
          <div class="cancel" ref="modelHoverCancel">
            {{ $t("printerSelect.modelHoverDialog.no") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import SetPrinter from "./setPrinter.vue";
import {
  getCurrentUserModelFileList,
  getCurrentModelList,
  getJumpModelFileInfo,
  searchModelsByName,
  uploadSlicePic,
  slice,
  getUserPrintInfo,
} from "../api/getInfo";
let newLineSegments = null;
import { uuid } from "vue-uuid";
import { STLLoader1 } from "../utils/loader/PLALoader";
// import stlPath from '../../public/static/100mm_yuxi_part_2_.stl'
export default {
  name: "HelloWorld",
  components: {
    SetPrinter,
  },
  data() {
    return {
      // packLayoutMessage: null,
      message: null,
      isPackLayout: false,
      loadingCircle: true,
      isShowSlicerBtn: true,
      machines: null,
      //放不进打印机的模型
      improperModels: [],
      loadTime: null,
      totalSize: 0,
      loadModelProId: null,
      //加载模型进度条显示控制
      isShowLoad: true,
      activeMachineIndex: 0,
      group: null,
      loadingCloneModel: false,
      //切片时有模型悬空弹窗
      modelHoverDialog: false,
      isShowTransformControls: false,
      websock: null,
      //删除只剩一个模型时提示退出弹窗
      delFinalSingleModelDialog: false,
      //排除全部超出模型弹窗
      isShowExcludeModelDialog: false,
      //模型超出边界弹窗
      isShowModelBeyondBorderDialog: false,
      //模型过大或过小弹窗
      isShowModelSizeDialog: false,
      //搜索模型的定时器id
      intervalId: null,
      //保存第一次跳转过来的模型id和模型文件id数据和语言
      params: null,
      isShowSearchModelList: false,
      searchModelsList: [],
      //初始同一个模型下stl文件的id
      idList: [],
      modelId: 8357376,
      //加载模型loading
      loading: true,
      //被拖拽的元素
      draggedModels: [],
      //当前模型相关模型文件列表
      currentModelList: null,
      //搜索模型名字
      searchModelName: "",
      //显示添加模型弹窗
      isShowAddModelsDialog: false,
      //打印机切片参数
      parmarters: {
        xaxisSize: 163.92,
        yaxisSize: 102.4,
        zaxisSize: 180.0,
        verticalResolution: 2560,
        horizontalResolution: 4098,
        printerTypeId: 51,
        configuration: {
          printerTypeName: "BLOOM-760",
          confName: "BLOOM-760默认配置",
          //打印设置
          resinType: "水洗树脂+",
          layerThickness: 0.05,
          layerExposure: 8000,
          bottomExposure: 600000,
          exposureDelay: 1.0,
          bottomExposureLayers: 20,
          bottomExposureTransition: 6,
          liftingHeightOne: 3,
          liftingSpeedOne: 40,
          liftingHeightTwo: 3,
          liftingSpeedTwo: 100,
          declineSpeed: 100,
          zaxisLiftComplete: 100,
          //高级设置
          insideDiameterCompensation: 0.0,
          outsideDiameterCompensation: 0.0,
          xaxisScaleCompensation: 0.0,
          yaxisScaleCompensation: 0.0,
          zaxisScaleCompensation: 0.0,
          antiAliasingLevel: 3,
          grayLevel: 1,
          imageBlurPixel: 2,
          isAutoSupport: 1, //是否自动支撑(0:否，1:是)
          supportDensity: 50,
          isAddBase: 1, //是否添加底座(0:否，1:是)
        },
      },
      //展示打印机的设置
      isShowsetPrinter: false,
      //复制的数目
      clone: {
        number: 0,
      },
      // 旋转
      spin: {
        x: 0,
        y: 0,
        z: 0,
      },
      controls: {
        isShowMove: false,
        isShowScale: false,
        isShowRotate: false,
        isShowLayout: false,
        isShowClone: false,
        rotate: {
          isShowReal: true,
          isShowPer: false,
        },
      },
      rotateControls: {
        isShowReal: true,
        isShowPer: false,
      },
      switchControls: {
        rotate: false,
      },
      //缩放尺寸和比例
      rotate: {
        size: {
          x: 0,
          y: 0,
          z: 0,
        },
        per: {
          x: 100,
          y: 100,
          z: 100,
        },
      },
      //控制模型列表显示
      isShowModelList: true,
      form: {
        isSupport: false,
      },
      value1: "BIOOM 760",
      value: 0,
      scene: null,
      camera: null,
      renderer: null,
      gridHelper: null,
      axes: null,
      exterGeometry: null,
      orbitControls: null,
      transformControls: null,
      light: null,
      loader: null,
      publicPath: process.env.BASE_URL,
      percentage: 0,
      objects: [],
      modelMap: new Map(),
      printerSize: {
        x: 163.91,
        y: 102.4,
        z: 150.0,
      },
      modelSize: null,
      slider: {
        step: 1.0,
        min: -50,
      },
      position: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      copyPosition: { x: 0, y: 0, z: 0 },
      checked: false,
      modelList: [],
      color: {
        unChecked: [186 / 255, 186 / 255, 186 / 255],
        checked: [255 / 255, 121 / 255, 125 / 255],
        over: [128 / 255, 0, 1],
      },
    };
  },
  async mounted() {
    let lang = localStorage.getItem("lang");
    if (lang === "ja") {
      /* //移动
      this.$refs.moveDialog.style = 'width: 225px'
      this.$refs.sink.style = 'width: 90px' */
    }
    //初始化
    this.init();
    console.log("STLLoader1", STLLoader1);
    this.getJumpModelFileInfo();
    //获取默认切片机型参数
    await this.getUserPrintInfo();
    this.initGridHelper();
    this.initExterGeometry();
    this.initAxes();
    //获取模型数据
    // this.getCurrentUserModelFileList();

    // this.initWebsocket();
  },
  methods: {
    getText(text) {
      console.log(text);
      return text;
    },
    //如果没有被选中的模型点击操作弹窗要选中模型
    hintSelectModel() {
      if (this.message) {
        this.message.close();
      }
      const selectedModel = this.objects.find((model) => model.isSelected);
      if (selectedModel) {
        return selectedModel;
      } else {
        this.message = this.$message({
          message: this.$t("slicer.selectSingleModel"),
          type: "warning",
        });
        // console.log('message',message.close());
        return false;
      }
    },
    back() {
      window.parent.postMessage("close", "*");
    },
    getModlBoxFromObject(selectedModel, isPrecise = false) {
      // console.log("selectedModel.rotation", selectedModel.rotation.x);
      let { x, y, z } = selectedModel.rotation;
      if (x === 0 && (y === 0) & (z === 0)) {
        isPrecise = false;
      }
      return new THREE.Box3().setFromObject(selectedModel, isPrecise);
    },
    //模型操作时让模型最低点一直贴地
    modelContactFloor(selectedModel) {
      const box = this.getModlBoxFromObject(selectedModel, true);
      selectedModel.position.z -= box.min.z;
      const lineSegments = this.modelMap.get(selectedModel);
      this.scene.remove(lineSegments);
      const size = this.getModelSize(selectedModel, true);
      console.log("modelContactFloor-size", size);
      const newLineSegments = this.getBoundingBox(size, selectedModel);
      this.modelMap.set(selectedModel, newLineSegments);
      this.scene.add(newLineSegments);
      newLineSegments.position.set(
        box.max.x * 0.5 + box.min.x * 0.5,
        box.max.y * 0.5 + box.min.y * 0.5,
        (box.max.z - box.min.z) * 0.5
      );
      //  console.log(aa);
      // var boundingBox = new THREE.Box3().setFromObject(selectedModel);
    },
    //获取默认切片机型参数
    async getUserPrintInfo() {
      const res = await getUserPrintInfo();
      this.machines = res.rows;
      let data = res.rows[0];
      this.printerSize = {
        x: data.xaxisSize,
        y: data.yaxisSize,
        z: data.zaxisSize,
      };
      let {
        xaxisSize,
        yaxisSize,
        zaxisSize,
        verticalResolution,
        horizontalResolution,
      } = data;
      this.parmarters = {
        xaxisSize,
        yaxisSize,
        zaxisSize,
        verticalResolution,
        horizontalResolution,
        printerTypeId: data.id,
        configuration: data.configurationList[0],
      };
      console.log(
        "默认切片机型参数",
        this.printerSize,
        this.parmarters.configuration.resinType
      );
    },

    //
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    //判断是否有模型超出边界
    isHasModelByBod() {
      console.log("parmarters", this.parmarters);
      let res = {
        isShowExcludeModelDialog: false,
        isShowModelBeyondBorderDialog: false,
      };
      for (let model of this.objects) {
        var modelBoundingBox = this.getModlBoxFromObject(model, true);
        var limitBox = new THREE.Box3();
        let min = {
          x: -this.printerSize.x / 2,
          y: -this.printerSize.y / 2,
          z: 0,
        };
        let max = {};
        if (this.parmarters.configuration.autoSupport) {
          max = {
            x: this.printerSize.x / 2,
            y: this.printerSize.y / 2,
            z:
              this.printerSize.z -
              this.parmarters.configuration.modelLiftingHeight,
          };
        } else {
          max = {
            x: this.printerSize.x / 2,
            y: this.printerSize.y / 2,
            z: this.printerSize.z,
          };
        }

        limitBox.set(min, max);
        //通过模型抬升高度判断是否超出
        const copyBox = new THREE.Box3();
        copyBox.copy(modelBoundingBox);
        const resBox = copyBox.intersect(limitBox);
        console.log(
          "resBox.equals(modelBoundingBox)",
          resBox.equals(modelBoundingBox),
          resBox
        );
        if (resBox.containsBox(modelBoundingBox)) {
          //模型没有超出
          console.log("模型没有超出");
          model.userData.isShowExcludeModelDialog = false;
        } else {
          //判断模型是部分超出还是全部超出
          if (resBox.max.x === -Infinity && resBox.min.x === Infinity) {
            //模型全部超出
            console.log("模型全部超出");
            model.userData.isShowExcludeModelDialog = true;
            res.isShowExcludeModelDialog = true;
          } else {
            //模型部分超出
            console.log("模型部分超出");
            res.isShowModelBeyondBorderDialog = true;
          }
        }
      }
      //判断是否所有的模型全部都超出
      if (
        this.objects.every((model) => model.userData.isShowExcludeModelDialog)
      ) {
        res.isShowModelBeyondBorderDialog = true;
      }
      return res;
    },
    async slicer() {
      let res = this.isHasModelByBod();
      if (res.isShowModelBeyondBorderDialog) {
        return (this.isShowModelBeyondBorderDialog = true);
      } else if (res.isShowExcludeModelDialog) {
        this.isShowExcludeModelDialog = true;
        const isSlice = await new Promise((resolve) => {
          this.$nextTick(() => {
            const excludeModelDialog = this.$refs.excludeModelDialog;
            if (excludeModelDialog) {
              excludeModelDialog.addEventListener("click", (e) => {
                //用户点击是，去切片
                if (e.target.id === "confirmButtonisShowExcludeModelDialog") {
                  this.isShowExcludeModelDialog = false;
                  resolve(true);
                } else if (
                  e.target.id === "closeButtonisShowExcludeModelDialog"
                ) {
                  this.isShowExcludeModelDialog = false;
                  resolve(false);
                }
              });
            }
          });
        });
        if (!isSlice) return;
      }

      let modelHover = false;
      console.log(
        "this.parmarters.configuration",
        this.parmarters.configuration
      );
      if (!this.parmarters.configuration.autoSupport) {
        this.parmarters.configuration.isAutoSupport = 0;
        //判断是否有模型悬空
        modelHover = this.objects.some((model) => {
          const box = this.getModlBoxFromObject(model, true);
          console.log("box", box);
          return box.min.z !== 0;
        });
      } else {
        modelHover = false;
        this.parmarters.configuration.isAutoSupport = 1;
        if (this.parmarters.configuration.addBase) {
          this.parmarters.configuration.isAddBase = 1;
        } else {
          this.parmarters.configuration.isAddBase = 0;
        }
      }
      if (modelHover) {
        this.modelHoverDialog = true;
        let isSlicer = await new Promise((resolve) => {
          this.$nextTick(() => {
            this.$refs.modelHoverConfirm.addEventListener("click", () => {
              this.modelHoverDialog = false;
              resolve(true);
            });
            this.$refs.modelHoverCancel.addEventListener("click", () => {
              this.modelHoverDialog = false;
              resolve(false);
            });
            this.$refs.closeModelHoverDialog.addEventListener("click", () => {
              this.modelHoverDialog = false;
              resolve(false);
            });
          });
        });
        if (!isSlicer) {
          return;
        }
      }
      //在拍照前将除网格内的模型其它物体进行隐藏
      this.objects.forEach((model) => {
        if (model.userData.isShowExcludeModelDialog) {
          model.visible = false;
        }
        if (model.isSelected) {
          this.cancelSelectModels();
        }
      });
      this.exterGeometry.visible = false;
      this.gridHelper.visible = false;
      this.axes.visible = false;
      this.isShowSlicerBtn = false;
      let size = this.printerSize;
      const radius = Math.sqrt(
        (size.x / 2) * (size.x / 2) +
          (size.y / 2) * (size.y / 2) +
          (size.z / 2) * (size.z / 2)
      );
      if (radius < size.y / 2) {
        radius = size.y / 2;
      }
      this.camera.position.z = this.printerSize.z / 2;
      // this.camera.position.y = -radius * 3;
      this.camera.position.y = -radius * 1.3;
      this.camera.position.x = 0;
      setTimeout(async () => {
        //切片时拍照获得预览照
        const canvas = document.getElementsByTagName("canvas")[0];
        const image = canvas.toDataURL("image/png");
        /* const a = document.createElement("a");
        a.href = image.replace(
          /^data:image\/[^;]/,
          "data:application/octet-stream"
        );
          const base64Data = a.href.split(",")[1]; // 提取Base64数据部分
        const binaryData = window.atob(base64Data); // 解码Base64数据为二进制数据
        const blob = new Blob([binaryData], { type: "application/octet-stream" });
        console.log('blob',blob);
        a.download = "image.png";
        a.click(); */
        const imgFile = this.base64ImgtoFile(image, uuid.v1());
        const formData = new FormData();
        formData.append("file", imgFile);
        const uploadPicRes = await uploadSlicePic(formData);
        let picId = uploadPicRes.data.id;
        console.log("params", this.machines, this.activeMachineIndex);
        const params = {
          fileList: [],
          previewPicId: picId,
          printerTypeId: this.machines[this.activeMachineIndex].id,
          printerConfiguration: this.parmarters.configuration,
          lang: this.params.lang,
        };
        if (params.printerConfiguration) {
          delete params.printerConfiguration.printerTypeName;
        }
        for (let model of this.objects) {
          if (!model.userData.isShowExcludeModelDialog) {
            params.fileList.push({
              fileId: model.modelInfo.id,
              matrix: model.matrixWorld.transpose().elements,
              modelId: model.modelInfo.modelId,
            });
          }
        }
        // this.websock.send(JSON.stringify(params));
        //调取切片上传接口
        const resSlice = await slice(params);
        if (resSlice.code !== 200) {
          if (this.message) {
            this.message.close();
          }
          this.message = this.$message({
            message: "切片失败:" + resSlice.msg,
            type: "error",
          });
          this.objects.forEach((model) => {
            if (model.userData.isShowExcludeModelDialog) {
              model.visible = true;
            }
            if (model.isSelected) {
              this.cancelSelectModels();
            }
          });
          this.exterGeometry.visible = true;
          this.gridHelper.visible = true;
          this.axes.visible = true;
          this.isShowSlicerBtn = true;
          return;
        }
        window.parent.postMessage("slice", "*");
      }, 500);
    },
    //搜索添加的模型
    searchAddModels(keywords) {
      if (keywords) {
        if (this.intervalId) {
          clearTimeout(this.intervalId);
        }
        this.intervalId = setTimeout(async () => {
          const res = await searchModelsByName(keywords);
          console.log("resRRR", res);
          for (let row of res.rows) {
            for (let fileRsp of row.fileRspList) {
              fileRsp.modelId = row.modelId;
            }
          }
          this.searchModelsList = res.rows;
          console.log("resRRR", res);
          this.isShowSearchModelList = true;
        }, 500);
      } else {
        if (this.intervalId) {
          clearTimeout(this.intervalId);
        }
        this.isShowSearchModelList = false;
        this.getCurrentModelList();
      }
    },
    //删除模型
    delModel(index) {
      if (this.objects.length <= 1) {
        return (this.delFinalSingleModelDialog = true);
      }
      console.log(this.objects);
      const model = this.objects[index];
      const lineSegments = this.modelMap.get(model);
      this.scene.remove(lineSegments);
      this.scene.remove(model);
      this.objects.splice(index, 1);
      if (this.message) {
        this.message.close();
      }
      this.message = this.$message.success(this.$t("printerSelect.delSuc"));
      if (model === this.transformControls.object) {
        this.transformControls.detach();
        this.transformControls.attach(this.objects[0]);
        this.transformControls.visible = false;
        this.cancelSelectModels();
      }
    },
    modelsAdaptPrinter() {
      //需要适应打印机尺寸的模型
      let adaptModel = [];
      this.objects.forEach((model) => {});
    },
    //让不同尺寸的模型自适应打印机尺寸
    async adaptPrint(model) {
      // 获取模型的最大最小坐标
      const box = this.getModlBoxFromObject(model, true);
      console.log("initModelSizeBox", box);
      const min = box.min;
      const max = box.max;
      //计算模型尺寸
      const size = new THREE.Vector3();
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      model.initSize = { ...size };
      console.log("model.initSize", model.initSize);
      let xScale = this.printerSize.x / size.x;
      let yScale = this.printerSize.y / size.y;
      let zScale = this.printerSize.z / size.z;
      //判断模型是否超出打印机大小
      if (xScale <= 1 || yScale <= 1 || zScale <= 1) {
        this.isShowModelSizeDialog = true;
        let res = await new Promise((resolve, reject) => {
          this.$nextTick().then(() => {
            const modelSizeDialog = this.$refs.modelSizeDialog;
            if (modelSizeDialog) {
              //判断用户点击的是取消还是确定
              modelSizeDialog.addEventListener(
                "click",
                (e) => {
                  console.log("e-modelSizeDialog", e.target);
                  if (e.target.id === "closeXModelSize") {
                    console.log("x");
                    this.isShowModelSizeDialog = false;
                    model.size = { ...model.initSize };
                    resolve();
                  } else if (e.target.id === "closeButtonModelSize") {
                    console.log("close");
                    model.size = { ...model.initSize };
                    this.isShowModelSizeDialog = false;
                    resolve();
                  } else if (e.target.id === "confirmButtonModelSize") {
                    console.log("confirm");
                    this.isShowModelSizeDialog = false;
                    let minScale = Math.min(xScale, yScale, zScale) * 0.6;
                    console.log("x-y-z", xScale, yScale, zScale);
                    model.scale.set(minScale, minScale, minScale);
                    size.x *= minScale;
                    size.y *= minScale;
                    size.z *= minScale;
                    model.size = { ...size };
                    console.log("model??", model);
                    const box = new THREE.Box3().setFromObject(model, true);
                    console.log("modelBoxSize", box);
                    this.packLayout();
                    resolve();
                  }
                },
                false
              );
            }
          });
        });
        console.log("res", res);
      }
      return size;
    },
    //预览或者切片跳转过来获取指定模型文件信息
    async getJumpModelFileInfo() {
      console.log("getModel");
      //在跳转到该页面时拿去模型id和模型文件id数组和语言
      //进行解码
      this.params = JSON.parse(decodeURIComponent(this.$route.query.params));
      /*  let lang = this.params.lang;
      console.log("lang", this.params);
      //传过来的参数lang
      if (lang === "en_US") {
        lang = "en";
      } else if(lang === 'ja_JP') {
        lang = 'ja'
      } else {
        lang = "zh";
      }
      let localLang = localStorage.getItem("lang") || "zh";
      if (lang !== localLang) {
        localStorage.setItem("lang", lang);
        location.reload();
      }
      console.log("this.params", this.params); */
      // this.params.modelId = 86503582945280
      localStorage.setItem("token", this.params.token);
      const res = await getJumpModelFileInfo(this.params.modelFileIds);
      console.log("res", res);
      for (let model of res.rows) {
        model.modelId = this.params.modelId;
        // this.totalSize = model.size;
      }
      console.log("resParams", res);
      const promises = [];
      for (let modelInfo of res.rows) {
        this.totalSize += modelInfo.size;
        promises.push(
          new Promise(async (resolve, reject) => {
            /* fetch(modelInfo.url)
              .then((response) => {
                console.log("response", response);
                console.log("percentage", this.percentage);
                this.loadModelProId = setInterval(() => {
                  if (this.percentage < 99) {
                    this.percentage++;
                  }
                }, 100);
                return response.blob();
              })
              .then((blob) => {
                const blobUrl = URL.createObjectURL(blob);
                // 在此处使用临时URL进行操作，例如显示图像
                this.loadStlModel(blobUrl, modelInfo);
                resolve();
                // 在使用完临时URL后记得释放资源
                // URL.revokeObjectURL(blobUrl);
              })
              .catch((error) => {
                console.error("Failed to fetch the file:", error);
              }); */
            let modelPromise = await this.loadStlModel(
              modelInfo.url,
              modelInfo,
              resolve,
              reject
            );
            console.log("modelPromise", modelPromise);
            this.idList.push(modelInfo.id);
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          console.log("loading", this.loading);
          this.isShowLoad = false;
          this.loadingCircle = false;
          this.packLayout();
        })
        .catch((err) => {
          if (this.message) {
            this.message.close();
          }
          this.message = this.$message({
            showClose: true,
            message: "模型加载失败",
            type: "error",
          });
          console.log("window.parent", window.parent);
          setTimeout(() => {
            window.parent.postMessage("close", "*");
          }, 1000);
        });
    },
    //下载添加的模型然后添加到场景中
    loadAddModel() {
      console.log("draggedModel", this.draggedModels);
      if (this.draggedModels.length) {
        this.loading = true;
        this.loadingCircle = true;
        const promises = [];
        for (let obj of this.draggedModels) {
          console.log("obj", obj);
          if (this.draggedModels.length) {
            promises.push(
              new Promise(async (resolve, reject) => {
                console.log("obj.url", obj.url);
                await this.loadStlModel(obj.url, obj, resolve, reject);
              })
            );
          }
        }
        Promise.all(promises)
          .then((p1, p2, p3) => {
            console.log("p", p1, p2, p3);
            console.log("loading", this.loading);
            this.loading = false;
            this.loadingCircle = false;
            this.packLayout();
          })
          .catch((err) => {
            this.loadingCircle = false;
            if (this.message) {
              this.message.close();
            }
            this.message = this.$message.error("模型加载失败");
          });
      }
      this.draggedModels = [];
      this.isShowAddModelsDialog = false;
      this.searchModelName = "";
    },
    //拖拽模型当前模型相关模型
    dragModelItem(index, model, e) {
      console.log("e", e);
      const parentEle = e.target.parentNode;
      // parentEle.removeChild(e.target)
      const domEle = e.target.cloneNode(true);
      //判断在拖拽元素时放下鼠标时的位置时是否能够放置
      if (e.dataTransfer.dropEffect != "none") {
        this.$refs.dragModelList.appendChild(domEle);
        this.draggedModels.push(model);
      }
    },
    //获取当前模型相关模型文件列表
    async getCurrentModelList() {
      const res = await getCurrentModelList(this.params);
      if (res.rows) {
        for (let model of res.rows) {
          model.modelId = this.modelId;
        }
        this.currentModelList = res.rows;
      }

      console.log("currentModelList", res);
    },
    async getCurrentUserModelFileList() {
      // const res = await getCurrentUserModelFileList();
      // console.log("data", res);
    },
    //关闭设置弹窗
    async closeSetPrinterDialog(pararmsForm, index, activeMachineIndex) {
      this.isShowsetPrinter = false;
      //重新调用获取打印机接口，防止添加新的机型后没有更新
      const res = await getUserPrintInfo();
      this.machines = [...res.rows];
      if (pararmsForm) {
        console.log("pararmsForm", pararmsForm);
        console.log("configIndex", index);
        this.parmarters = { ...pararmsForm };
        this.parmarters.configuration = pararmsForm.configurationList[index];
        if (this.activeMachineIndex !== activeMachineIndex) {
          this.printerSize = {
            x: pararmsForm.xaxisSize,
            y: pararmsForm.yaxisSize,
            z: pararmsForm.zaxisSize,
          };
          console.log("不等于");
          this.group.remove(this.gridHelper);
          this.group.remove(this.axes);
          this.group.remove(this.exterGeometry);
          for (let obj of this.objects) {
            obj.material = this.getShaderMaterial();
          }
          this.initGridHelper();
          this.initExterGeometry();
          this.initAxes();
          this.activeMachineIndex = activeMachineIndex;
          let size = this.printerSize;
          const radius = Math.sqrt(
            (size.x / 2) * (size.x / 2) +
              (size.y / 2) * (size.y / 2) +
              (size.z / 2) * (size.z / 2)
          );
          if (radius < size.y / 2) {
            radius = size.y / 2;
          }
          this.camera.position.z = this.printerSize.z / 2;
          this.camera.position.y = -radius * 3;
          this.camera.position.x = 0;
          this.packLayout();
        }
        //判断是否切片了打印机 需要重新布局模型
        // delete this.parmarters.configurationList;
        /* console.log("this.parmarters", this.parmarters);
        console.log("关闭设置后修改的参数", this.parmarters); */
      }
    },
    //通过模型列表选中模型
    selectModel(index) {
      const model = this.objects[index];
      this.cancelSelectModels();
      model.isSelected = true;
      const size = this.getModelSize(model);
      const lineSegments = this.modelMap.get(model);
      lineSegments.visible = true;
      this.scene.add(lineSegments);
      //设置位置
      this.position = model.position;
      this.copyPosition = { ...this.position };
      this.copyPosition.z -= size.z / 2;
      this.rotate.size = this.getModelSize(model, true);
      this.transformControls.attach(model);
      model.material.uniforms.color.value.set(...this.color.checked);
    },

    // 旋转
    spinChange(axes, val) {
      console.log("val", val);
      if (val === undefined) return;
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      console.log(axes, val);
      if (selectedModel) {
        switch (axes) {
          case "+X":
            this.handelSpinChange("+", "x", val, selectedModel);
            break;
          case "-X":
            this.handelSpinChange("-", "x", val, selectedModel);
            break;
          case "+Y":
            this.handelSpinChange("+", "y", val, selectedModel);
            break;
          case "-Y":
            this.handelSpinChange("-", "y", val, selectedModel);
            break;
          case "+Z":
            this.handelSpinChange("+", "z", val, selectedModel);
            break;
          case "-Z":
            this.handelSpinChange("-", "z", val, selectedModel);
            break;
          case "X":
            this.handelSpinChange(null, "x", val, selectedModel);
            break;
          case "Y":
            this.handelSpinChange(null, "y", val, selectedModel);
            break;
          case "Z":
            this.handelSpinChange(null, "z", val, selectedModel);
            break;
        }
      }
    },
    handelSpinChange(sign, axes, value, selectedModel) {
      console.log("val", value);
      if (sign === "+" && value !== undefined) {
        this.spin[axes] = value;
      } else if (sign === "+" && value === undefined) {
        this.spin[axes]++;
      } else if (sign === "-" && value !== undefined) {
        this.spin[axes] = value;
      } else if (sign === "-" && value === undefined) {
        this.spin[axes]--;
      } else {
        this.spin[axes] = value;
      }
      selectedModel.rotation[axes] = Math.PI * (this.spin[axes] / 180);
      this.modelContactFloor(selectedModel);
    },
    //旋转重置
    spinReset() {
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        this.spin = {
          x: 0,
          y: 0,
          z: 0,
        };
        selectedModel.rotation.set(0, 0, 0);
        this.modelContactFloor(selectedModel);
      }
    },
    getSpinEluerAngel(model) {
      let selectedModel = this.objects.find((obj) => obj.isSelected);
      console.log("spinSelect", selectedModel);
      if (model) {
        selectedModel = model;
      }
      if (selectedModel) {
        var euler = new THREE.Euler().setFromQuaternion(
          selectedModel.quaternion,
          "XYZ"
        );
        var degX = THREE.MathUtils.radToDeg(euler.x);
        var degY = THREE.MathUtils.radToDeg(euler.y);
        var degZ = THREE.MathUtils.radToDeg(euler.z);
        console.log("选择");
        return {
          x: degX,
          y: degY,
          z: degZ,
        };
      } else {
        return {
          x: 0,
          y: 0,
          z: 0,
        };
      }
    },
    //缩放以适应
    scaleAdapt() {
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        let lineSegments = this.modelMap.get(selectedModel);
        this.scene.remove(lineSegments);
        let realSize = this.getModelSize(selectedModel, true);
        let xScale = this.printerSize.x / realSize.x;
        let yScale = this.printerSize.y / realSize.y;
        let zScale = this.printerSize.z / realSize.z;
        console.log("scale", xScale, yScale, zScale);
        let min = Math.min(xScale, yScale, zScale) * 0.999;
        // console.log();
        xScale = min * selectedModel.scale.x;
        yScale = min * selectedModel.scale.y;
        zScale = min * selectedModel.scale.z;
        console.log("initSize", selectedModel.initSize);
        console.log("scale", xScale, yScale, zScale);
        // min = Math.min(xScale, yScale, zScale);
        console.log(xScale, yScale, zScale);
        console.log("selectedModel", selectedModel);
        selectedModel.scale.set(xScale, yScale, zScale);
        const size = this.getModelSize(selectedModel, true);
        // const newLineSegments = this.getBoundingBox(size, selectedModel)
        // const box2 = this.getModlBoxFromObject(selectedModel, true);
        // this.scene.add(newLineSegments)
        // newLineSegments.position.set(
        //   box2.max.x * 0.5 + box2.min.x * 0.5,
        //   box2.max.y * 0.5 + box2.min.y * 0.5,
        //   box2.max.z * 0.5 + box2.min.z * 0.5,
        // );
        // this.modelMap.set(selectedModel,newLineSegments)
        console.log("size", size);
        this.rotate.size = { ...size };
        this.rotate.per = {
          x: min * 100,
          y: min * 100,
          z: min * 100,
        };
        const box = this.getModlBoxFromObject(selectedModel, true);
        selectedModel.position.set(
          selectedModel.position.x - box.getCenter(new THREE.Vector3()).x,
          selectedModel.position.y - box.getCenter(new THREE.Vector3()).y,
          selectedModel.position.z - box.min.z
        );
        this.copyPosition = { x: 0, y: 0, z: 0 };
        this.modelContactFloor(selectedModel);
      }
    },
    //通过缩放传入直接尺寸的值进行模型缩放
    scaleModelBySize(sign, axes, value, selectedModel) {
      if (sign === "+") {
        this.rotate.size[axes]++;
      } else if (sign === "-") {
        this.rotate.size[axes]--;
      } else {
        this.rotate.size[axes] = value;
      }
      /* console.log("this.rotate.size[axes]", this.rotate.size[axes]);
      console.log("selectedModel.size[axes]", selectedModel.size[axes]); */
      this.rotate.per[axes] =
        (this.rotate.size[axes] / selectedModel.initSize[axes]) * 100;
      console.log("this.rotate.per[axes]", this.rotate.per[axes]);
      if (this.switchControls.rotate) {
        this.rotate.per.x = this.rotate.per[axes];
        this.rotate.per.y = this.rotate.per[axes];
        this.rotate.per.z = this.rotate.per[axes];
      }
      // console.log('实际缩放',);
      selectedModel.scale.set(
        this.rotate.per.x / 100,
        this.rotate.per.y / 100,
        this.rotate.per.z / 100
      );
      let size = this.getModelSize(selectedModel, true);
      this.rotate.size = { ...size };
      this.afterChange();
    },
    //通过缩放传入不同的百分比的值进行缩放模型
    scaleModelByPer(sign, axes, value, selectedModel) {
      if (sign === "+" && axes) {
        this.rotate.per[axes]++;
      } else if (sign === "-" && axes) {
        this.rotate.per[axes]--;
      } else {
        this.rotate.per[axes] = value;
      }
      if (this.switchControls.rotate) {
        this.rotate.size.x = this.rotate.size[axes];
        this.rotate.size.y = this.rotate.size[axes];
        this.rotate.size.z = this.rotate.size[axes];
        this.rotate.per.x = this.rotate.per[axes];
        this.rotate.per.y = this.rotate.per[axes];
        this.rotate.per.z = this.rotate.per[axes];
      }
      let lineSegments = this.modelMap.get(selectedModel);
      this.scene.remove(lineSegments);
      selectedModel.scale.set(
        this.rotate.per.x / 100,
        this.rotate.per.y / 100,
        this.rotate.per.z / 100
      );
      this.afterChange();
    },
    //设置缩放模型百分比
    scalePerChange(axes, val) {
      if (val === undefined) return;
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        switch (axes) {
          case "+X":
            this.scaleModelByPer("+", "x", null, selectedModel);
            break;
          case "-X":
            this.scaleModelByPer("-", "x", null, selectedModel);
            break;
          case "+Y":
            this.scaleModelByPer("+", "y", null, selectedModel);
            break;
          case "-Y":
            this.scaleModelByPer("-", "y", null, selectedModel);
            break;
          case "+Z":
            this.scaleModelByPer("+", "z", null, selectedModel);
            break;
          case "-Z":
            this.scaleModelByPer("-", "z", null, selectedModel);
            break;
          case "X":
            this.scaleModelByPer(null, "x", Number(val), selectedModel);
            break;
          case "Y":
            this.scaleModelByPer(null, "y", Number(val), selectedModel);
            break;
          case "Z":
            this.scaleModelByPer(null, "z", Number(val), selectedModel);
            break;
        }
      }
    },
    //缩放重置
    scaleReset() {
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      console.log("selectedModel.size", selectedModel.size);
      if (selectedModel) {
        this.rotate.per = {
          x: 100,
          y: 100,
          z: 100,
        };
        selectedModel.scale.set(1, 1, 1);
        this.rotate.size = { ...selectedModel.initSize };
        this.modelContactFloor(selectedModel);
      }
    },
    //在缩放中切换实际尺寸和百分比
    toggleRotateSizeStyle(flag) {
      this.rotateControls.isShowReal = flag;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (flag && selectedModel) {
        //获取选中模型实际尺寸
        const size = this.getModelSize(selectedModel, true);
        this.rotate.size = { ...size };
      } else if (!flag && selectedModel) {
        const size = this.getModelSize(selectedModel, true);
        console.log("切换到百分比时模型实际大小", size);
        console.log("切换到百分比时模型初始大小", selectedModel.initSize);
        this.rotate.per = {
          x: (size.x / selectedModel.initSize.x) * 100,
          y: (size.y / selectedModel.initSize.y) * 100,
          z: (size.z / selectedModel.initSize.z) * 100,
        };
        /* this.rotate.per = { ...selectedModel.scale };
        for (let key of Object.keys(this.rotate.per)) {
          this.rotate.per[key] = this.rotate.per[key] * 100;
        } */
      }
    },
    //模型scale尺寸的改变
    scaleChange(axes, val) {
      console.log(axes, val);
      if (val === undefined) return;
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        const size = this.getModelSize(selectedModel, true);
        let lineSegments = this.modelMap.get(selectedModel);
        this.scene.remove(lineSegments);
        let perctange;
        switch (axes) {
          case "X":
            this.scaleModelBySize(null, "x", val, selectedModel);
            break;
          case "Y":
            this.scaleModelBySize(null, "y", val, selectedModel);
            break;
          case "Z":
            this.scaleModelBySize(null, "z", val, selectedModel);
            break;
          case "+X":
            this.scaleModelBySize("+", "x", null, selectedModel);
            break;
          case "-X":
            this.scaleModelBySize("-", "x", null, selectedModel);
            break;
          case "+Y":
            this.scaleModelBySize("+", "y", null, selectedModel);
            break;
          case "-Y":
            this.scaleModelBySize("-", "x", null, selectedModel);
            break;
          case "+Z":
            this.scaleModelBySize("+", "z", null, selectedModel);
            break;
          case "-Z":
            this.scaleModelBySize("-", "z", null, selectedModel);
            break;
        }
      }
    },
    //设置点击缩放模型的大小
    setModelRotateSize() {
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        const size = this.getModelSize(selectedModel, true);
        console.log("目前size", size);
        console.log("initSize", selectedModel.initSize);
        this.rotate.size = { ...size };
        this.rotate.per.x = (size.x / selectedModel.initSize.x) * 100;
        this.rotate.per.y = (size.y / selectedModel.initSize.y) * 100;
        this.rotate.per.z = (size.z / selectedModel.initSize.z) * 100;
        console.log("百分比", this.rotate.per);
      }
    },
    // -----------------------------------

    //移动、缩放...排他
    toggleContorls(prop) {
      const box3 = this.getModlBoxFromObject(this.objects[0], true);
      let center = {
        x: (box3.min.x + box3.max.x) / 2,
        y: (box3.min.y + box3.max.y) / 2,
        z: (box3.min.z + box3.max.z) / 2,
      };
      let selectedModel = this.objects.find((obj) => obj.isSelected);
      for (let key in this.controls) {
        if (prop === key && !this.controls[prop]) {
          this.controls[key] = true;
          if (!selectedModel) {
            this.objects[0].isSelected = true;
            this.objects[0].material.uniforms.color.value.set(
              ...this.color.checked
            );
            this.transformControls.attach(this.objects[0]);
            selectedModel = this.objects[0];
            // console.log("111", selectedModel.size.x);
          } else {
            const box = this.getModlBoxFromObject(selectedModel, true);
            this.copyPosition.z = box.min.z;
            console.log("222");
          }
        } else {
          this.controls[key] = false;
        }
      }
      let lineSegments = this.modelMap.get(selectedModel);
      let box = this.getModlBoxFromObject(selectedModel, true);
      switch (prop) {
        case "isShowMove":
          if (this.controls.isShowMove) {
            this.position = {
              ...selectedModel.position,
            };
            this.copyPosition = { ...this.position };
            this.copyPosition.z = box.min.z;
            this.transformControls.setMode("translate");
            lineSegments.visible = true;
            // this.scene.add(lineSegments);
          } else {
            if (!selectedModel) {
              this.transformControls.visible = false;
            }
          }
          break;
        case "isShowScale":
          if (this.controls.isShowScale) {
            this.setModelRotateSize();
            lineSegments.visible = true;
            this.transformControls.setMode("scale");
          } else {
            if (!selectedModel) {
              this.transformControls.visible = false;
            }
          }
          break;
        case "isShowRotate":
          if (this.controls.isShowRotate) {
            this.spin = this.getSpinEluerAngel();
            this.transformControls.setMode("rotate");
            lineSegments.visible = true;
          } else {
            if (!selectedModel) {
              this.transformControls.visible = false;
            }
          }
          break;
        case "isShowClone":
          if (this.controls.isShowClone) {
            lineSegments.visible = true;
            // this.scene.add(lineSegments);
          }
          // this.transformControls.visible = false;
          break;
        case "isShowLayout":
          if (this.controls.isShowLayout) {
            lineSegments.visible = true;
            // this.scene.add(lineSegments);
            console.log("scene", this.scene);
          }
          // this.transformControls.visible = false;
          break;
      }
      console.log("selectedModel", selectedModel);
    },

    // 移动
    //模型下沉、居中、重置操作
    movesModel(val) {
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        const size = this.getModelSize(selectedModel);
        let box = this.getModlBoxFromObject(selectedModel, true);
        switch (val) {
          case "sink":
            selectedModel.position.z -= box.min.z;
            this.copyPosition.z = 0;
            break;
          case "center":
            selectedModel.position.set(
              selectedModel.position.x - box.getCenter(new THREE.Vector3()).x,
              selectedModel.position.y - box.getCenter(new THREE.Vector3()).y,
              selectedModel.position.z - box.min.z
            );
            this.copyPosition = { x: 0, y: 0, z: 0 };
            break;
          case "reset":
            selectedModel.position.set(
              selectedModel.initPosition.x,
              selectedModel.initPosition.y,
              selectedModel.initPosition.z
            );
            this.copyPosition = { ...selectedModel.initPosition };
            this.copyPosition.z = 0;
            box = this.getModlBoxFromObject(selectedModel, true);
            selectedModel.position.z -= box.min.z;
            break;
        }
        box = this.getModlBoxFromObject(selectedModel, true);
        this.modelMap
          .get(selectedModel)
          .position.set(
            box.max.x * 0.5 + box.min.x * 0.5,
            box.max.y * 0.5 + box.min.y * 0.5,
            (box.max.z - box.min.z) * 0.5
          );
      }
    },
    //模型x、y、z轴移动
    handleChange(axes, val) {
      console.log("val", val, axes);
      if (val === undefined) {
        return;
      }
      if (!this.hintSelectModel()) {
        return;
      }
      console.log("1111");
      //查找被选中的模型
      const selectedModel = this.objects.find((obj) => {
        return obj.isSelected;
      });
      if (selectedModel) {
        switch (axes) {
          case "X":
            selectedModel.position.x = val;
            this.modelMap.get(selectedModel).position.x = val;
            this.copyPosition.x = val;
            break;
          case "Y":
            selectedModel.position.y = val;
            this.modelMap.get(selectedModel).position.y = val;
            this.copyPosition.y = 1.0;
            break;
          case "Z":
            selectedModel.position.z += val;
            this.copyPosition.z = val;
            this.modelMap.get(selectedModel).position.z =
              selectedModel.position.z;
            break;
          case "+X":
            selectedModel.position.x += 1.0;
            this.modelMap.get(selectedModel).position.x += 1.0;
            this.copyPosition.x += 1.0;
            break;
          case "-X":
            selectedModel.position.x -= 1.0;
            this.modelMap.get(selectedModel).position.x -= 1.0;
            this.copyPosition.x -= 1.0;
            break;
          case "+Y":
            selectedModel.position.y += 1.0;
            this.modelMap.get(selectedModel).position.y += 1.0;
            this.copyPosition.y += 1.0;
            break;
          case "-Y":
            selectedModel.position.y -= 1.0;
            this.modelMap.get(selectedModel).position.y -= 1.0;
            this.copyPosition.y -= 1.0;
            break;
          case "+Z":
            selectedModel.position.z += 1.0;
            this.modelMap.get(selectedModel).position.z += 1.0;
            this.copyPosition.z += 1.0;
            break;
          case "-Z":
            selectedModel.position.z -= 1.0;
            this.modelMap.get(selectedModel).position.z -= 1.0;
            this.copyPosition.z -= 1.0;
            break;
        }
      }
    },
    toggleShowModelList(flag) {
      this.isShowModelList = !this.isShowModelList;
      if (!flag) {
        console.log(this.$refs.modelList.style);
        this.$refs.modelList.style = "height:40px";
        this.$refs.ul.style = "height:0px; border-bottom: none;";
        this.$refs.addModel.style = "height:0px";
      } else {
        this.$refs.modelList.style = "height:420px";
        this.$refs.ul.style =
          "height:322px;border-bottom: 1px solid rgba(0, 0, 0, 0.6);";
        this.$refs.addModel.style = "height:60px";
      }
    },
    layout1() {
      const planeSize = {
        width: this.printerSize.x,
        height: this.printerSize.y,
      };
      let models = [];
      for (let i = 0; i < this.objects.length; i++) {
        models[i] = {};
        models[i].width = this.objects[i].size.x;
        models[i].height = this.objects[i].size.y;
        models[i].depth = this.objects[i].size.z;
      }
      const computeModelArea = ({ width, height, depth }) => {
        return width * depth;
      };
      for (let model of models) {
        model.area = computeModelArea(model);
      }
      models = models.sort((a, b) => b.area - a.area);
      const layoutModels = () => {
        let x = 0;
        let z = 0;
        let maxY = 0;
        for (let model of models) {
          // 计算模型旋转角度
          const rotationY = Math.random() * Math.PI * 2;
          model.rotation = { x: 0, y: rotationY, z: 0 };
          // 计算模型位置
          const modelWidth = model.width;
          const modelDepth = model.depth;
          const modelHeight = model.height;
          if (x + modelWidth > planeSize.width) {
            x = 0;
            z += maxY;
            maxY = 0;
          }
          model.position = {
            x: x + modelWidth / 2 - planeSize.width / 2,
            y: modelHeight / 2,
            z: z + modelDepth / 2 - planeSize.height / 2,
          };
          x += modelWidth;
          maxY = Math.max(maxY, modelHeight);
        }
      };
      layoutModels();
      /* for (let model of models) {
        const geometry = new THREE.BoxGeometry(
          model.width,
          model.height,
          model.depth
        );
        const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(model.position.x, model.position.y, model.position.z);
        mesh.rotation.set(model.rotation.x, model.rotation.y, model.rotation.z);
        scene.add(mesh);
      } */
      for (let i = 0; i < this.objects.length; i++) {
        this.objects[i].position.set(
          models[i].position.x,
          models[i].position.y,
          models[i].position.z
        );
        /* this.objects[i].rotation.set(
          models[i].rotation.x,
          models[i].rotation.y,
          models[i].rotation.z
        ); */
      }
    },
    packLayout() {
      this.isPackLayout = true;
      setTimeout(() => {
        var Packer = function (w, h) {
          console.log(this, "this");
          this.init(w, h);
        };
        Packer.prototype = {
          init: function (w, h) {
            this.root = { x: 0, y: 0, w: w, h: h };
          },

          fit: function (blocks) {
            var n, node, block;
            for (n = 0; n < blocks.length; n++) {
              block = blocks[n];
              if ((node = this.findNode(this.root, block.w, block.h)))
                block.fit = this.splitNode(node, block.w, block.h);
            }
          },

          findNode: function (root, w, h) {
            if (root.used)
              return (
                this.findNode(root.right, w, h) ||
                this.findNode(root.down, w, h)
              );
            else if (w <= root.w && h <= root.h) return root;
            else return null;
          },

          splitNode: function (node, w, h) {
            node.used = true;
            node.down = { x: node.x, y: node.y + h, w: node.w, h: node.h - h };
            node.right = { x: node.x + w, y: node.y, w: node.w - w, h: h };
            return node;
          },
        };
        var packer = new Packer(this.printerSize.x, this.printerSize.y); // 初始化一个容器
        var blocks = [
          { w: 600, h: 300 },
          { w: 400, h: 400 },
          { w: 100, h: 200 },
        ];
        blocks = [];
        let copyObjects = [...this.objects];
        console.log("copyObjects", copyObjects);
        copyObjects.sort((a, b) => {
          console.log("a", a);
          console.log("b", b);
          let aSize = this.getModelSize(a, true);
          let bSize = this.getModelSize(b, true);
          return bSize.y - aSize.y;
        });
        for (let model of copyObjects) {
          const size = this.getModelSize(model, true);
          blocks.push({
            w: size.x,
            h: size.y,
          });
        }
        // packer.fit(blocks)
        packer.fit(blocks); // 装箱
        // console.log(blocks[0].fit.x, blocks[0].fit.y); // 输出第一个块的位置
        //收集没有放入的模型
        let models = [];
        for (let i = 0; i < copyObjects.length; i++) {
          // const z = this.getModelSize(copyObjects[i], true).z / 2;
          const box = new THREE.Box3().setFromObject(copyObjects[i], true);
          let box1 = box;
          let z = copyObjects[i].position.z - box.min.z;
          if (blocks[i].fit) {
            const x =
              blocks[i].fit.x -
              this.printerSize.x / 2 +
              (copyObjects[i].position.x - box1.min.x);
            const y =
              blocks[i].fit.y -
              this.printerSize.y / 2 +
              (copyObjects[i].position.y - box1.min.y);
            copyObjects[i].position.set(x, y, z);
            let selectedModel = copyObjects[i];
            const box = this.getModlBoxFromObject(selectedModel, true);
            // selectedModel.position.z -= box.min.z;
            const lineSegments = this.modelMap.get(selectedModel);
            this.scene.remove(lineSegments);
            const size = this.getModelSize(selectedModel, true);
            copyObjects[i].initPosition = { ...copyObjects[i].position };
            // copyObjects[i].initSize = { ...size };
            const newLineSegments = this.getBoundingBox(size, selectedModel);
            this.modelMap.set(selectedModel, newLineSegments);
            // if (copyObjects[i].isSelected) {
            // /*   newLineSegments.visible = true;
            // } else { */
            //   newLineSegments.visible = false;
            // }
            this.scene.add(newLineSegments);
            newLineSegments.visible = false;
            newLineSegments.position.set(
              box.max.x * 0.5 + box.min.x * 0.5,
              box.max.y * 0.5 + box.min.y * 0.5,
              (box.max.z - box.min.z) * 0.5
            );
            if (this.objects.length === 1) {
              selectedModel.position.set(
                selectedModel.position.x - box.getCenter(new THREE.Vector3()).x,
                selectedModel.position.y - box.getCenter(new THREE.Vector3()).y,
                selectedModel.position.z - box.min.z
              );
              newLineSegments.position.set(
                0,
                0,
                selectedModel.position.z - box.min.z
              );
              this.copyPosition = { x: 0, y: 0, z: 0 };
              if (selectedModel.isSelected) {
                newLineSegments.visible = true;
              }
              /* this.objects[0].position.x = 0;
            this.modelContactFloor(this.objects[0]);
            this.copyPosition = { x: 0, y: 0, z: 0 };
            const lineSegments = this.modelMap.get(this.objects[0]);
            if (!this.objects[0].isSelected) {
              lineSegments.visible = false;
            } */

              // return;
            }
          } else {
            //没放入的模型
            /* this.objects[i].position.set(
            this.printerSize.x / 2 + 200,
            this.printerSize.y / 2 + 200,
            z
          ); */
            models.push(copyObjects[i]);
          }
        }
        this.improperModels = models;
        this.cancelSelectModels();
        /* var printSize = this.printerSize;
      let XPostive = -printSize.x / 2;
      let YPostive = -printSize.y / 2;
      let circle = 1;
      //记录在X轴 Y轴的绝对值长度
      let x = 0;
      let y = 0;
      let axes = "Y+";
      let lineSegments;
      let maxYPositive = -printSize.x / 2;
      let yPostiveToxPositive = 0;
      let xPositiveToyMinus = 0;
      let yMinusToxMinus = 0;
      let xMinusToyPositive = models[0]
        ? [0, this.getModelSize(models[0], true)]
        : [];
      // 记录Y轴正方向宽度坐标
      for (let i = 0; i < models.length; i++) {
        let xMinusToyPositiveSum = xMinusToyPositive.reduce((pre, cur) => {
          return pre + cur;
        }, 0);
        let size = this.getModelSize(models[i], true);
        if (axes === "Y+") {
          //切换X+轴方向
          if (y > printSize.y) {
            yPostiveToxPositive += size.y;
            axes = "X+";
            y = 0;
            XPostive = -printSize.x / 2 + size.x / 2;
            YPostive = printSize.y / 2 + yPostiveToxPositive - size.y / 2;
            x += size.x;
            models[i].position.set(XPostive, YPostive, size.z / 2);
          } else {
            x = 0;
            if (circle == 1) {
              XPostive = -printSize.x / 2 - size.x / 2;
            } else {
              XPostive = -printSize.x / 2 - size.x / 2 - xMinusToyPositiveSum;
            }
            if (!models[i - 1]) {
              YPostive = size.y / 2 + YPostive;
            } else {
              YPostive =
                size.y / 2 +
                YPostive +
                this.getModelSize(models[i - 1],true).y / 2 +
                yPostiveToxPositive;
            }
            y += size.y;
            models[i].position.set(XPostive, YPostive, size.z / 2);
            lineSegments = this.modelMap.get(models[i]);
            lineSegments.position.set(XPostive, YPostive, size.z / 2);
          }
        }
        // X轴正方向
        else if (axes === "X+") {
          console.log("x+");
          if (x <= printSize.x) {
            y = 0;
            XPostive =
              XPostive + size.x / 2 + this.getModelSize(models[i - 1],true).x / 2;
            YPostive = printSize.y / 2 + size.y / 2;
            x += size.x;
            models[i].position.set(XPostive, YPostive, size.z / 2);
          }
          //切换到Y-轴方向
          else {
            console.log("x+ --- y-");
            axes = "Y-";
            y += size.x;
            XPostive = printSize.x / 2 + size.x / 2;
            YPostive = printSize.y / 2 - size.y / 2;
            models[i].position.set(XPostive, YPostive, size.z / 2);
          }
        }

        //Y轴负方向
        else if (axes === "Y-") {
          console.log("y-");
          if (y <= printSize.y) {
            x = 0;
            XPostive = printSize.x / 2 + size.x / 2;
            YPostive =
              YPostive - size.y / 2 - this.getModelSize(models[i - 1],true).y / 2;
            y += size.y;
          }
          //切换到X-轴方向
          else {
            console.log("y- ---- x-");
            axes = "X-";
            XPostive = printSize.x / 2 - size.x / 2;
            YPostive = -printSize.y / 2 - size.y / 2;
            x += size.x;
          }
          models[i].position.set(XPostive, YPostive, size.z / 2);
        }

        //X轴负方向
        else if (axes === "X-") {
          console.log("x-");
          if (x <= printSize.x) {
            XPostive =
              XPostive - size.x / 2 - this.getModelSize(models[i - 1],true).x / 2;
            YPostive = -printSize.y / 2 - size.y / 2;
            x += size.x;
          }
          //切换到Y+轴方向
          else {
            circle++;
            console.log("x- ---- y+");
            axes = "Y+";
            y = 0;
            y += size.y / 2;
            XPostive = -printSize.x / 2 - xMinusToyPositiveSum - size.x / 2;
            YPostive = -printSize.y / 2 + size.y / 2;
            xMinusToyPositive.push(size.x);
          }
          models[i].position.set(XPostive, YPostive, size.z / 2);
        }
      } */
        //x和y方向的绝对值
        let ySum = 0,
          xSum = 0;
        let yPostive = 0,
          xPostive = 0,
          yMinus = 0,
          xMinus = 0;
        let yPostiveMax = 0.01,
          xPostiveMax = 0.01,
          yMinusMax = 0.01,
          xMinusMax = 0.01;
        let yPostiveModels = [],
          xPostiveModels = [],
          yMinusModels = [],
          xMinusModels = [];
        for (let i = 0; i < models.length; i++) {
          const size = this.getModelSize(models[i], true);
          let box = this.getModlBoxFromObject(models[i], true);
          let center = {
            x: (box.min.x + box.max.x) / 2,
            y: (box.min.y + box.max.y) / 2,
            z: (box.min.z + box.max.z) / 2,
          };
          let initX = models[i].position.x;
          let initY = models[i].position.y;
          let initZ = models[i].position.z;
          let { x, y, z } = size;
          //Y+
          if (yPostive == xPostive && xPostive == yMinus && yMinus == xMinus) {
            xSum = 0;
            ySum += size.y;
            if (!yPostiveModels[yPostive]) {
              yPostiveModels[yPostive] = [];
            }
            let xPosition, yPosition, xdiff, ydiff;
            yPostiveModels[yPostive].push(size.x);
            xPosition = -this.printerSize.x / 2 - x / 2 - yPostiveMax;
            yPosition = -this.printerSize.y / 2 + ySum - y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (ySum >= this.printerSize.y) {
              yPostiveMax += Math.max(...yPostiveModels[yPostive]);
              yPostive++;
            }
          }
          //模型排X+
          else if (yPostive - xPostive == 1) {
            ySum = 0;
            xSum += size.x;
            if (!xPostiveModels[xPostive]) {
              xPostiveModels[xPostive] = [];
            }
            let xPosition, yPosition;
            xPostiveModels[xPostive].push(size.y);
            xPosition = -this.printerSize.x / 2 + xSum - x / 2;
            yPosition = this.printerSize.y / 2 + xPostiveMax + y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            console.log("xPostion", xPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (xSum >= this.printerSize.x) {
              xPostiveMax += Math.max(...xPostiveModels[xPostive]);
              xPostive++;
            }
          }
          //模型排Y-
          else if (xPostive - yMinus == 1) {
            xSum = 0;
            ySum += size.y;
            if (!yMinusModels[yMinus]) {
              yMinusModels[yMinus] = [];
            }
            let xPosition, yPosition;
            yPostiveModels[yMinus].push(size.x);
            xPosition = this.printerSize.x / 2 + x / 2 + yMinusMax;
            yPosition = this.printerSize.y / 2 - ySum + y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            console.log("xPostion", xPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (ySum >= this.printerSize.y) {
              yMinusMax += Math.max(...yMinusModels[yMinus]);
              yMinus++;
            }
          }
          //模型排X-
          else if (yMinus - xMinus == 1) {
            ySum = 0;
            xSum += size.x;
            if (!xMinusModels[xMinus]) {
              xMinusModels[xMinus] = [];
            }
            let xPosition, yPosition;
            xPostiveModels[xMinus].push(size.y);
            xPosition = this.printerSize.x / 2 - xSum + x / 2;
            yPosition = -this.printerSize.y / 2 - xMinusMax - y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            console.log("xPostion", xPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (xSum >= this.printerSize.x) {
              xMinusMax += Math.max(...xMinusModels[xMinus]);
              xMinus++;
            }
          }
          models[i].initPosition = {
            ...models[i].position,
          };
          // models[i].initSize = { ...size };
          console.log("models[i].initSize", models[i].initSize);
          const newLineSegments = this.getBoundingBox(
            this.getModelSize(models[i], true),
            models[i]
          );
          this.scene.remove(this.modelMap.get(models[i]));
          this.modelMap.set(models[i], newLineSegments);
          this.scene.add(newLineSegments);
          newLineSegments.visible = false;
        }
        console.log("自动布局完成");
        if (this.message) {
          this.message.close();
        }
        this.message = this.$message({
          type: "success",
          duration: 1000,
          message: this.$t("slicer.autoLayoutComp"),
          offset: 50,
        });
        this.isPackLayout = false;
      }, 1000);
    },
    layoutModels() {
      // 获取模型的包围盒，并计算每个模型在平面上所占据的大小
      var modelSizes = [];
      let models = [...this.objects];
      //用来记录在每行第一个模型的索引值,和有几行
      let row = 1;
      let row1Arr = [0];
      models.sort((model1, model2) => {
        let sizeX2 = this.getModelSize(model2).x;
        let sizeX1 = this.getModelSize(model1).x;
        return sizeX2 - sizeX1;
      });
      console.log("models", models);
      models.forEach(function (model) {
        var boundingBox = new THREE.Box3().setFromObject(model);
        var size = boundingBox.getSize(new THREE.Vector3());
        modelSizes.push({
          width: size.x * model.scale.x,
          height: size.y * model.scale.y,
        });
      });
      console.log("modelSizes", modelSizes);
      // 计算平面上模型之间的间距
      var horizontalPadding = 0.2;
      var verticalPadding = 0.2;

      var totalWidth = modelSizes.reduce(function (acc, size) {
        return acc + size.width;
      }, 0);
      var totalHeight = modelSizes.reduce(function (acc, size) {
        return Math.max(acc, size.height);
      }, 0);
      let distanceX = this.printerSize.x;
      console.log(modelSizes[0]);
      var xStart = -totalWidth / 2 + modelSizes[0].width / 2;
      var yStart = totalHeight / 2 - modelSizes[0].height / 2;
      xStart = -this.printerSize.x / 2 + modelSizes[0].width / 2;
      yStart = -this.printerSize.y / 2 + modelSizes[0].height / 2;
      // 计算每个模型的位置，并移动它们到正确的位置
      var x = xStart;
      var y = yStart;

      models.forEach((model, index) => {
        var modelSize = modelSizes[index];
        console.log("size", this.getModelSize(models[index]));
        model.position.x = x;
        model.position.y = y;
        distanceX -= modelSizes[index].width + horizontalPadding;
        if (index < models.length - 1) {
          // 向右移动x以便为下一个模型留出空间
          x +=
            modelSize.width / 2 +
            horizontalPadding +
            modelSizes[index + 1].width / 2;
        }
        // console.log('modelSizes[index + 1]',modelSizes[index + 1]);
        console.log("x", x);
        console.log(this.printerSize.x);

        if (modelSizes[index + 1] && distanceX <= modelSizes[index + 1].width) {
          // 如果在平面上没有足够的空间放置下一个模型，则换行
          x = -this.printerSize.x / 2 + modelSizes[index + 1].width / 2;
          y +=
            modelSizes[row1Arr[row - 1]].height / 2 +
            verticalPadding +
            modelSizes[index + 1].height / 2;
          console.log("nextY", y);
          distanceX = this.printerSize.x;
          row++;
          row1Arr.push(index + 1);
        }
      });
    },
    //添加模型
    addModel() {
      // 获取当前模型相关模型文件列表
      this.getCurrentModelList(this.modelId, this.idList);
      this.isShowAddModelsDialog = true;
      this.$nextTick(() => {
        const dragModelList = this.$refs.dragModelList;
        if (dragModelList) {
          //给modelList添加绑定拖拽事件
          dragModelList.addEventListener("dragover", (e) => {
            e.preventDefault();
          });
          dragModelList.addEventListener("dragenter", (e) => {
            e.preventDefault();
          });
        }
      });
    },
    //改变模型位置
    moveModel(x, y, z) {
      if (x) {
        this.position.x = x;
      } else if (y) {
        this.position.y = y;
      } else if (z) {
        this.position.z = z;
      }
      for (let obj of this.objects) {
        if (obj.isSelected) {
          obj.position.set(
            this.position.x,
            this.position.y,
            this.position.z - this.printerSize.z / 2
          );
          this.modelMap
            .get(obj)
            .position.set(
              this.position.x,
              this.position.y,
              this.position.z - this.printerSize.z / 2
            );
        }
      }
    },
    //切换选中所有模型
    toggleSelectAll(val) {
      console.log("val", val);
      if (val) {
        return this.selectAllModels();
      }
      this.cancelSelectModels();
    },
    showModel(obj) {
      obj.isShow = false;
      obj.visible = false;
      // obj.userData.selectable = false;
      const lineSegments = this.modelMap.get(obj);
      lineSegments.visible = false;
      // this.transformControls.visible = false
      console.log();
      this.$forceUpdate();
    },
    hiddenModel(obj) {
      obj.isShow = true;
      obj.visible = true;
      const lineSegments = this.modelMap.get(obj);
      if (obj.isSelected) {
        lineSegments.visible = true;
      }
      this.$forceUpdate();
    },
    async cloneModel() {
      if (!this.hintSelectModel()) return;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      // console.log('number',typeof this.clone.number);
      if (selectedModel && this.clone.number) {
        const lineSegments = this.modelMap.get(selectedModel);
        lineSegments.visible = false;
        this.transformControls.visible = false;
        for (let i = 0; i < this.clone.number; i++) {
          const cloneModel = selectedModel.clone();
          const shaderMaterial = this.getShaderMaterial();
          cloneModel.material = shaderMaterial;
          cloneModel.size = selectedModel.size;
          cloneModel.isShow = true;
          cloneModel.visible = true;
          cloneModel.isSelected = false;
          cloneModel.modelInfo = selectedModel.modelInfo;
          console.log("cloneModel.modelInfo", cloneModel.modelInfo);
          cloneModel.initSize = { ...selectedModel.initSize };
          this.objects.push(cloneModel);
          //获得模型尺寸
          const size = this.getModelSize(cloneModel);
          const lineSegments = this.getBoundingBox(size, cloneModel);
          this.scene.add(lineSegments);
          lineSegments.visible = false;
          this.modelMap.set(cloneModel, lineSegments);

          this.loadingCloneModel = false;
          this.scene.add(cloneModel);
        }
        await this.packLayout();
      }
      //
    },
    selectAllModels() {
      for (let obj of this.objects) {
        obj.isSelected = true;
        //给模型改变颜色
        setTimeout(() => {
          if (obj.isSelected) {
            obj.material.uniforms.color.value.set(...this.color.checked);
          }
        });
        const lineSegments = this.modelMap.get(obj);
        lineSegments.visible = true;
        this.scene.add(lineSegments);
      }
    },
    //取消选中模型
    cancelSelectModels() {
      console.log("cancel");
      this.checked = false;
      //将移动的坐标归0
      this.copyPosition.x = 0;
      this.copyPosition.y = 0;
      this.copyPosition.z = 0;
      //将旋转实际模型尺寸归0
      this.rotate.size = {
        x: 0,
        y: 0,
        z: 0,
      };
      this.rotate.per = {
        x: 100,
        y: 100,
        z: 100,
      };
      this.spin = {
        x: 0,
        y: 0,
        z: 0,
      };
      // this.scaleReset()
      for (let obj of this.objects) {
        if (obj.isSelected) {
          const lineSegments = this.modelMap.get(obj);
          lineSegments.visible = false;
          obj.material.uniforms.color.value.set(...this.color.unChecked);
          obj.isSelected = false;
          this.transformControls.detach(obj);
        }
      }
    },
    //自动布局
    layout() {
      this.cancelSelectModels();
      // 计算模型的数量
      const modelNum = this.objects.length;
      // 计算每行模型的数量
      const rowNum = Math.ceil(Math.sqrt(modelNum));
      // 计算每行模型的间距
      const rowSpace = (rowNum - 1) * 27;
      // 计算每列模型的间距
      const colSpace = (rowNum - 1) * 27;
      // 计算模型的起始位置
      const startX = -rowSpace / 2;
      const startY = -colSpace / 2;
      // 遍历模型，设置模型的位置
      for (let i = 0; i < modelNum; i++) {
        let rowIndex = i % rowNum;
        let colIndex = Math.floor(i / rowNum);
        let x = startX + rowIndex * this.objects[i].size.x;
        let y = startY + colIndex * this.objects[i].size.y;
        this.objects[i].position.set(x, y, this.objects[i].size.z / 2);
        this.modelMap
          .get(this.objects[i])
          .position.set(
            this.objects[i].position.x,
            this.objects[i].position.y,
            this.objects[i].position.z
          );
      }
    },
    //获得模型尺寸
    getModelSize(mesh, isPrecise = false) {
      let { x, y, z } = mesh.rotation;
      if (x === 0 && y === 0 && z === 0) {
        isPrecise = false;
      }
      console.log("getModelSize", mesh);
      // 获取模型的最大最小坐标
      const box = new THREE.Box3().setFromObject(mesh, isPrecise);
      console.log("getModelSize", box);
      const min = box.min;
      const max = box.max;
      // 计算模型的缩放比例
      const scale = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);
      const size = new THREE.Vector3();
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      console.log("getModelSize", size);
      return size;
    },
    //根据模型尺寸获得包围盒
    getBoundingBox(size, mesh) {
      console.log("box", size);
      const geometryBox = this.box(size.x, size.y, size.z);
      const lineSegments = new THREE.LineSegments(
        geometryBox,
        new THREE.LineBasicMaterial({ color: "#e7c200" })
      );
      lineSegments.name = "box";
      lineSegments.computeLineDistances();
      // lineSegments.rotation.x = -Math.PI / 2;
      lineSegments.position.set(
        mesh.position.x,
        mesh.position.y,
        mesh.position.z
      );
      return lineSegments;
    },
    //创建场景、相机、渲染器、网格面、辅助坐标、控制器
    init() {
      this.initScene();
      this.initRenderer();
      this.initCamera();
      this.initTransformControls();
      this.initOrbitControls();
      this.initLight();
      this.animate();
      console.log("init");
      // this.loadStlModel();
      // 监听浏览器窗口大小变化并更新renderer和camera等属性
      window.addEventListener("resize", () => {
        console.log("resize");
        var viewportWidth =
          window.innerWidth || document.documentElement.clientWidth;
        var viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        this.renderer.setSize(viewportWidth, viewportHeight);
        this.camera.aspect = viewportWidth / viewportHeight;
        this.camera.updateProjectionMatrix();
        // this.renderer.setPixelRatio(window.devicePixelRatio);
      });
    },
    //初始化场景
    initScene() {
      const scene = new THREE.Scene();
      this.scene = scene;
      scene.position.set(0, 0, 0);
      scene.background = new THREE.Color("#ADAFB6");
      const group = new THREE.Group();
      this.group = group;
      this.scene.add(group);
    },
    //初始化相机
    initCamera() {
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        30000
      );
      this.camera = camera;
      camera.position.y = -250;
      camera.position.z = 80;
      // camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
      const target = new THREE.Vector3(0, 0, camera.position.z);
      camera.up = new THREE.Vector3(0, 0, 1);
      camera.lookAt(target);
    },
    //初始化渲染器
    initRenderer() {
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      });
      this.renderer = renderer;
      renderer.sortObjects = false;
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.hello.appendChild(renderer.domElement);
    },
    // 添加网格地面
    initGridHelper() {
      // #C3C4C9
      const gridHelper = new THREE.GridHelper(
        this.printerSize.x,
        20,
        "rgb(208,17,17)",
        "#C3C4C9"
      );
      this.gridHelper = gridHelper;
      gridHelper.rotation.x = -Math.PI / 2;
      gridHelper.scale.z = this.printerSize.y / this.printerSize.x;
      gridHelper.material.depthTest = false;
      this.group.add(gridHelper);

      // 创建一个新的网格
      /* var geometry = new THREE.PlaneGeometry(
        this.printerSize.x,
        this.printerSize.y,
        20,
        14
      );
      const wireframe = new THREE.WireframeGeometry(geometry);
      const line = new THREE.LineSegments(wireframe);
      line.position.z = -this.printerSize.z / 2
      this.scene.add(line); */

      /* // 创建一个新的材质
      var material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
      });

      // 创建一个新的网格
      var plane = new THREE.Mesh(geometry, material);
      plane.position.z = -this.printerSize.z / 2 */
      // 将网格添加到场景中
      // this.scene.add(plane);
    },
    //创建搭建模型外部的几何体
    initExterGeometry() {
      const { x, y, z } = this.printerSize;
      const geometry = new THREE.BoxGeometry(x, y, z);
      // define the wireframe of the cube
      const wireframe = new THREE.EdgesGeometry(geometry);

      // define the material of the wireframe
      const material = new THREE.LineBasicMaterial({
        color: "#fff",
        linewidth: 20,
      });
      // combine the wireframe and material
      const cube = new THREE.LineSegments(wireframe, material);
      cube.position.set(0, 0, this.printerSize.z / 2);
      this.group.add(cube);
      this.exterGeometry = cube;
    },
    //初始化辅助坐标轴
    initAxes() {
      const axes = new THREE.AxesHelper(50);
      const { x, y, z } = this.printerSize;
      axes.material.linewidth = 2;
      axes.material.depthTest = false;
      console.log("axes", axes.material.linewidth);
      this.axes = axes;
      axes.position.set(-x / 2, -y / 2, 0);
      this.group.add(axes);
    },
    //初始化轨道控制器
    initOrbitControls() {
      const orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      orbitControls.target = new THREE.Vector3(0, 0, this.printerSize.z / 2);
      this.orbitControls = orbitControls;
      orbitControls;
      //启用阻尼
      orbitControls.enableDamping = true;
      orbitControls.dampingFactor = 0.5;
      //是否开启右键拖拽
      orbitControls.enablePan = false;
      orbitControls.update();
    },
    //初始化拖拽控制器
    // initTransformControls() {
    //   // 添加平移控件
    //   const transformControls = new TransformControls(
    //     this.camera,
    //     this.renderer.domElement
    //   );
    //   this.transformControls = transformControls;
    //   transformControls.size = 0.7
    //   this.scene.add(transformControls);
    //   transformControls.addEventListener("dragging-changed", (event) => {
    //     this.orbitControls.enabled = !event.value;
    //   });
    //   //最初的包围盒
    //   let lineSegments = null;
    //   let spinlineSegements = null;
    //   transformControls.addEventListener("mouseUp", (e) => {
    //     console.log("end");
    //     this.isShowTransformControls = true
    //     const selectedModel = this.objects.find((obj) => obj.isSelected);
    //     if (selectedModel) {
    //       // console.log(selectedModel.position === this.position);
    //       const size = this.getModelSize(selectedModel,true)
    //       const lineSegments = this.modelMap.get(selectedModel)
    //       if(lineSegments) {
    //         this.scene.remove(lineSegments)
    //       }
    //       this.position.z = size.z / 2;
    //       let newLineSegments = this.getBoundingBox(size,selectedModel)
    //       console.log('newLineSegments',newLineSegments)
    //       this.modelMap.set(selectedModel,newLineSegments)
    //       newLineSegments.visible = true
    //       this.scene.add(newLineSegments)
    //       newLineSegments.positon = selectedModel.position
    //       /* this.copyPosition = {...this.position}
    //       this.copyPosition.z -= this.getModelSize(selectedModel).z / 2 */
    //       // console.log('this.copyPosition.z',this.copyPosition.z);
    //     }
    //   });
    //   transformControls.addEventListener("objectChange", (e) => {
    //     const selectedModel = this.objects.find((obj) => obj.isSelected);
    //     // console.log("transformControls.mode", transformControls.mode);
    //     if (selectedModel) {
    //       this.scene.remove(this.modelMap.get(selectedModel))
    //     }
    //     //判断处于哪种模式
    //     switch (transformControls.mode) {
    //       // case "scale":
    //       //   if (selectedModel) {
    //       //     let size = this.getModelSize(selectedModel, true);
    //       //     //移除原来的包围盒
    //       //     if (lineSegments) {
    //       //       this.scene.remove(lineSegments);
    //       //     }
    //       //     if (this.switchControls.rotate) {
    //       //       // 获取控件各轴线上的缩放值
    //       //       var scaleX = transformControls.object.scale.x;
    //       //       var scaleY = transformControls.object.scale.y;
    //       //       var scaleZ = transformControls.object.scale.z;
    //       //       // 计算缩放因子
    //       //       var avgScale = (scaleX + scaleY + scaleZ) / 3;
    //       //       selectedModel.scale.set(avgScale, avgScale, avgScale);
    //       //       size = this.getModelSize(selectedModel);
    //       //     }
    //       //     this.rotate.size = size;
    //       //     //获取模型缩放的百分比
    //       //     this.rotate.per = { ...selectedModel.scale };
    //       //     for (let key of Object.keys(this.rotate.per)) {
    //       //       this.rotate.per[key] = this.rotate.per[key] * 100;
    //       //     }
    //       //     lineSegments = this.modelMap.get(selectedModel);
    //       //     this.scene.remove(lineSegments);
    //       //     console.log("postion.z", selectedModel.position.z);
    //       //     //获得包围盒
    //       //     lineSegments = this.getBoundingBox(size, selectedModel);
    //       //     this.modelMap.set(selectedModel, lineSegments);
    //       //     this.scene.add(lineSegments);
    //       //     // this.position.z = this.getModelSize(selectedModel).z / 2
    //       //     /* this.position.z = this.getModelSize(selectedModel).z / 2
    //       //     this.copyPosition = {...this.position}
    //       //     this.copyPosition.z -= this.getModelSize(selectedModel).z / 2 */
    //       //   }
    //       //   break;
    //       // case "rotate":
    //       //   if (selectedModel) {
    //       //     spinlineSegements = this.modelMap.get(selectedModel);
    //       //     this.scene.remove(spinlineSegements);
    //       //     this.spin = this.getSpinEluerAngel();
    //       //     spinlineSegements = this.getBoundingBox(
    //       //       this.getModelSize(selectedModel, true),
    //       //       selectedModel
    //       //     );
    //       //     const box = new THREE.Box3().setFromObject(selectedModel, true);
    //       //     console.log("min", box.min);
    //       //     this.modelMap.set(selectedModel, spinlineSegements);
    //       //     this.scene.add(spinlineSegements);
    //       //     /* spinlineSegements.rotation.x = (Math.PI * this.spin.x) / 180;
    //       //     spinlineSegements.rotation.y = (Math.PI * this.spin.y) / 180;
    //       //     spinlineSegements.rotation.z = (Math.PI * this.spin.z) / 180; */
    //       //   }
    //       //   break;
    //       // case "translate":
    //       //   if (selectedModel) {
    //       //     // console.log("this.transformControls", transformControls.position);
    //       //   }
    //       case "scale":
    //         if(selectedModel) {
    //           let size = this.getModelSize(selectedModel);
    //         if (this.switchControls.rotate) {
    //           // 获取控件各轴线上的缩放值
    //           var scaleX = transformControls.object.scale.x;
    //           var scaleY = transformControls.object.scale.y;
    //           var scaleZ = transformControls.object.scale.z;
    //           // 计算缩放因子
    //           var avgScale = (scaleX + scaleY + scaleZ) / 3;
    //           selectedModel.scale.set(avgScale, avgScale, avgScale);
    //           size = this.getModelSize(selectedModel);
    //         }
    //         this.rotate.size = size;
    //         //获取模型缩放的百分比
    //         this.rotate.per = { ...selectedModel.scale };
    //         for (let key of Object.keys(this.rotate.per)) {
    //           this.rotate.per[key] = this.rotate.per[key] * 100;
    //         }
    //         }
    //         break;
    //       case "rotate":
    //         if (selectedModel) {
    //           // spinlineSegements = this.modelMap.get(selectedModel);
    //           // this.scene.remove(spinlineSegements);
    //           this.spin = this.getSpinEluerAngel();
    //         }
    //         break;
    //       case "translate":
    //       if(selectedModel) {
    //          this.position = selectedModel.position;
    //         this.copyPosition = { ...this.position };
    //         this.copyPosition.z -= this.getModelSize(selectedModel).z / 2;
    //       }
    //     }
    //     for (let obj of this.objects) {
    //       if (obj.isSelected) {
    //         this.modelMap
    //           .get(obj)
    //           .position.set(obj.position.x, obj.position.y, obj.position.z);
    //       }
    //     }
    //   });
    // },
    //初始化拖拽控制器
    afterChange() {
      console.log("up", this.rotate);
      this.isShowTransformControls = true;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (selectedModel) {
        const size = this.getModelSize(selectedModel, true);
        selectedModel.size = { ...size };
        const box = this.getModlBoxFromObject(selectedModel, true);
        const lineSegments = this.modelMap.get(selectedModel);
        if (lineSegments) {
          this.scene.remove(lineSegments);
        }
        // selectedModel.position.z -= box.min.z;
        // this.copyPosition.z = 0;
        let newLineSegments = this.getBoundingBox(size, selectedModel);
        this.modelMap.set(selectedModel, newLineSegments);
        this.scene.add(newLineSegments);
        newLineSegments.position.set(
          box.max.x * 0.5 + box.min.x * 0.5,
          box.max.y * 0.5 + box.min.y * 0.5,
          box.max.z * 0.5 + box.min.z * 0.5
        );
      }
    },
    initTransformControls() {
      // 添加平移控件
      const transformControls = new TransformControls(
        this.camera,
        this.renderer.domElement
      );
      this.transformControls = transformControls;
      transformControls.size = 0.4;
      this.scene.add(transformControls);
      transformControls.addEventListener("dragging-changed", (event) => {
        this.orbitControls.enabled = !event.value;
      });
      //最初的包围盒
      let lineSegments = null;
      let spinlineSegements = null;
      transformControls.addEventListener("mouseUp", (e) => {
        console.log("mouseUp");
        this.afterChange();
      });
      transformControls.addEventListener("objectChange", (e) => {
        console.log("change");
        const selectedModel = this.objects.find((obj) => obj.isSelected);
        if (selectedModel) {
          this.scene.remove(this.modelMap.get(selectedModel));
        }
        //判断处于哪种模式
        switch (transformControls.mode) {
          case "scale":
            if (selectedModel) {
              // let size = this.getModelSize(selectedModel);
              let size = { ...selectedModel.initSize };
              // 获取控件各轴线上的缩放值
              var scaleX = transformControls.object.scale.x;
              var scaleY = transformControls.object.scale.y;
              var scaleZ = transformControls.object.scale.z;
              console.log("transformControls.object", transformControls.object);
              this.rotate.size = {
                x: size.x * scaleX,
                y: size.y * scaleY,
                z: size.z * scaleZ,
              };
              if (this.switchControls.rotate) {
                // 计算缩放因子
                var avgScale = (scaleX + scaleY + scaleZ) / 3;
                selectedModel.scale.set(avgScale, avgScale, avgScale);
                this.rotate.size = {
                  x: size.x * avgScale,
                  y: size.y * avgScale,
                  z: size.z * avgScale,
                };
              }
              console.log(
                "avgScale, avgScale, avgScale",
                avgScale,
                avgScale,
                avgScale
              );
              //获取模型缩放的百分比
              this.rotate.per = { ...selectedModel.scale };
              for (let key of Object.keys(this.rotate.per)) {
                this.rotate.per[key] = this.rotate.per[key] * 100;
              }
              selectedModel.size = { ...this.rotate.size };
            }
            break;
          case "rotate":
            if (selectedModel) {
              this.spin = this.getSpinEluerAngel();
            }
            break;
          case "translate":
            if (selectedModel) {
              this.position = selectedModel.position;
              // const box = new THREE.Box3().setFromObject(selectedModel,true);
              this.copyPosition = { ...this.position };
              this.copyPosition.z = 0;
              // this.copyPosition.z = box.min.z;
            }
        }
      });
    },
    //初始化光照
    initLight() {
      const ambienLight = new THREE.AmbientLight(0xfff, 0.1);
      this.scene.add(ambienLight);
      const light = new THREE.DirectionalLight(0xffffff, 1.5);
      this.light = light;
      light.position.set(0, 0, 0);
      this.scene.add(light);
    },
    animate() {
      this.orbitControls.update();
      this.light.position.set(
        this.camera.position.x,
        this.camera.position.y,
        this.camera.position.z
      );
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    },
    //获得着色器材质
    getShaderMaterial() {
      const vertexShader = `
out vec3 pos;
out vec3 n;
void main() {
  pos = (modelMatrix * vec4(position, 1.0)).xyz;
  n = normalize((transpose(inverse(modelMatrix)) * vec4(normal, 0.0)).xyz);
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0); // 计算顶点位置
}
`;
      const fragmentShader = `
uniform vec3 size; // 定义时间变量
uniform vec3 color;
in vec3 pos;
in vec3 n;
void main() {
  
  vec3 vcolor;
  if (
    abs(pos.x) <= size.x &&
    abs(pos.y) <= size.y &&
    abs(pos.z) <= size.z * 2.0 &&  
    pos.z >= -0.005
    ) { // 上半部分设置为红色
    vcolor = color;
  } else { // 下半部分设置为绿色
    vcolor = vec3(0.5, 0.0, 1.0);
  }
  float light = dot(n , normalize(cameraPosition - pos));

  gl_FragColor = vec4(vcolor * light, 1.0) ; // 定义片元颜色
}
`;
      const { x, y, z } = this.printerSize;
      console.log("着色器材质", this.printerSize);
      console.log("x", x, y, z);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          size: { value: { x: x / 2, y: y / 2, z: z / 2 } },
          color: { value: new THREE.Vector3(...this.color.unChecked) },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
      });
      return material;
    },
    //加载stl模型
    loadStlModel(path, modelInfo, resolve, reject) {
      console.log("path", path);
      const loader = new STLLoader1();
      this.loader = loader;
      this.loadTime = Date.now();
      //loader加载成功调用
      loader.onStartParse = () => {
        this.percentage = 100;
        this.$forceUpdate();
        console.log("start parse");
      };
      loader
        .loadAsync(path, (xhr) => {
          console.log("xhr.loader", xhr);
          console.log("totalSiZE", this.totalSize);
          let percentage = Number((xhr.loaded / this.totalSize).toFixed(0));
          console.log("percentage", percentage);
          let curTime = Date.now();
          if (percentage === 99) {
            this.percentage = 100;
          }
          if (curTime - this.loadTime >= 1000) {
            this.$nextTick(() => {
              this.percentage = percentage;
              this.loadTime = curTime;
            });
          }
        })
        .then(async (geometry) => {
          geometry.computeVertexNormals();
          // clearInterval(this.loadModelProId);
          // this.isShowLoad = false;
          this.loading = false;
          console.log("startTime", Date.now());
          //加载后使模型居中
          geometry.center();
          // 定义顶点着色器和片元着色器
          const material = this.getShaderMaterial();
          const camera = this.camera;
          const scene = this.scene;
          const mesh = new THREE.Mesh(geometry, material);
          mesh.isSelected = false;
          //获取stl模型尺寸
          let size = this.adaptPrint(mesh);
          console.log("next", size);
          this.modelSize = size;
          // mesh.size = {...size};
          // console.log("no布局", mesh.size);
          // 计算模型的中心点坐标
          /* const center = new THREE.Vector3();
          center.x = (max.x + min.x) / 2;
          center.y = (max.y + min.y) / 2;
          center.z = (max.z + min.z) / 2; */
          // 设置模型的位置
          // mesh.position.set(0, 0, size.z / 2);
          //生成一个包围盒
          const lineSegments = this.getBoundingBox(size, mesh);
          lineSegments.visible = false;
          mesh.isShow = true;
          mesh.isSelected = false;
          mesh.modelInfo = modelInfo;
          console.log("modelInfo", mesh.modelInfo);
          this.objects.push(mesh);
          this.modelList.push(mesh);
          this.modelMap.set(mesh, lineSegments);
          // this.packLayout();
          console.log("布局后", mesh.size);
          mesh.initPosition = { ...mesh.position };
          // 创建鼠标拾取器
          const raycaster = new THREE.Raycaster();
          const mouse = new THREE.Vector2();
          let that = this;
          // 添加鼠标点击事件
          if (this.$refs.hello) {
            /*  this.$refs.canvasDom.addEventListener(
              "mouseup",
              onMouseDown,
              false
            ); */
            // this.$refs.hello.onmouseup =
            // function (event) {
            //   console.log("点击了");
            //   if (that.transformControls.dragging) return;

            //   // 获取鼠标点击位置
            //   mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            //   mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            //   // 设置射线
            //   raycaster.setFromCamera(mouse, camera);
            //   // 获取射线与模型相交的数组
            //   const intersects = raycaster.intersectObjects(
            //     that.objects,
            //     false
            //   );
            //   // 如果有相交的模型
            //   console.log("cancel");
            //   if (intersects.length > 0) {
            //     // 获取第一个相交的模型
            //     const intersected = intersects[0];
            //     console.log("intersected.object", intersected.object);
            //     if (intersected.object instanceof THREE.Mesh) {
            //       intersected.object.material.uniforms.color.value.set(
            //         ...that.color.checked
            //       );
            //       //将移动中的初始位置为选中模型时的位置
            //       that.position = intersected.object.position;
            //       // 获取模型的最大最小坐标
            //       const box = that.getModlBoxFromObject(
            //         intersected.object,
            //         true
            //       );
            //       that.copyPosition = { ...that.position };
            //       that.copyPosition.z = box.min.z;
            //       const size = that.getModelSize(intersected.object, true);
            //       console.log("size", size);
            //       console.log("intersected.object", intersected.object);
            //       that.rotate.per = {
            //         x: (size.x / intersected.object.initSize.x) * 100,
            //         y: (size.y / intersected.object.initSize.y) * 100,
            //         z: (size.z / intersected.object.initSize.z) * 100,
            //       };
            //       //选中模型时将模型旋转的角度赋值给变量
            //       that.spin = that.getSpinEluerAngel(intersected.object);
            //       console.log("that.spin", that.spin);
            //       //选中模型时将模型旋转的角度赋值给变量
            //       /*  that.spin = that.getSpinEluerAngel()
            //     console.log('spin',that.spin); */
            //       //产生平移轨道控件
            //       that.transformControls.attach(intersected.object);
            //       that.rotate.size = that.getModelSize(
            //         intersected.object,
            //         true
            //       );
            //       // 设置模型的颜色
            //       intersected.object.isSelected = true;
            //       if (that.objects.length === 1) {
            //         that.checked = true;
            //       }
            //       for (let obj of that.objects) {
            //         //如果模型不是选中的模型，将模型状态变为非选中，同时去除外边框改变回原来的颜色
            //         if (obj !== intersected.object) {
            //           obj.isSelected = false;
            //           obj.material.uniforms.color.value.set(
            //             ...that.color.unChecked
            //           );
            //           // scene.remove(that.modelMap.get(obj));
            //           that.modelMap.get(obj).visible = false;
            //         }
            //       }
            //       that.$forceUpdate();
            //       // intersected.object.material.uniforms.color.value.set(1,0,1);
            //       if (intersected.object.isShow) {
            //         const lineSegments1 = that.modelMap.get(intersected.object);
            //         lineSegments1.visible = true;
            //         // scene.add(lineSegments1);
            //       } else {
            //         const lineSegments1 = that.modelMap.get(intersected.object);
            //         lineSegments1.visible = false;
            //         // scene.add(lineSegments1);
            //       }
            //     } else {
            //       that.cancelSelectModels();
            //     }
            //   } else {
            //     that.cancelSelectModels();
            //   }
            // };
            this.$refs.hello.addEventListener("mouseup", onMouseDown, true);
          }
          function onMouseDown(event) {
            if (event.target.tagName !== "CANVAS") return;
            if (that.transformControls.dragging) return;
            // 获取鼠标点击位置
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            console.log("mouse", mouse);
            console.log("window.innerWidth", window.innerWidth);
            // 设置射线
            raycaster.setFromCamera(mouse, camera);
            // 获取射线与模型相交的数组
            const intersects = raycaster.intersectObjects(that.objects, false);
            // 如果有相交的模型
            console.log("cancel");
            if (intersects.length > 0) {
              // 获取第一个相交的模型
              const intersected = intersects[0];
              console.log("intersected.object", intersected.object);
              if (intersected.object instanceof THREE.Mesh) {
                intersected.object.material.uniforms.color.value.set(
                  ...that.color.checked
                );
                //将移动中的初始位置为选中模型时的位置
                that.position = intersected.object.position;
                // 获取模型的最大最小坐标
                const box = that.getModlBoxFromObject(intersected.object, true);
                that.copyPosition = { ...that.position };
                that.copyPosition.z = box.min.z;
                const size = that.getModelSize(intersected.object, true);
                console.log("size", size);
                console.log("intersected.object", intersected.object);
                that.rotate.per = {
                  x: (size.x / intersected.object.initSize.x) * 100,
                  y: (size.y / intersected.object.initSize.y) * 100,
                  z: (size.z / intersected.object.initSize.z) * 100,
                };
                //选中模型时将模型旋转的角度赋值给变量
                that.spin = that.getSpinEluerAngel(intersected.object);
                console.log("that.spin", that.spin);
                //选中模型时将模型旋转的角度赋值给变量
                /*  that.spin = that.getSpinEluerAngel()
                console.log('spin',that.spin); */
                //产生平移轨道控件
                that.transformControls.attach(intersected.object);
                that.rotate.size = that.getModelSize(intersected.object, true);
                // 设置模型的颜色
                intersected.object.isSelected = true;
                if (that.objects.length === 1) {
                  that.checked = true;
                }
                for (let obj of that.objects) {
                  //如果模型不是选中的模型，将模型状态变为非选中，同时去除外边框改变回原来的颜色
                  if (obj !== intersected.object) {
                    obj.isSelected = false;
                    obj.material.uniforms.color.value.set(
                      ...that.color.unChecked
                    );
                    // scene.remove(that.modelMap.get(obj));
                    that.modelMap.get(obj).visible = false;
                  }
                }
                that.$forceUpdate();
                // intersected.object.material.uniforms.color.value.set(1,0,1);
                if (intersected.object.isShow) {
                  const lineSegments1 = that.modelMap.get(intersected.object);
                  lineSegments1.visible = true;
                  // scene.add(lineSegments1);
                } else {
                  const lineSegments1 = that.modelMap.get(intersected.object);
                  lineSegments1.visible = false;
                  // scene.add(lineSegments1);
                }
              } else {
                that.cancelSelectModels();
              }
            } else {
              that.cancelSelectModels();
            }
          }
          console.log("mesh.initSize", mesh);
          this.scene.add(mesh);
          this.scene.add(lineSegments);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    },
    //包围盒各顶点设置
    box(width, height, depth) {
      (width = width * 0.5), (height = height * 0.5), (depth = depth * 0.5);
      const geometry = new THREE.BufferGeometry();
      const position = [];
      position.push(
        -width,
        -height,
        -depth, // -15,-25,-5
        -width,
        -height + height / 2,
        -depth,
        -width,
        height - height / 2,
        -depth,
        -width,
        height,
        -depth,
        -width,
        height,
        -depth,
        -width + 0.7 * width,
        height,
        -depth,
        width - 0.7 * width,
        height,
        -depth,
        width,
        height,
        -depth,
        width,
        height,
        -depth,
        width,
        height - height / 2,
        -depth,
        width,
        -height + height / 2,
        -depth,
        width,
        -height,
        -depth,

        width,
        -height,
        -depth,
        width - 0.7 * width,
        -height,
        -depth,
        -width + 0.7 * width,
        -height,
        -depth,
        -width,
        -height,
        -depth,

        -width,
        -height,
        depth,
        -width,
        -height + height / 2,
        depth,
        -width,
        height - height / 2,
        depth,
        -width,
        height,
        depth,

        -width,
        height,
        depth,
        -width + 0.7 * width,
        height,
        depth,
        width - 0.7 * width,
        height,
        depth,
        width,
        height,
        depth,

        width,
        height,
        depth,
        width,
        height - height / 2,
        depth,
        width,
        -height + height / 2,
        depth,
        width,
        -height,
        depth,

        width,
        -height,
        depth,
        width - 0.7 * width,
        -height,
        depth,
        -width + 0.7 * width,
        -height,
        depth,
        -width,
        -height,
        depth,
        -width,
        -height,
        depth,
        -width,
        -height,
        depth - 0.5 * depth,
        -width,
        -height,
        -depth + 0.5 * depth,
        -width,
        -height,
        -depth,

        -width,
        height,
        depth,
        -width,
        height,
        depth - 0.5 * depth,
        -width,
        height,
        -depth + 0.5 * depth,
        -width,
        height,
        -depth,

        width,
        height,
        depth,
        width,
        height,
        depth - 0.5 * depth,
        width,
        height,
        -depth + 0.5 * depth,
        width,
        height,
        -depth,

        width,
        -height,
        depth,
        width,
        -height,
        depth - 0.5 * depth,
        width,
        -height,
        -depth,
        width,
        -height,
        -depth + 0.5 * depth
      );
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(position, 3)
      );
      return geometry;
    },
  },
  computed: {
    isShow() {
      return this.percentage === 100;
    },
    positionZ() {
      return {
        x: 0,
        y: 0,
        z: 0,
      };
    },
  },
  watch: {
    /* position: {
      handler(newVal, oldVal) {
        const selectedModel = this.objects.find((obj) => obj.isSelected);
        if (selectedModel) {
          this.position = selectedModel.position;
          // this.position.z = this.getModelSize(selectedModel).z / 2
          this.copyPosition = { ...this.position };
          this.copyPosition.z -= this.getModelSize(selectedModel).z / 2;
          console.log("this.copyPosition.z", this.copyPosition.z);
          console.log("this.position.z", this.position.z);
        }
      },
      deep: true,
    }, */
    "controls.rotate.isShowReal"(newVal, oldVal) {
      console.log("newVal", newVal);
      console.log("oldVal", oldVal);
    },
  },
  filters: {
    sizeToKb(size) {
      return (size / 1024).toFixed(1) + " KB";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-loading-mask {
  .el-loading-spinner {
    .el-icon-loading {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
.iconfont {
  color: #d92023;
  font-size: 20px;
}
.icon-a-shezhi1 {
  font-size: 25px;
}
.icon-qiepianpeizhi,
.icon-a-shuzhixuanze1,
.icon-a-jixingxuanze11,
.icon-a-qiepianpeizhi1,
.icon-a-moxingliebiao1,
.icon-a-qiepianpeizhi4 {
  font-size: 35px;
}
.icon-yidong,
.icon-suofang,
.icon-xuanzhuan,
.icon-fuzhi,
.icon-a-zidongbuju1 {
  font-size: 30px;
}
.activeControls {
  background-color: #d71518 !important;
}
@media screen and (max-width: 1570px) {
  .model-list {
    position: fixed !important;
    z-index: 9 !important;
    right: 15px !important;
    // top: 0 !important;
    top: calc(100vh - 493px) !important;
  }
}
.hello {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // height: 1030px;
  position: relative;
  .mask {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(245, 245, 245, 0.6);
    z-index: 9999;
    span.txt {
      color: #fff;
    }
  }

  .el-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /deep/ .el-progress__text {
      color: #fff !important;
    }
  }

  .print-setting {
    display: flex;
    position: absolute;
    .common {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 19vw;
      min-width: 300px;
      height: 60px;
      padding: 0 20px 0 40px;
      margin-right: 2px;
      background: #ffffff;
      border-radius: 0px 0px 8px 0px;
      .left {
        display: flex;
        align-items: center;
        span.name {
          margin-left: 10px;
          font-size: 18px;
          font-weight: 800;
          color: #333333;
        }
      }
      .right {
        i.icon-shezhi2 {
          font-size: 18px;
          color: #fff;
        }
        span.name {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .type {
      .right {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .resin {
      border-radius: 0px 0px 8px 8px;
    }
    .slicer {
      width: 31.77vw;
      min-width: 515px;
      border-radius: 0px 0px 8px 8px;
      .left {
        flex: 1;
        justify-content: space-between;
        align-items: center;
        .total {
          display: flex;
          align-items: center;
        }
      }
      span.mm {
        // margin-left: 144px;
        margin-right: 25px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 100px;
        height: 30px;
        padding: 0 0 0 20px;
        border-left: 1px solid #999;
        .support {
          display: flex;
          align-items: center;
          span.name {
            margin-right: 11px;
            font-size: 16px;
            font-weight: 800;
            color: #333333;
          }
          /deep/ .el-switch {
            span.el-switch__core {
              border-color: #d71518 !important;
            }
            span.el-switch__core::after {
              background-color: #d71518;
            }
          }
          /deep/ .is-checked {
            span.el-switch__core {
              border-color: #fff !important;
            }
            span.el-switch__core::after {
              background-color: #fff;
            }
          }
        }
        .setting {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 35px;
          background: #d71518;
          border-radius: 5px;
          cursor: pointer;
          i.icon-shezhi {
            color: #fff;
            font-size: 12px;
          }
          i.icon-shezhi::before {
            vertical-align: middle;
          }
          .icon-a-shezhi1 {
            color: #fff;
          }
          span.name {
            color: #fff;
            font-size: 16px;
            margin-left: 10px;
            line-height: 30px;
          }
        }
      }
    }
  }

  .control {
    position: absolute;
    top: 250px;
    left: 100px;
    ul li {
      position: relative;
      margin-bottom: 5px;
      .common-control {
        display: flex;
        width: 65px;
        height: 65px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        color: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        .iconfont {
          color: #fff;
        }
        span.txt {
          margin-top: 6px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      //move移动
      .dialog-move {
        position: absolute;
        z-index: 999;
        top: 0px;
        left: 80px;
        width: 230px;
        height: 200px;
        padding: 12px 15px 0 14px;
        border-radius: 10px;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.4);
        div.name {
          width: 28px;
          height: 14px;
          margin-bottom: 19px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
        }
        .move-control {
          ul li {
            display: flex;
            align-items: center;
            height: 24px;
            margin-bottom: 10px;
            span.axes {
              margin-right: 10px;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              transform: translateY(6px);
            }
            .input {
              position: relative;
              // width: 148px;
              height: 100%;
              .el-input-number {
                width: 100%;
                height: 24px;
                /deep/ .el-input-number__decrease {
                  cursor: pointer;

                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input-number__increase {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input {
                  input {
                    height: 24px;
                    text-align: left;
                    padding-left: 7px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0);
                  }
                }
              }
              span.mm {
                position: absolute;
                top: 50%;
                right: 23px;
                width: 23px;
                height: 8px;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
              }

              .icon-shangjiantou {
                position: absolute;
                top: 9px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }

              .icon-xiajiantou {
                position: absolute;
                bottom: -7px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }
            }
          }
        }
        .btns {
          display: flex;
          justify-content: space-around;
          height: 24px;
          margin-top: 20px;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: 23px;
          text-align: center;
          .sink {
            width: 90px;
            height: 24px;
            background: rgba(255, 255, 255, 0);
            border: 1px solid #ffffff;
            border-radius: 4px;
            cursor: pointer;
          }
          .center {
            width: 40px;
            height: 24px;
            margin: 0 10px;
            background: rgba(255, 255, 255, 0);
            border: 1px solid #ffffff;
            border-radius: 4px;
            cursor: pointer;
          }
          .reset {
            width: 55px;
            height: 24px;
            background: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 4px;
            color: #111;
            cursor: pointer;
          }
        }
      }
      .dialog-move::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 5px solid rgba(0, 0, 0, 0.4);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        left: -5px;
        top: 25px;
      }
      //缩放
      .dialog-scale {
        position: absolute;
        z-index: 999;
        top: 0px;
        left: 80px;
        width: 225px;
        height: 280px;
        padding: 12px 15px 0 14px;
        border-radius: 10px;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.4);
        div.name {
          // width: 28px;
          height: 14px;
          margin-bottom: 19px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
        }
        .scale-control-btns {
          display: flex;
          align-items: center;
          width: 200px;
          height: 24px;
          margin-bottom: 14px;
          padding: 3px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #ffffff;
          border-radius: 4px;
          div {
            width: 83px;
            height: 18px;
            line-height: 18px;
            background: #ffffff;
            border-radius: 2px;
            flex: 1;
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            // color: #fff;
            text-align: center;
            // line-height: 24px;
          }
        }
        .scale-control {
          ul li {
            display: flex;
            align-items: center;
            height: 24px;
            margin-bottom: 10px;
            span.axes {
              margin-right: 10px;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              transform: translateY(6px);
            }
            .input {
              position: relative;
              // width: 148px;
              height: 100%;
              .el-input-number {
                width: 100%;
                height: 24px;
                /deep/ .el-input-number__decrease {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input-number__increase {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input {
                  input {
                    height: 24px;
                    text-align: left;
                    padding-left: 7px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0);
                  }
                }
              }
              span.mm {
                position: absolute;
                top: 50%;
                right: 23px;
                width: 23px;
                height: 8px;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
              }
              span.per {
                position: absolute;
                top: 50%;
                right: 10px;
                width: 23px;
                height: 8px;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
              }
              .icon-shangjiantou {
                position: absolute;
                top: 9px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }

              .icon-xiajiantou {
                position: absolute;
                bottom: -7px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }
            }
          }
        }
        .locking-ratio {
          display: flex;
          justify-content: right;
          align-items: center;
          height: 30px;
          margin-top: 15px;
          span.name {
            margin-right: 10px;
            font-size: 14px;
            font-weight: 800;
            color: #ffffff;
          }
          /deep/ .el-switch {
            span.el-switch__core {
              border-color: #fff !important;
            }
            span.el-switch__core::after {
              background-color: #626267;
            }
          }
          /deep/ .is-checked {
            span.el-switch__core {
              border-color: #fff !important;
            }
            span.el-switch__core::after {
              background-color: #fff;
            }
          }
        }
        .btns {
          display: flex;
          height: 24px;
          margin-top: 16px;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: 23px;
          text-align: center;
          .scale-adapt {
            width: 121px;
            min-height: 24px;
            margin-right: 10px;
            background: rgba(255, 255, 255, 0);
            border: 1px solid #ffffff;
            border-radius: 4px;
            cursor: pointer;
          }
          .center {
            width: 40px;
            height: 24px;
            margin: 0 10px;
            background: rgba(255, 255, 255, 0);
            border: 1px solid #ffffff;
            border-radius: 4px;
            cursor: pointer;
          }
          .reset {
            width: 62px;
            height: 24px;
            background: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 4px;
            color: #111;
            cursor: pointer;
          }
        }
      }
      .dialog-scale::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 5px solid rgba(0, 0, 0, 0.4);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        left: -5px;
        top: 25px;
      }
      //rotate旋转
      .dialog-rotate {
        position: absolute;
        z-index: 999;
        top: 0px;
        left: 80px;
        width: 200px;
        height: 200px;
        padding: 12px 15px 0 14px;
        border-radius: 10px;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.4);
        div.name {
          width: 28px;
          height: 14px;
          margin-bottom: 19px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
        }
        .rotate-control {
          ul li {
            display: flex;
            align-items: center;
            height: 24px;
            margin-bottom: 10px;
            span.axes {
              margin-right: 10px;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              transform: translateY(6px);
            }
            .input {
              position: relative;
              // width: 148px;
              height: 100%;

              .el-input-number {
                width: 58px;
                height: 24px;
                /deep/.el-input__inner {
                  padding-right: 20px;
                }
                /deep/ .el-input-number__decrease {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input-number__increase {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input {
                  input {
                    height: 24px;
                    text-align: left;
                    padding-left: 7px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0);
                  }
                }
              }
              span.angle {
                position: absolute;
                top: 12px;
                right: 20px;
                width: 4px;
                height: 4px;
                border: 1px solid #fff;
                border-radius: 50%;
                background: #68696d 0.4;
                font-size: 14px;
                font-weight: 500;
              }
              .icon-shangjiantou {
                position: absolute;
                top: 9px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }

              .icon-xiajiantou {
                position: absolute;
                bottom: -7px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }
              .special {
                // right: -1px;
              }
            }
            span.common {
              position: absolute;
              left: 65px;
              top: 7px;
              width: 40px;
              height: 24px;
              line-height: 24px;
              background: rgba(0, 0, 0, 0);
              border: 1px solid #ffffff;
              border-radius: 4px;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              cursor: pointer;
            }
            span.sub45 {
              left: 110px;
            }
          }
        }
        .reset {
          width: 171px;
          height: 24px;
          line-height: 24px;
          margin-top: 20px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          color: #111111;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .dialog-rotate::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 5px solid rgba(0, 0, 0, 0.4);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        left: -5px;
        top: 25px;
      }
      //复制
      .dialog-clone {
        position: absolute;
        z-index: 999;
        top: 0px;
        left: 80px;
        width: 200px;
        height: 130px;
        padding: 12px 15px 0 14px;
        border-radius: 10px;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.4);
        div.name {
          // width: 28px;
          height: 14px;
          margin-bottom: 19px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
        }
        .clone-control {
          ul li {
            display: flex;
            align-items: center;
            height: 24px;
            margin-bottom: 10px;
            span.axes {
              margin-right: 10px;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              transform: translateY(6px);
            }
            .input {
              position: relative;
              width: 129px;
              height: 100%;
              .el-input-number {
                width: 100%;
                height: 24px;
                /deep/ .el-input-number__decrease {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input-number__increase {
                  cursor: pointer;
                  display: none;
                  width: 8px;
                  height: 6px;
                  border: none;
                }
                /deep/ .el-input {
                  input {
                    height: 24px;
                    text-align: left;
                    padding-left: 7px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0);
                  }
                }
              }
              .icon-shangjiantou {
                position: absolute;
                top: 9px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }

              .icon-xiajiantou {
                position: absolute;
                bottom: -7px;
                right: 4px;
                font-size: 12px;
                color: #fff;
              }
            }
          }
        }
        .apply {
          width: 171px;
          height: 24px;
          margin-top: 20px;
          line-height: 24px;
          font-size: 12px;
          font-weight: 500;
          color: #111;
          text-align: center;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      .dialog-clone::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 5px solid rgba(0, 0, 0, 0.4);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        left: -5px;
        top: 25px;
      }
    }
  }
  .model-list {
    z-index: 99;
    position: absolute;
    top: 15px;
    right: 135px;
    width: 310px;
    height: 420px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    transition: all 0.3s;
    .top {
      display: flex;
      height: 40px;
      justify-content: space-between;
      padding: 0px 20px;
      // span:last-child {
      //   min-width: 60px;
      //   height: 28px;
      //   line-height: 28px;
      //   padding: 0 5px;
      //   background: #f4f7fb;
      //   border-radius: 14px;
      //   border: 1px solid rgba(0, 89, 245, 0.14);
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   color: #0059f5;
      //   cursor: pointer;
      //   font-size: 12px;
      //   position: relative;
      // }
      .left {
        display: flex;
        height: 40px;
        line-height: 40px;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 14px;
        .icon-a-moxingliebiao1 {
          color: #fff;
          margin-right: 10px;
        }
        .icon-a-moxingliebiao1::before {
          vertical-align: middle;
        }
      }
      .center {
        height: 12px;
        font-size: 12px;
        font-weight: 500;
        color: #aaaaaa;
        line-height: 40px;
      }
      .right {
        color: #fff;
        line-height: 40px;
        cursor: pointer;
      }
      .el-icon-arrow-up::before,
      .el-icon-arrow-down::before {
        vertical-align: middle;
      }
    }
    ul {
      transition: all 0.3s;
      height: 322px;
      overflow: hidden;
      overflow-y: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
      li {
        display: flex;
        height: 80px;
        padding: 10px 14px;
        cursor: pointer;
        .icon {
          display: flex;
          align-items: center;
          margin-right: 9px;
          .iconfont {
            color: #ccc;
          }
        }
        .pic {
          width: 60px;
          height: 60px;
          border-radius: 8px;
          margin-right: 11px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }
        span.name {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #ccc;
          white-space: nowrap; /* 禁止换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 使用省略号替代 */
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      overflow: hidden;
      // margin-top: 1px;
      .addModel {
        width: 282px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #ffffff;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  //设置
  .setting-dialog {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1090px;
    height: 880px;
    background: #ffffff;
    border-radius: 20px;
  }

  .slicer-btn {
    position: absolute;
    z-index: 999;
    left: 50%;
    bottom: 58px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 52px;
    background: #d71518;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    i.icon-a-qiepianpeizhi4 {
      color: #fff;
    }
    span {
      margin-left: 5px;
      font-weight: 500;
      font-size: 18px;
    }
  }

  //添加模型
  .addModel {
    /deep/ .el-dialog {
      width: 900px;
      height: 700px;
      background: #ffffff;
      border-radius: 10px;
      padding: 30px 30px 0 30px;
      .el-dialog__header {
        padding: 0;
      }
      i.el-icon-close {
        position: absolute;
        right: -60px;
        top: 0;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 150px;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      .el-dialog__body {
        padding: 0;
      }
      .top {
        .label {
          display: flex;
          align-items: center;
          span.txt1 {
            font-weight: bold;
            font-size: 20px;
          }
          span.txt2 {
            margin-left: 20px;
            font-weight: 500;
            color: #999;
            font-size: 16px;
          }
        }
        .value {
          margin-top: 20px;
          ul {
            display: flex;
            flex-wrap: wrap;
            min-height: 90px;
            max-height: 200px;
            overflow-y: auto;
            li {
              display: flex;
              justify-content: space-between;
              width: 260px;
              height: 90px;
              margin-bottom: 15px;
              padding: 15px;
              margin-right: 15px;
              background: #f9f9f9;
              border: 1px solid #eeeeee;
              border-radius: 8px;
              .pic {
                width: 40px;
                height: 60px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .des {
                width: 150px;
                display: flex;
                flex-direction: column;
                margin: 0 0 0 10px;
                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #111111;
                  white-space: nowrap; /* 禁止换行 */
                  overflow: hidden; /* 超出部分隐藏 */
                  text-overflow: ellipsis; /* 使用省略号替代 */
                }
                .size {
                  margin-top: 5px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #666666;
                }
              }
              .el-icon-delete {
                font-size: 18px;
                color: #ff7071;
                cursor: pointer;
              }
            }
            li:nth-child(3n) {
              margin-right: 0px;
            }
          }
        }
      }
      .model-base {
        position: relative;
        margin-top: 20px;
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #000000;
        }
        .el-input {
          margin: 20px 0;
          input {
            border-radius: 8px;
          }
        }
        .corr-models {
          ul {
            display: flex;
            flex-wrap: wrap;
            max-height: 220px;
            overflow-y: auto;
            li {
              display: flex;
              justify-content: space-between;
              width: 260px;
              height: 90px;
              padding: 15px;
              margin-right: 10px;
              margin-bottom: 15px;
              background: #f9f9f9;
              border: 1px solid #eeeeee;
              border-radius: 8px;
              .pic {
                width: 60px;
                height: 60px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .des {
                display: flex;
                flex-direction: column;
                margin: 0 60px 0 10px;
                .name {
                  width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 16px;
                  font-weight: 500;
                  color: #111111;
                }
                .size {
                  margin-top: 5px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #666666;
                }
              }
              .icon-drag_2 {
                font-size: 18px;
                color: #999999;
                cursor: pointer;
              }
            }
            li:nth-child(3n) {
              margin-right: 0px;
            }
          }
        }
        .searchList {
          ul {
            display: flex;
            flex-wrap: wrap;
            max-height: 300px;
            overflow: auto;
            li {
              cursor: pointer;
              display: flex;
              width: 270px;
              height: 90px;
              padding-top: 15px;
              border: 1px solid #eee;
              border-radius: 8px;
              .pic {
                width: 60px;
                height: 60px;
                margin: 0 10px 0 15px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
              .modelInfo {
                .modelName {
                  font-size: 16px;
                  font-weight: 500;
                  color: #111;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 180px;
                }
                .author {
                  display: flex;
                  align-items: center;
                  margin: 7px 0 5px;
                  .avat {
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      object-fit: cover;
                    }
                  }
                  .name {
                  }
                }
                .like {
                  display: flex;
                  align-items: center;
                  .icon-icon_collect {
                    margin-right: 15px;
                  }
                }
              }
            }
          }
          .empty {
            font-size: 18px;
            color: #111;
          }
        }
      }
      .btn {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        button {
          width: 140px;
          height: 40px;
          margin: 0 auto;
          background: #d71518;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }
  //mask
  .mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    //提示模型过大或过小
    .dialog-modelSize {
      position: absolute;
      z-index: 999999999;
      left: 50%;
      top: 20%;
      width: 380px;
      height: 200px;
      padding: 15px 10px 0;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      background: #fff;
      .tip {
        position: relative;
        font-weight: 500;
        color: #000;
        font-size: 16px;
        text-align: center;
        .icon-a-guanbi2 {
          position: absolute;
          top: -6px;
          right: 0;
          border: none;
          border-radius: 0;
          color: #333;
          font-size: 30px;
          cursor: pointer;
        }
      }
      .txt {
        width: 300px;
        font-size: 12px;
        line-height: 20px;
        margin: 45px auto 30px;
        color: #000;
        text-align: center;
      }
      .btns {
        display: flex;
        justify-content: center;
        margin: 0;
        span {
          width: 80px;
          height: 30px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          line-height: 30px;
          text-align: center;
        }
        .confirm {
          background-color: #d71518;
          color: #fff;
          margin-right: 45px;
        }
        .cancel {
          border: 1px solid #a0a0a0;
        }
      }
    }
    //切片提示模型超出边界
    .dialog-bounding {
      position: absolute;
      z-index: 999999999;
      left: 50%;
      top: 20%;
      width: 380px;
      // height: 200px;
      padding: 15px 0 20px;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      background: #fff;

      .tip {
        position: relative;
        font-weight: 500;
        color: #000;
        font-size: 16px;
        text-align: center;
        .icon-a-guanbi2 {
          position: absolute;
          top: -6px;
          right: 0;
          border: none;
          border-radius: 0;
          color: #333;
          font-size: 30px;
          cursor: pointer;
        }
      }
      .txt {
        width: 235px;
        font-size: 12px;
        line-height: 20px;
        margin: 45px auto 30px;
        color: #000;
      }
      .btns {
        display: flex;
        justify-content: center;
        margin: 0;
        span {
          width: 80px;
          height: 30px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          line-height: 30px;
          text-align: center;
          background-color: #d71518;
          color: #fff;
        }
      }
    }
    //有模型全部超出弹窗
    .dialog-excludeModel {
      position: absolute;
      z-index: 999999;
      left: 50%;
      top: 20%;
      width: 380px;
      height: 200px;
      padding: 15px 10px 0;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      background: #fff;

      .tip {
        position: relative;
        font-weight: 500;
        color: #000;
        font-size: 16px;
        text-align: center;
        .icon-a-guanbi2 {
          position: absolute;
          top: -6px;
          right: 0;
          border: none;
          border-radius: 0;
          color: #333;
          font-size: 30px;
          cursor: pointer;
        }
      }
      .txt {
        width: 290px;
        font-size: 12px;
        line-height: 20px;
        margin: 45px auto 30px;
        color: #000;
      }
      .btns {
        display: flex;
        justify-content: center;
        margin: 0;
        span {
          width: 80px;
          height: 30px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          line-height: 30px;
          text-align: center;
        }
        .confirm {
          background-color: #d71518;
          color: #fff;
          margin-right: 45px;
        }
        .cancel {
          border: 1px solid #a0a0a0;
        }
      }
    }
    //有模型全部超出弹窗
    .delFinalSingleModelDialog,
    .modelHoverDialog {
      position: absolute;
      z-index: 999999;
      left: 50%;
      top: 20%;
      width: 380px;
      height: 200px;
      padding: 15px 10px 0;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      background: #fff;
      .tip {
        position: relative;
        font-weight: 500;
        color: #000;
        font-size: 16px;
        text-align: center;
        .icon-a-guanbi2 {
          position: absolute;
          top: -6px;
          right: 0;
          border: none;
          border-radius: 0;
          color: #333;
          font-size: 30px;
          cursor: pointer;
        }
      }
      .txt {
        width: 250px;
        font-size: 12px;
        line-height: 20px;
        margin: 45px auto 30px;
        color: #000;
      }
      .btns {
        display: flex;
        justify-content: center;
        margin: 0;
        div {
          width: 80px;
          height: 30px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          line-height: 30px;
          text-align: center;
        }
        .confirm {
          background-color: #d71518;
          color: #fff;
          margin-right: 45px;
        }
        .cancel {
          border: 1px solid #a0a0a0;
        }
      }
    }
  }

  //退出
  .exit-edit {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 60px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    border-radius: 5px;
    background: #68696d;
    cursor: pointer;
    .icon-a-Frame8 {
      color: #fff;
    }
  }
  .exit-edit:hover {
    background-color: #d71518;
  }
}
</style>
