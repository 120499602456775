export default {
  preview: {
    modelLoading: 'モデルの読み込み中',
    setting: '設定',
    fold: '折りたたむ',
    color: 'カラー',
    displayStyle: '表示方法',
    default: 'デフォルト',
    transparent: '透明',
    dashed: '点線',
    background: '背景',
    dark: 'ダーク',
    light: 'ライト',
    displayGrid: 'グリッドの表示',
    frontView: 'フロントビュー',
    backView: 'バックビュー',
    leftSideView: '左側ビュー',
    rightSideView: '右側ビュー',
    topView: 'トップビュー',
    bottomView: 'ボトムビュー',
    close: '閉じる',
    zoomIn: '拡大',
    zoomOut: '縮小',
    reset: 'リセット',
    markSize: 'マークのサイズ',
    degreeeDisplay360: '360度表示',
    fullscreen: 'フルスクリーン',
    exitFullscreen: 'フルスクリーンを終了',
    slices: 'スライス',
    modelSize: 'モデルサイズ'
    },
    slicer: {
      selectSingleModel: '最初に単一のモデルを選択してください',
      devSel: 'デバイスの選択',
      resSel: 'レジンの選択',
      slicConfig: 'スライスの設定',
      set: '設定',
      move: '移動',
      scale: 'スケール',
      rotate: '回転',
      autoLayout: '自動配置',
      copy: 'コピー',
      slicer: 'スライサー',
      modelList: 'モデルリスト',
      loadedModel: 'ロードされたモデル',
      addModel:'モデルの追加',
      modelSink: 'モデルのシンク',
      center: '中央',
      reset: 'リセット',
      actual: '実際',
      percentage: 'パーセンテージ',
      lockRatio: '比率をロック',
      scaleToFit: 'フィットするようにスケール',
      quantity: '数量',
      apply: '適用',
      modelLoading: 'モデルの読み込み中',
      autoLayoutInPro: '自動配置中',
      autoLayoutComp: '自動配置完了'
  },
  printerSelect: {
    yes: 'はい',
    no: 'いいえ',
    title: '3Dプリンターの選択',
    choosePrinter: '3Dプリンターを選択または追加',
    manageMachines: 'マシンの管理',
    addDefineMachines: 'カスタムマシンを追加',
    msg1: 'カスタムマシン名は空白にできません',
    msg2: 'X軸は空白にできません',
    msg3: 'Y軸は空白にできません',
    msg4: 'Z軸は空白にできません',
    msg5: '水平分解能は空白にできません',
    msg6: '垂直分解能は空白にできません',
    msg7: 'マシン画像を追加してください',
    picSizeLimit: 'アップロードする画像のサイズは2MB以下にしてください！',
    updateSuc: '更新成功',
    updateFail: '更新失敗',
    truPicFormat: '正しい画像形式をアップロードしてください',
    delSuc: '削除成功',
    delFail: '削除失敗',
    addSuc: '追加成功',
    addFail: '追加失敗',
    resinOptions: [
      '標準剛性樹脂','ABS樹脂','水洗い樹脂','植物由来樹脂'
    ],
    confileDialog: {
      selectConfigFile: '設定ファイルを選択',
      selected: '選択済み',
      defaultConfig: 'デフォルト設定',
      defineConfig: 'カスタム設定',
      resin: '樹脂',
      layThick: '層厚',
      allPars: '全てのパラメータ',
      machineType: '機種'
    },
    resinSlicePar: {
      label: '樹脂-スライスパラメータ',
      value: '樹脂と印刷の設定を行います',
      saveAs: '名前を付けて保存',
    },
    printSet: {
      title: '印刷設定',
      resinType: {
        label: '樹脂の種類',
        value: '印刷に使用する樹脂材料の種類。'
      }, 
      layThick: {
        label: '層厚',
        value: '層厚は0.1～0.025mmの範囲で設定します。'
      },
      singLayExpTime: '単層露光時間',
      bottomLayExpTime: '底層露光時間',
      expDelay: '露光遅延',
      bottomLayExpLay: '底層露光層数',
      bottomLayExpTranLay: '底層露光過渡層数',
      liftHeight1: 'リフト高さ1',
      lifgSpeed1: 'リフト速度1',
      liftHeight2: 'リフト高さ2',
      lifgSpeed2: 'リフト速度2',
      lowerSpeed: '降下速度',
      zAxLiftAfterPrint: '印刷後にZ軸をリフト'
    },
    highSet: {
      fine: '細かい',
      medium: '中程度',
      coarse: '粗い',
      modelLiftHeight: 'モデルリフトの高さ',
      supportType: 'サポートの種類',
      title: '高度な設定',
      innerDiaComp: '内径補償',
      outDiaComp: '外径補償',
      xAxisScaleComp: 'X軸のスケール補償',
      yAxisScaleComp: 'Y軸のスケール補償',
      zAxisScaleComp: 'Z軸のスケール補償',
      antial: {
        label: 'アンチエイリアス',
        value: 'レベルが“1”の場合、アンチエイリアスはオフです。'
      },
      autoSup: '自動サポート',
      antDisAtLevel1: 'レベルが“1”の場合、アンチエイリアスはオフです',
      grayLevel: 'グレースケールレベル',
      imgBlurPx: '画像のぼかしピクセル',
      supDensity: 'サポート密度',
      supDensityTip: '数値が大きいほど、追加されるサポートがより密集します',
      addBase: 'ベースを追加',
      open: 'オン',
      close: 'オフ'
    },
    confirm: '確認',
    cancel: 'キャンセル',
    save: {
      title: 'パラメータ設定の名前を保存',
      placeholder: '30文字以内で設定名を入力してください         '
    },
    newAddMachine: '新しいカスタムマシンを追加',
    machineName: 'マシン名',
    machineNamePlaceholder: '30文字以内でマシン名を入力してください',
    addMachinePic: 'マシン画像を追加',
    newAddMachineSave: '保存',
    platSize: 'プラットフォームサイズ',
    resolution: '解像度',
    illustration: '図解',
    itHaveMonitoring: '監視機能がありますか',
    newAddDefineMachine: {
      title: '新しいカスタムマシンを追加',
      addMachinePic: 'マシン画像を追加'
    },
    define: 'カスタム',
    delete: '削除',
    updatePic: '画像を更新',
    config: {
      label: '設定ファイルの選択',
      value: '選択済み',
      resin: 'レジン',
      layThick: '層の厚さ',
      defaultConfig: 'デフォルト設定',
      defineConfig: 'カスタム設定',
      allPar: 'すべてのパラメータ',
      machineType: '機種',
      cancel: 'キャンセル'
    },
    delDialog : {
      tip: '注意',
      txt: 'この設定パラメータを削除しますか？削除すると復元できません',
      confirm: '確定',
      cancel: 'キャンセル'
    },
    outOfBoundDialog : {
      tip: '注意',
      txt: 'モデルがプリンターの境界を超えています。モデルの位置やサイズを調整してください(モデルにサポートを追加すると印刷範囲を超える可能性があります)。',
      know: '了解'
    },
    adaptModelDialog : {
      tip: '注意',
      txt: 'モデルが大きすぎるか小さすぎる可能性があります。適切なサイズに調整しますか？',
      confirm: '確定',
      cancel: 'キャンセル'
    },
    addModelDialog : {
       modelList: {
        label: 'モデルリスト',
        value: 'モデルリストにドラッグしてモデルを追加できます'
       },
       modelBase: 'モデルライブラリ',
       searchModel: 'モデルを検索',
       noResults: '検索結果はありません'
    },
    excludeModelDialog: {
      tip: '注意',
      txt: '一部のモデルがプリンターの境界を超えています。これらのモデルを今回のスライスから除外しますか？',
      confirm: 'はい、スライスへ',
      cancel: 'キャンセル'
    },
    delFinalSingleModelDialog: {
      tip: '注意',
      txt: '削除するとスライスプレビューが終了します。続行しますか？',
      yes: 'はい',
      no: 'いいえ'
    },
    modelHoverDialog : {
      tip: '注意',
      txt: 'モデルが浮いています。続行しますか？これはモデルの印刷エラーを引き起こす可能性があります。',
      yes: 'はい',
      no: 'いいえ'
    }
  }
}