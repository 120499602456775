import {
	BufferAttribute,
	BufferGeometry,
	FileLoader,
	Float32BufferAttribute,
	Loader,
	Vector3
} from 'three';
/**
 * Description: A THREE loader for STL ASCII files, as created by Solidworks and other CAD programs.
 *
 * Supports both binary and ASCII encoded files, with automatic detection of type.
 *
 * The loader returns a non-indexed buffer geometry.
 *
 * Limitations:
 *  Binary decoding supports "Magics" color format (http://en.wikipedia.org/wiki/STL_(file_format)#Color_in_binary_STL).
 *  There is perhaps some question as to how valid it is to always assume little-endian-ness.
 *  ASCII decoding assumes file is UTF-8.
 *
 * Usage:
 *  const loader = new STLLoader();
 *  loader.load( './models/stl/slotted_disk.stl', function ( geometry ) {
 *    scene.add( new THREE.Mesh( geometry ) );
 *  });
 *
 * For binary STLs geometry might contain colors for vertices. To use it:
 *  // use the same code to load STL as above
 *  if (geometry.hasColors) {
 *    material = new THREE.MeshPhongMaterial({ opacity: geometry.alpha, vertexColors: true });
 *  } else { .... }
 *  const mesh = new THREE.Mesh( geometry, material );
 *
 * For ASCII STLs containing multiple solids, each solid is assigned to a different group.
 * Groups can be used to assign a different color by defining an array of materials with the same length of
 * geometry.groups and passing it to the Mesh constructor:
 *
 * const mesh = new THREE.Mesh( geometry, material );
 *
 * For example:
 *
 *  const materials = [];
 *  const nGeometryGroups = geometry.groups.length;
 *
 *  const colorMap = ...; // Some logic to index colors.
 *
 *  for (let i = 0; i < nGeometryGroups; i++) {
 *
 *		const material = new THREE.MeshPhongMaterial({
 *			color: colorMap[i],
 *			wireframe: false
 *		});
 *
 *  }
 *
 *  materials.push(material);
 *  const mesh = new THREE.Mesh(geometry, materials);
 */


class STLLoader1 extends Loader {

	constructor(manager) {

		super(manager);

	}

	onStartParse =()=> {

	}
	
	load(url, onLoad, onProgress, onError) {

		const scope = this;

		const loader = new FileLoader(this.manager);
		loader.setPath(this.path);
		loader.setResponseType('arraybuffer');
		loader.setRequestHeader(this.requestHeader);
		loader.setWithCredentials(this.withCredentials);

		loader.load(url,  (text) => {

			try {
				this.onStartParse();
				onLoad(scope.parse(text));

			} catch (e) {

				if (onError) {

					onError(e);

				} else {

					console.error(e);

				}

				scope.manager.itemError(url);

			}

		}, onProgress, onError);

	}

	parse(data) {
		function parseBinary(data) {

			const reader = new DataView(data);
			let readOffset = 0;
			const vertBufferSize = reader.getBigUint64(0, true);
			readOffset += 8;

			const floatSize = 4;
			const bytesPerVert = floatSize * 6;
			const floatNumberTotal = Number(vertBufferSize) / floatSize;

			const geometry = new BufferGeometry();

			let vertArray = new Float32Array(floatNumberTotal / 2);
			let normalArray = new Float32Array(floatNumberTotal / 2);

			const VertCount = Number(vertBufferSize) / bytesPerVert;

			let offsetBuffer = 0;
			let vi = 0;
			let ni = 0;
			for (let i = 0; i < VertCount; i++) {

				for (let j = 0; j < 3; j++) {
					let off = 8 + offsetBuffer * floatSize;

					vertArray[vi] = reader.getFloat32(off, true);
					vi++;
					offsetBuffer++;
				}
				for (let j = 0; j < 3; j++) {
					let off = 8 + offsetBuffer * floatSize;

					normalArray[ni] = reader.getFloat32(off, true);
					ni++;
					offsetBuffer++;
				}
			}

			let icBase = 8 + Number(vertBufferSize);
			
			const indiceBufferSize = reader.getBigUint64(icBase, true);
			const iCount = Number(indiceBufferSize) / 4;
			let indiceArray = new Uint32Array(iCount);
			let iBase = 8 + icBase;

			for(let i = 0;i<iCount;i++){
				indiceArray[i] = reader.getUint32(iBase+i*4,true);
			}




			geometry.setAttribute('position', new BufferAttribute(vertArray, 3));
			geometry.setAttribute('normal', new BufferAttribute(normalArray, 3));

			geometry.setIndex(new BufferAttribute(indiceArray,1));

			console.log(geometry);
			console.log('parse finished');
			return geometry;
		}



		function ensureString(buffer) {

			if (typeof buffer !== 'string') {

				return new TextDecoder().decode(buffer);

			}

			return buffer;

		}

		function ensureBinary(buffer) {

			if (typeof buffer === 'string') {

				const array_buffer = new Uint8Array(buffer.length);
				for (let i = 0; i < buffer.length; i++) {

					array_buffer[i] = buffer.charCodeAt(i) & 0xff; // implicitly assumes little-endian

				}

				return array_buffer.buffer || array_buffer;

			} else {

				return buffer;

			}

		}

		const binData = ensureBinary(data);

		return parseBinary(binData);

	}

}

export { STLLoader1 };
