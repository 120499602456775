import axios from 'axios'
axios.defaults.baseURL = 'https://code.core.octopusworks.com'
//axios.defaults.baseURL = 'http://10.60.201.39:8080'                                                    
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  let lang = localStorage.getItem('lang') || 'zh'
  console.log('request',lang);
  if(lang === 'zh') {
    config.headers['Accept-Language'] = 'zh_CN'
  } else if(lang === 'en') {
    config.headers['Accept-Language'] = 'en-US'
  } else if(lang === 'ja') {
    config.headers['Accept-Language'] = 'ja-JP'
  }
  if(token) {
    console.log('token',localStorage.getItem('token'));
    config.headers.Authorization = token
    // 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImJiZjY0ODNhLWM0NGEtNDVlNS05MzE0LTI2M2M2YzBjM2Y3YiJ9.NKxnzlTEqOYl9Z_9l48vXh7gfO_qbD44EMES62zX18TW_fZpsIyhU4lVduRNfb8raDFnHV0s6FQ3lzG6J-4qHw'
    // 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImJiMzJlNTZhLTg0YjgtNGNiMy1hYWQ2LWNmMWI1MGRkNDVmZiJ9.0NX_oqPCjEfWcHLBnTZAEM77iv2KaFC2fhUrG1lOrCzbaAv0doIVFXCjwTQB5dxbP7gm6I0v2aRvB_vWS5HwxQ'
  }
  return config
})
axios.interceptors.response.use(res => {
  // console.log('res',res);
  console.log('response',res);
  res = res.data
  if(res.code === 401) {
    //token 失效重新跳转到登录页面
    console.log('token失效');
    return window.parent.postMessage("tokenOverdue", "*");
  }
   return res
})
export default axios