<template>
  <div class="hello">
    <el-progress
      type="circle"
      :percentage="percentage"
      v-if="!isShow"
    ></el-progress>
    <div class="control">
      <ul>
        <li style="cursor: pointer">
          <span>移动</span>
          <!-- <div class="block">
            <el-slider
              :show-tooltip="false"
              v-model="value"
              show-input
              step="0.01"
            >
            </el-slider>
          </div> -->
        </li>
        <li style="cursor: pointer">缩放</li>
        <li style="cursor: pointer">旋转</li>
        <li style="cursor: pointer" @click="cloneModel">克隆</li>
        <li style="cursor: pointer" @click="selectAllModels">选中全部模型</li>
        <li style="cursor: pointer" @click="cancelSelectModels">
          取消选中的模型
        </li>
        <li style="cursor: pointer" @click="layout">自动布局</li>
      </ul>
    </div>
    <div class="model-list">
      <h2>模型列表</h2>
      <ul>
        <li
          v-for="(obj, index) in objects"
          :key="index"
          :style="{ backgroundColor: obj.isSelected ? 'pink' : '#fff' }"
        >
          <span v-if="obj.isShow" @click="showModel(obj)"
            ><i class="iconfont icon-bxs-show"></i
          ></span>
          <span v-else @click="hiddenModel(obj)"
            ><i class="iconfont icon-hideinvisiblehidden"></i
          ></span>
          <span>模型{{ ++index }}</span>
        </li>
      </ul>
    </div>
    <div class="canvas" ref="canvasDom" v-show="isShow"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { DragControls } from "three/examples/jsm/controls/DragControls";
// import stlPath from '../../public/static/100mm_yuxi_part_2_.stl'
export default {
  name: "HelloWorld",
  data() {
    return {
      value: 0,
      scene: null,
      camera: null,
      renderer: null,
      gridHelper: null,
      axes: null,
      OrbitControls: null,
      dragControls: null,
      light: null,
      loader: null,
      publicPath: process.env.BASE_URL,
      percentage: 0,
      objects: [],
      modelMap: new Map(),
      printerSize: {
        x: 163.91,
        y: 102.4,
        z: 150.0,
      },
      modelSize: null,
    };
  },
  mounted() {
    //初始化
    this.init();
  },
  methods: {
    showModel(obj) {
      obj.isShow = false;
      obj.visible = false;
      obj.isSelected = false;
      this.$forceUpdate();
    },
    hiddenModel(obj) {
      obj.isShow = true;
      obj.visible = true;
      this.$forceUpdate();
    },
    cloneModel() {
      this.cancelSelectModels();
      const cloneModel = this.objects[0].clone();
      console.log(cloneModel);
      // cloneModel.position.set(1, 1, 1);
      cloneModel.isShow = true;
      cloneModel.visible = true;
      cloneModel.isSelected = false;
      this.objects.push(cloneModel);
      //获得模型尺寸
      const size = this.getModelSize(cloneModel);
      const lineSegments = this.getBoundingBox(size, cloneModel);
      this.modelMap.set(cloneModel, lineSegments);
      this.layout();
      this.scene.add(cloneModel);
    },
    selectAllModels() {
      for (let obj of this.objects) {
        obj.isSelected = true;
        //给模型改变颜色
        setTimeout(() => {
          if (obj.isSelected) {
            obj.material.color.set("rgb(0, 102, 203)");
          }
        });
        const lineSegments = this.modelMap.get(obj);
        this.scene.add(lineSegments);
      }
    },
    //取消选中模型
    cancelSelectModels() {
      for (let obj of this.objects) {
        if (obj.isSelected) {
          const lineSegments = this.modelMap.get(obj);
          this.scene.remove(lineSegments);
          obj.material.color.set("rgb(106, 106, 106)");
          obj.isSelected = false;
        }
      }
    },
    //自动布局
    layout() {
      this.cancelSelectModels();
      // 计算模型的数量
      const modelNum = this.objects.length;
      // 计算每行模型的数量
      const rowNum = Math.ceil(Math.sqrt(modelNum));
      // 计算每行模型的间距
      const rowSpace = (rowNum - 1) * 25;
      // 计算每列模型的间距
      const colSpace = (rowNum - 1) * 25;
      // 计算模型的起始位置
      const startX = -rowSpace / 2;
      const startY = -colSpace / 2;
      // 遍历模型，设置模型的位置
      for (let i = 0; i < modelNum; i++) {
        let rowIndex = i % rowNum;
        let colIndex = Math.floor(i / rowNum);
        let x = startX + rowIndex * 27;
        let y = startY + colIndex * 27;
        this.objects[i].position.set(
          x,
          y,
          -(this.printerSize.z / 2 - this.modelSize.z / 2)
        );
        console.log("line", this.modelMap.get(this.objects[i]));
        this.modelMap
          .get(this.objects[i])
          .position.set(
            this.objects[i].position.x,
            this.objects[i].position.y,
            this.objects[i].position.z
          );
      }
    },

    //获得模型尺寸
    getModelSize(mesh) {
      // 获取模型的最大最小坐标
      const box = new THREE.Box3().setFromObject(mesh);
      const min = box.min;
      const max = box.max;
      // 计算模型的缩放比例
      const scale = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);
      const size = new THREE.Vector3();
      // size.x = (max.x - min.x) * (3 / scale);
      // size.y = (max.y - min.y) * (3 / scale);
      // size.z = (max.z - min.z) * (3 / scale);
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      return size;
    },
    //根据模型尺寸获得包围盒
    getBoundingBox(size, mesh) {
      const geometryBox = this.box(size.x, size.y, size.z);
      const lineSegments = new THREE.LineSegments(
        geometryBox,
        new THREE.LineBasicMaterial({ color: "rgb(0, 102, 203)" })
      );
      lineSegments.name = "box";
      lineSegments.computeLineDistances();
      // lineSegments.rotation.x = -Math.PI / 2;
      lineSegments.position.set(
        mesh.position.x,
        mesh.position.y,
        mesh.position.z
      );
      return lineSegments;
    },
    //创建场景、相机、渲染器、网格面、辅助坐标、控制器
    init() {
      this.initScene();
      this.initCamera();
      this.initRenderer();
      this.initGridHelper();
      this.initExterGeometry();
      this.initAxes();
      this.initOrbitControls();
      this.initLight();
      this.animate();
      this.loadStlModel();
    },
    //初始化场景
    initScene() {
      const scene = new THREE.Scene();
      this.scene = scene;
      scene.background = new THREE.Color("rgb(229,238,244)");
    },
    //初始化相机
    initCamera() {
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera = camera;
      camera.position.y = -250;
      // camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
      const target = new THREE.Vector3(0, 0, 0);
      camera.up = new THREE.Vector3(0, 0, 1);
      camera.lookAt(target);
    },
    //初始化渲染器
    initRenderer() {
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer = renderer;
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasDom.appendChild(renderer.domElement);
    },
    // 添加网格地面
    initGridHelper() {
      const gridHelper = new THREE.GridHelper(
        this.printerSize.x,
        20,
        "PINK",
        0xffffff
      );
      this.gridHelper = gridHelper;
      gridHelper.position.z = -this.printerSize.z / 2;
      gridHelper.rotation.x = -Math.PI / 2;
      gridHelper.scale.z = this.printerSize.y / this.printerSize.x;
      this.scene.add(gridHelper);

      // 创建一个新的网格
      /* var geometry = new THREE.PlaneGeometry(
        this.printerSize.x,
        this.printerSize.y,
        20,
        14
      );
      const wireframe = new THREE.WireframeGeometry(geometry);
      const line = new THREE.LineSegments(wireframe);
      line.position.z = -this.printerSize.z / 2
      this.scene.add(line); */

      /* // 创建一个新的材质
      var material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
      });

      // 创建一个新的网格
      var plane = new THREE.Mesh(geometry, material);
      plane.position.z = -this.printerSize.z / 2 */
      // 将网格添加到场景中
      // this.scene.add(plane);
    },
    //创建搭建模型外部的几何体
    initExterGeometry() {
      const { x, y, z } = this.printerSize;
      const geometry = new THREE.BoxGeometry(x, y, z);
      // define the wireframe of the cube
      const wireframe = new THREE.EdgesGeometry(geometry);

      // define the material of the wireframe
      const material = new THREE.LineBasicMaterial({
        color: "rgb(114,198,249)",
        linewidth: 20,
      });
      // combine the wireframe and material
      const cube = new THREE.LineSegments(wireframe, material);
      //设置正方体中心点
      const center = new THREE.Vector3(-3.5, -3.5, 3.5);
      cube.userData.selectable = false;
      cube.geometry.center();
      // add the wireframe to the scene
      this.scene.add(cube);
    },
    //初始化辅助坐标轴
    initAxes() {
      const axes = new THREE.AxesHelper(50);
      const { x, y, z } = this.printerSize;
      this.axes = axes;
      axes.position.set(-x / 2, -y / 2, -z / 2);
      this.scene.add(axes);
    },
    //初始化轨道控制器
    initOrbitControls() {
      const orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      this.orbitControls = orbitControls;
      //启用阻尼
      orbitControls.enableDamping = true;
      orbitControls.dampingFactor = 0.5;
      //是否开启右键拖拽
      orbitControls.enablePan = false;
      orbitControls.update();
    },
    //初始化拖拽控制器
    initDragControls(mesh) {
      this.dragControls = new DragControls(
        mesh,
        this.camera,
        this.renderer.domElement
      );
      return this.dragControls;
    },
    //初始化光照
    initLight() {
      const ambienLight = new THREE.AmbientLight(0xfff, 0.1);
      this.scene.add(ambienLight);
      const light = new THREE.DirectionalLight(0xffffff, 1.5);
      this.light = light;
      light.position.set(0, 0, 0);
      this.scene.add(light);
    },
    animate() {
      this.orbitControls.update();
      this.light.position.set(
        this.camera.position.x,
        this.camera.position.y,
        this.camera.position.z
      );
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    },
    //加载stl模型
    loadStlModel() {
      const loader = new STLLoader();
      this.loader = loader;
      loader.load(
        `${this.publicPath}model/哆啦A梦.stl`,
        (geometry) => {
          const material = new THREE.MeshLambertMaterial({
            color: "rgb(106, 106, 106)",
            opacity: 1,
          });
          const camera = this.camera;
          const scene = this.scene;
          const mesh = new THREE.Mesh(geometry, material);
          mesh.isSelected = false;
          this.scene.add(mesh);
          //获取stl模型尺寸
          // 获取模型的最大最小坐标
          const boxObj = this.scene.children[1];
          const box = new THREE.Box3().setFromObject(mesh);
          const min = box.min;
          const max = box.max;
          // 计算模型的缩放比例
          const scale = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);
          // 设置模型的缩放比例
          // mesh.scale.set(3 / scale, 3 / scale, 3 / scale);
          //计算模型尺寸
          const size = new THREE.Vector3();
          // size.x = (max.x - min.x) * (3 / scale);
          // size.y = (max.y - min.y) * (3 / scale);
          // size.z = (max.z - min.z) * (3 / scale);
          size.x = max.x - min.x;
          size.y = max.y - min.y;
          size.z = max.z - min.z;
          this.modelSize = size;
          // 计算模型的中心点坐标
          const center = new THREE.Vector3();
          center.x = (max.x + min.x) / 2;
          center.y = (max.y + min.y) / 2;
          center.z = (max.z + min.z) / 2;
          //加载后使模型居中
          geometry.center();
          // 设置模型的位置
          mesh.position.set(0, 0, -(this.printerSize.z / 2 - size.z / 2));
          //生成一个包围盒
          const lineSegments = this.getBoundingBox(size, mesh);
          mesh.isShow = true;
          mesh.isSelected = false;
          this.objects.push(mesh);
          this.modelMap.set(mesh, lineSegments);
          // 创建鼠标拾取器
          const raycaster = new THREE.Raycaster();
          const mouse = new THREE.Vector2();
          let that = this;
          // 添加鼠标点击事件
          this.$refs.canvasDom.addEventListener("click", onMouseDown, false);
          function onMouseDown(event) {
            // 获取鼠标点击位置
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            // 设置射线
            raycaster.setFromCamera(mouse, camera);
            // 获取射线与模型相交的数组
            const intersects = raycaster.intersectObjects(that.objects);
            // 如果有相交的模型
            if (intersects.length > 0) {
              // 获取第一个相交的模型
              const intersected = intersects[0];
              if (intersected.object instanceof THREE.Mesh) {
                intersected.object.material.color.set("rgb(106, 106, 106)");
                const material = new THREE.MeshLambertMaterial({
                  color: "rgb(0, 102, 203)",
                  opacity: 1,
                });
                that.dragControls = that.initDragControls(intersected.object);
                // 拖拽开始时触发
                that.dragControls.addEventListener(
                  "dragstart",
                  function (event) {
                    console.log('drag');
                    event.object.material.emissive.set(0xaaaaaa);
                  }
                );
                /* // 创建一个箭头，指向z轴的正方向
                const arrow = new THREE.ArrowHelper(
                  new THREE.Vector3(1, 0, 0),
                  new THREE.Vector3(0, -2, 0),
                  3
                );
                arrow.setColor("red");
                // 创建一个箭头，指向z轴的正方向
                const arrow2 = new THREE.ArrowHelper(
                  new THREE.Vector3(0, 1, 0),
                  new THREE.Vector3(0, -2, 0),
                  3
                );
                arrow2.setColor("blue");
                // 创建一个箭头，指向z轴的正方向
                const arrow3 = new THREE.ArrowHelper(
                  new THREE.Vector3(0, 0, 1),
                  new THREE.Vector3(0, -2, 0),
                  3
                );
                arrow3.setColor("green");
                //把xyz轴箭头放在一起
                const group = new THREE.Group();
                group.add(arrow);
                group.add(arrow2);
                group.add(arrow3);
                console.log("group", group);
                // 将箭头添加到场景中
                // scene.add(arrow);
                // scene.add(arrow2)
                // scene.add(group) */
                /*  //给选中的模型添加坐标轴
                const axes = new THREE.AxesHelper(3);
                axes.position.set(
                  intersected.object.position.x,
                  intersected.object.position.y,
                  intersected.object.position.z
                );
                axes.rotation.x = -Math.PI / 2;
                scene.add(axes); */
                /* console.log(
                  "intersected.object",
                  intersected.object,
                  axes.position
                ); */
                // intersected.object.add(axes)
                intersected.object.material = material;

                // 设置模型的颜色
                intersected.object.isSelected = true;
                for (let obj of that.objects) {
                  //如果模型不是选中的模型，将模型状态变为非选中，同时去除外边框改变回原来的颜色
                  if (obj !== intersected.object) {
                    obj.isSelected = false;
                    obj.material.color.set("rgb(106, 106, 106)");
                    scene.remove(that.modelMap.get(obj));
                  }
                }
                that.$forceUpdate();
                intersected.object.material.color.set("rgb(0, 102, 203)");
                const lineSegments1 = that.modelMap.get(intersected.object);
                scene.add(lineSegments1);
              } else {
                that.cancelSelectModels();
              }
            } else {
              that.cancelSelectModels();
            }
          }
        },
        (xhr) => {
          this.percentage = Number(((xhr.loaded * 100) / xhr.total).toFixed(0));
          // console.log('xhr',xhr,this.percentage);
        }
      );
    },
    //包围盒各顶点设置
    box(width, height, depth) {
      (width = width * 0.5), (height = height * 0.5), (depth = depth * 0.5);
      const geometry = new THREE.BufferGeometry();
      const position = [];
      position.push(
        -width,
        -height,
        -depth, // -15,-25,-5
        -width,
        -height + height / 2,
        -depth,
        -width,
        height - height / 2,
        -depth,
        -width,
        height,
        -depth,
        -width,
        height,
        -depth,
        -width + 0.7 * width,
        height,
        -depth,
        width - 0.7 * width,
        height,
        -depth,
        width,
        height,
        -depth,
        width,
        height,
        -depth,
        width,
        height - height / 2,
        -depth,
        width,
        -height + height / 2,
        -depth,
        width,
        -height,
        -depth,

        width,
        -height,
        -depth,
        width - 0.7 * width,
        -height,
        -depth,
        -width + 0.7 * width,
        -height,
        -depth,
        -width,
        -height,
        -depth,

        -width,
        -height,
        depth,
        -width,
        -height + height / 2,
        depth,
        -width,
        height - height / 2,
        depth,
        -width,
        height,
        depth,

        -width,
        height,
        depth,
        -width + 0.7 * width,
        height,
        depth,
        width - 0.7 * width,
        height,
        depth,
        width,
        height,
        depth,

        width,
        height,
        depth,
        width,
        height - height / 2,
        depth,
        width,
        -height + height / 2,
        depth,
        width,
        -height,
        depth,

        width,
        -height,
        depth,
        width - 0.7 * width,
        -height,
        depth,
        -width + 0.7 * width,
        -height,
        depth,
        -width,
        -height,
        depth,
        -width,
        -height,
        depth,
        -width,
        -height,
        depth - 0.5 * depth,
        -width,
        -height,
        -depth + 0.5 * depth,
        -width,
        -height,
        -depth,

        -width,
        height,
        depth,
        -width,
        height,
        depth - 0.5 * depth,
        -width,
        height,
        -depth + 0.5 * depth,
        -width,
        height,
        -depth,

        width,
        height,
        depth,
        width,
        height,
        depth - 0.5 * depth,
        width,
        height,
        -depth + 0.5 * depth,
        width,
        height,
        -depth,

        width,
        -height,
        depth,
        width,
        -height,
        depth - 0.5 * depth,
        width,
        -height,
        -depth,
        width,
        -height,
        -depth + 0.5 * depth
      );
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(position, 3)
      );
      return geometry;
    },
  },
  computed: {
    isShow() {
      return this.percentage === 100;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hello {
  position: relative;
  .el-progress {
    margin: 0 auto;
    width: 126px;
  }
  .control {
    position: absolute;
    top: 100px;
    left: 100px;
    ul li {
      width: 500px;
    }
  }
  .model-list {
    z-index: 99;
    position: absolute;
    top: 400px;
    left: 100px;
  }
}
</style>
