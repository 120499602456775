export default {
  a: '1234',
  preview: {
    modelLoading: '模型加载中',
    setting: '设置',
    fold: '收起',
    color: '着色',
    displayStyle: '显示方式',
    default: '默认',
    transparent: '透明',
    dashed: '虚线',
    background: '背景',
    dark: '深色',
    light: '浅色',
    displayGrid: '显示网格',
    frontView: '主视图',
    backView: '后视图',
    leftSideView: '左视图',
    rightSideView: '右视图',
    topView: '俯视图',
    bottomView: '仰视图',
    close: '关闭',
    zoomIn: '放大',
    zoomOut: '缩小',
    reset: '重置',
    markSize: '标记尺寸',
    degreeeDisplay360: '环绕展示',
    fullscreen: '全屏',
    exitFullscreen: '退出全屏',
    slices: '切片',
    modelSize: '模型尺寸'
  },
  slicer: {
    selectSingleModel: '请先选中单个模型',
    devSel: '机型选择',
    resSel: '树脂选择',
    slicConfig: '切片配置',
    set: '设置',
    move: '移动',
    scale: '缩放',
    rotate: '旋转',
    autoLayout: '自动布局',
    copy: '复制',
    slicer: '切片',
    modelList: '模型列表',
    loadedModel: '已加载模型',
    addModel:'添加模型',
    modelSink: '模型下沉',
    center: '居中',
    reset: '重置',
    actual: '实际',
    percentage: '百分比',
    lockRatio: '锁定比例',
    scaleToFit: '缩放以适应',
    quantity: '数量',
    apply: '应用',
    modelLoading: '模型加载中',
    autoLayoutInPro: '自动布局中',
    autoLayoutComp: '自动布局完成'
  },
  printerSelect: {
    yes: '有',
    no: '没有',
    title: '3D打印机选择',
    choosePrinter: '选择你的3D打印机或新增',
    manageMachines: '管理机型',
    addDefineMachines: '添加自定机型',
    msg1: '自定义机型名称不能为空',
    msg2: 'X轴不能为空',
    msg3: 'Y轴不能为空',
    msg4: 'Z轴不能为空',
    msg5: '水平分辨率不能为空',
    msg6: '垂直分辨率不能为空',
    msg7: '请添加机型图片',
    picSizeLimit: '上传机型图片大小不能超过 2MB!',
    updateSuc: '更新成功',
    updateFail: '更新失败',
    truPicFormat: '请上传正确的图片格式',
    delSuc: '删除成功',
    delFail: '删除失败',
    addSuc: '添加成功',
    addFail: '添加失败',
    resinOptions: [
      '标准刚性树脂','类ABS树脂','水洗树脂','植物基树脂'
    ],
    confileDialog: {
      selectConfigFile: '选择配置文件',
      selected: '已选中',
      defaultConfig: '默认配置',
      defineConfig: '自定义配置',
      resin: '树脂',
      layThick: '层厚',
      allPars: '全部参数',
      machineType: '机型'
    },
    resinSlicePar: {
      label: '树脂-切片参数',
      value: '设置你的树脂以及打印配置',
      saveAs: '另存为',
    },
    printSet: {
      title: '打印设置',
      resinType: {
        label: '树脂类型',
        value: '打印所用的树脂材料类型。'
      }, 
      layThick: {
        label: '层厚',
        value: '层厚范围在0.1～0.025mm。'
      },
      singLayExpTime: '单层曝光时间',
      bottomLayExpTime: '底层曝光时间',
      expDelay: '曝光延迟',
      bottomLayExpLay: '底层曝光层数',
      bottomLayExpTranLay: '底层曝光过渡层数',
      liftHeight1: '抬升高度1',
      lifgSpeed1: '抬升速度1',
      liftHeight2: '抬升高度2',
      lifgSpeed2: '抬升速度2',
      lowerSpeed: '下降速度',
      zAxLiftAfterPrint: '打印完Z轴抬升'
    },
    highSet: {
      fine: '细',
      medium: '中等',
      coarse: '粗',
      modelLiftHeight: '模型抬升高度',
      supportType: '支撑类型',
      title: '高级设置',
      innerDiaComp: '内径补偿',
      outDiaComp: '外径补偿',
      xAxisScaleComp: 'X轴缩放补偿',
      yAxisScaleComp: 'Y轴缩放补偿',
      zAxisScaleComp: 'Z轴缩放补偿',
      antial: {
        label: '抗锯齿',
        value: '等级为“1”时不开启抗锯齿。'
      },
      autoSup: '自动支撑',
      antDisAtLevel1: '等级为“1”时不开启抗锯齿',
      grayLevel: '灰度级别',
      imgBlurPx: '图像模糊像素',
      supDensity: '支撑密度',
      supDensityTip: '数值越大添加的支撑越密集',
      addBase: '添加底座',
      open: '开启',
      close: '关闭'
    },
    confirm: '确定',
    cancel: '取消',
    save: {
      title: '参数配置命名',
      placeholder: '请输入30个字以内的配置名称         '
    },
    newAddMachine: '新增自定义机型',
    machineName: '机型名称',
    machineNamePlaceholder: '请输入30个字以内的机型名称',
    addMachinePic: '添加机型图片',
    newAddMachineSave: '保存',
    platSize: '平台尺寸',
    resolution: '分辨率',
    illustration: '图示',
    itHaveMonitoring: '是否有监控功能',
    newAddDefineMachine: {
      title: '新增自定义机型',
      addMachinePic: '添加机型图片'
    },
    define: '自定义',
    delete: '删除',
    updatePic: '更换图片',
    config: {
      label: '选择配置文件',
      value: '已选中',
      resin: '树脂',
      layThick: '层厚',
      defaultConfig: '默认配置',
      defineConfig: '自定义配置',
      allPar: '全部参数',
      machineType: '机型',
      cancel: '取消'
    },
    delDialog : {
      tip: '提示',
      txt: '是否删除该配置参数？删除将不可恢复',
      confirm: '确定',
      cancel: '取消'
    },
    outOfBoundDialog : {
      tip: '提示',
      txt: '模型已超出打印机边界,请调整模型的位置或尺寸。(模型添加支撑将有可能超出打印边界)',
      know: '已知道'
    },
    adaptModelDialog : {
      tip: '提示',
      txt: '存在着模型过大或过小,是否调整到合适大小?',
      confirm: '确定',
      cancel: '取消'
    },
    addModelDialog : {
       modelList: {
        label: '模型列表',
        value: '拖动模型进入模型列表即可添加模型'
       },
       modelBase: '模型库',
       searchModel: '搜索模型',
       noResults: '暂无搜索结果'
    },
    excludeModelDialog: {
      tip: '提示',
      txt: '部分模型超出打印机边界，本次切片是否排除这些模型',
      confirm: '是，去切片',
      cancel: '取消'
    },
    delFinalSingleModelDialog: {
      tip: '提示',
      txt: '删除将退出切片预览，是否继续',
      yes: '是',
      no: '否'
    },
    modelHoverDialog : {
      tip: '提示',
      txt: '模型悬空，会导致模型打印错误 是否继续？',
      yes: '是',
      no: '否'
    }
  }
}
