var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview"},[_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingCircle),expression:"loadingCircle"}],attrs:{"close-on-click-modal":false,"visible":_vm.dialogVisible,"close-on-press-escape":false,"show-close":false,"element-loading-text":_vm.$t('preview.modelLoading'),"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.6)"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{ref:"viewContainer",staticClass:"view-container"},[(!_vm.isSetUnfold && !_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"set",on:{"click":function($event){_vm.isSetUnfold = true}}},[_c('i',{staticClass:"icon-a-shezhi2 iconfont"}),_c('span',[_vm._v(_vm._s(_vm.transformText("setting")))])]):_vm._e(),(_vm.isSetUnfold && !_vm.defaultConfig.isFullScreen)?_c('div',{ref:"setUnfold",staticClass:"set-unfold"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('i',{staticClass:"icon-a-shezhi2 iconfont"}),_c('span',[_vm._v(_vm._s(_vm.transformText("setting")))])]),_c('div',{staticClass:"right",on:{"click":function($event){_vm.isSetUnfold = false}}},[_c('i',{staticClass:"icon-fanhui iconfont"}),_c('span',[_vm._v(_vm._s(_vm.transformText("fold")))])])]),_c('div',{staticClass:"colour"},[_c('div',{staticClass:"label"},[_c('img',{attrs:{"src":require("../assets/colored.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.transformText("color")))])]),_c('div',{staticClass:"value"},[_c('ul',_vm._l((_vm.set.colour),function(color,index){return _c('li',{key:index,style:({
                  backgroundColor: color,
                  borderColor:
                    _vm.defaultConfig.colorIndex === index ? '#fff' : color,
                }),on:{"click":function($event){return _vm.setModelColor(index)}}})}),0)])]),_c('div',{staticClass:"displayMode"},[_c('div',{staticClass:"label"},[_c('i',{staticClass:"icon-xianshifangshi iconfont"}),_c('span',[_vm._v(_vm._s(_vm.transformText("displayStyle")))])]),_c('div',{staticClass:"value"},[_c('ul',_vm._l((_vm.set.displayMode),function(mode,index){return _c('li',{key:index,style:({
                  backgroundColor:
                    _vm.defaultConfig.displayModeIndex === index ? '#D71518' : '',
                  border:
                    _vm.defaultConfig.displayModeIndex === index
                      ? 'none'
                      : '1px solid #999999',
                }),on:{"click":function($event){return _vm.setModeDisplay(index)}}},[_vm._v(" "+_vm._s(mode)+" ")])}),0)])]),_c('div',{staticClass:"background"},[_c('div',{staticClass:"label"},[_c('i',{staticClass:"icon-a-beijing2 iconfont"}),_c('span',[_vm._v(_vm._s(_vm.transformText("background")))])]),_c('div',{staticClass:"value"},[_c('ul',_vm._l((_vm.set.background),function(mode,index){return _c('li',{key:index,style:({
                  backgroundColor:
                    _vm.defaultConfig.backgroundIndex === index ? '#D71518' : '',
                  border:
                    _vm.defaultConfig.backgroundIndex === index
                      ? 'none'
                      : '1px solid #999999',
                }),on:{"click":function($event){return _vm.setBackground(index)}}},[_vm._v(" "+_vm._s(mode)+" ")])}),0)])]),_c('div',{staticClass:"displayGrid"},[_c('div',{staticClass:"label"},[_c('img',{attrs:{"src":require("../assets/grid.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.transformText("displayGrid")))])]),_c('el-switch',{attrs:{"active-color":"#626267","inactive-color":"#fff"},on:{"change":_vm.ShowGrid},model:{value:(_vm.defaultConfig.isShowGrid),callback:function ($$v) {_vm.$set(_vm.defaultConfig, "isShowGrid", $$v)},expression:"defaultConfig.isShowGrid"}})],1)]):_vm._e(),(!_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"view"},[_c('ul',_vm._l((_vm.view),function(item,index){return _c('li',{key:index},[_c('div',{ref:"viewLi",refInFor:true,style:({
                background:
                  _vm.viewIndex === index ? '#D71518' : 'rgb(0, 0, 0,0.1)',
                color: _vm.viewIndex === index ? '#fff' : '#aaa',
              }),on:{"click":function($event){return _vm.toggleView(index)}}},[_c('i',{staticClass:"iconfont",class:index === _vm.viewIndex ? item.activeClass : item.class}),_c('span',[_vm._v(_vm._s(item.name))])])])}),0)]):_vm._e(),(!_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"close",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"iconfont icon-a-guanbi4"}),_c('span',[_vm._v(_vm._s(this.transformText("close")))])]):_vm._e(),(!_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"scale-reset"},[_c('div',{staticClass:"magnify common",on:{"click":function($event){return _vm.zoomModel(-0.1)}}},[_c('i',{staticClass:"icon-a-fangda2 iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("zoomIn")))])]),_c('div',{staticClass:"reduce common",on:{"click":function($event){return _vm.zoomModel(0.1)}}},[_c('i',{staticClass:"icon-fangda iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("zoomOut")))])]),_c('div',{staticClass:"reset common",on:{"click":_vm.resetModel}},[_c('i',{staticClass:"icon-zhongzhi iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("reset")))])])]):_vm._e(),_c('div',{staticClass:"bottom-btns"},[(!_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"markSize common",style:({
            background: _vm.defaultConfig.isShowSize
              ? '#D71518'
              : 'rgba(0,0,0,0.4)',
          }),on:{"click":_vm.markSize}},[_c('i',{staticClass:"icon-celiang iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("markSize")))])]):_vm._e(),(!_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"rotate common",style:({
            background: _vm.defaultConfig.isAutoRotate
              ? '#D71518'
              : 'rgba(0,0,0,0.4)',
          }),on:{"click":_vm.autoRotate}},[_c('i',{staticClass:"icon-huanraozhanshi iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("degreeeDisplay360")))])]):_vm._e(),_c('div',{ref:"fullScreen",staticClass:"markSize common",on:{"click":_vm.fullScreen}},[(!_vm.defaultConfig.isFullScreen)?[_c('i',{staticClass:"icon-quanping iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("fullscreen")))])]:[_c('i',{staticClass:"icon-tuichuquanping iconfont"}),_c('span',[_vm._v(_vm._s(this.transformText("exitFullscreen")))])]],2)]),(!_vm.defaultConfig.isFullScreen)?_c('div',{staticClass:"slicer",on:{"click":_vm.slicer}},[_c('i',{staticClass:"iconfont icon-qiepianpeizhi"}),_c('span',[_vm._v(_vm._s(this.transformText("slices")))])]):_vm._e(),(_vm.defaultConfig.isShowSize)?_c('div',{staticClass:"size"},[_vm._v(" "+_vm._s(_vm.transformText("modelSize"))+"："+_vm._s(_vm.model.size.x.toFixed(2))+" * "+_vm._s(_vm.model.size.y.toFixed(2))+" * "+_vm._s(_vm.model.size.z.toFixed(2))+" mm ")]):_vm._e(),_c('div',{ref:"canvasDom",attrs:{"id":"model-preview"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }