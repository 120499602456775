<template>
  <div class="preview">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :show-close="false"
      v-loading="loadingCircle"
      :element-loading-text="$t('preview.modelLoading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    >
      <!-- <div class="mask-container" v-if="loading">
        <el-progress type="circle" :percentage="percentage"></el-progress>
      </div> -->
      <div class="view-container" ref="viewContainer">
        <div
          class="set"
          v-if="!isSetUnfold && !defaultConfig.isFullScreen"
          @click="isSetUnfold = true"
        >
          <i class="icon-a-shezhi2 iconfont"></i>
          <span>{{ transformText("setting") }}</span>
        </div>
        <div
          class="set-unfold"
          ref="setUnfold"
          v-if="isSetUnfold && !defaultConfig.isFullScreen"
        >
          <div class="top">
            <div class="left">
              <i class="icon-a-shezhi2 iconfont"></i>
              <span>{{ transformText("setting") }}</span>
            </div>
            <div class="right" @click="isSetUnfold = false">
              <i class="icon-fanhui iconfont"></i>
              <span>{{ transformText("fold") }}</span>
            </div>
          </div>
          <div class="colour">
            <div class="label">
              <!-- <i class="icon-a-zhuose1 iconfont"></i> -->
              <img src="../assets/colored.svg" alt="" />
              <span>{{ transformText("color") }}</span>
            </div>
            <div class="value">
              <ul>
                <li
                  v-for="(color, index) in set.colour"
                  :key="index"
                  @click="setModelColor(index)"
                  :style="{
                    backgroundColor: color,
                    borderColor:
                      defaultConfig.colorIndex === index ? '#fff' : color,
                  }"
                ></li>
              </ul>
            </div>
          </div>
          <div class="displayMode">
            <div class="label">
              <i class="icon-xianshifangshi iconfont"></i>
              <span>{{ transformText("displayStyle") }}</span>
            </div>
            <div class="value">
              <ul>
                <li
                  v-for="(mode, index) in set.displayMode"
                  :key="index"
                  @click="setModeDisplay(index)"
                  :style="{
                    backgroundColor:
                      defaultConfig.displayModeIndex === index ? '#D71518' : '',
                    border:
                      defaultConfig.displayModeIndex === index
                        ? 'none'
                        : '1px solid #999999',
                  }"
                >
                  {{ mode }}
                </li>
              </ul>
            </div>
          </div>
          <div class="background">
            <div class="label">
              <i class="icon-a-beijing2 iconfont"></i>
              <span>{{ transformText("background") }}</span>
            </div>
            <div class="value">
              <ul>
                <li
                  v-for="(mode, index) in set.background"
                  :key="index"
                  @click="setBackground(index)"
                  :style="{
                    backgroundColor:
                      defaultConfig.backgroundIndex === index ? '#D71518' : '',
                    border:
                      defaultConfig.backgroundIndex === index
                        ? 'none'
                        : '1px solid #999999',
                  }"
                >
                  {{ mode }}
                </li>
              </ul>
            </div>
          </div>
          <div class="displayGrid">
            <div class="label">
              <!-- <i class="icon-a-wangge1 iconfont"></i> -->
              <img src="../assets/grid.svg" alt="" />
              <span>{{ transformText("displayGrid") }}</span>
            </div>
            <el-switch
              v-model="defaultConfig.isShowGrid"
              active-color="#626267"
              inactive-color="#fff"
              @change="ShowGrid"
            >
            </el-switch>
          </div>
        </div>
        <div class="view" v-if="!defaultConfig.isFullScreen">
          <ul>
            <li v-for="(item, index) in view" :key="index">
              <div
                :style="{
                  background:
                    viewIndex === index ? '#D71518' : 'rgb(0, 0, 0,0.1)',
                  color: viewIndex === index ? '#fff' : '#aaa',
                }"
                ref="viewLi"
                @click="toggleView(index)"
              >
                <i
                  class="iconfont"
                  :class="index === viewIndex ? item.activeClass : item.class"
                />
                <span>{{ item.name }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="close" v-if="!defaultConfig.isFullScreen" @click="back()">
          <i class="iconfont icon-a-guanbi4"></i>
          <span>{{ this.transformText("close") }}</span>
        </div>
        <div class="scale-reset" v-if="!defaultConfig.isFullScreen">
          <div class="magnify common" @click="zoomModel(-0.1)">
            <i class="icon-a-fangda2 iconfont"></i>
            <span>{{ this.transformText("zoomIn") }}</span>
          </div>
          <div class="reduce common" @click="zoomModel(0.1)">
            <i class="icon-fangda iconfont"></i>
            <span>{{ this.transformText("zoomOut") }}</span>
          </div>
          <div class="reset common" @click="resetModel">
            <i class="icon-zhongzhi iconfont"></i>
            <span>{{ this.transformText("reset") }}</span>
          </div>
        </div>
        <div class="bottom-btns">
          <div
            v-if="!defaultConfig.isFullScreen"
            class="markSize common"
            @click="markSize"
            :style="{
              background: defaultConfig.isShowSize
                ? '#D71518'
                : 'rgba(0,0,0,0.4)',
            }"
          >
            <i class="icon-celiang iconfont"></i>
            <span>{{ this.transformText("markSize") }}</span>
          </div>
          <div
            v-if="!defaultConfig.isFullScreen"
            class="rotate common"
            @click="autoRotate"
            :style="{
              background: defaultConfig.isAutoRotate
                ? '#D71518'
                : 'rgba(0,0,0,0.4)',
            }"
          >
            <i class="icon-huanraozhanshi iconfont"></i>
            <span>{{ this.transformText("degreeeDisplay360") }}</span>
          </div>
          <div class="markSize common" @click="fullScreen" ref="fullScreen">
            <template v-if="!defaultConfig.isFullScreen">
              <i class="icon-quanping iconfont"></i>
              <span>{{ this.transformText("fullscreen") }}</span>
            </template>
            <template v-else>
              <i class="icon-tuichuquanping iconfont"></i>
              <span>{{ this.transformText("exitFullscreen") }}</span>
            </template>
          </div>
        </div>
        <!-- { name: 'slicer', params: { modelUrl: [modelUrl],modelFile: [modelFile],modelId } } -->
        <div class="slicer" @click="slicer" v-if="!defaultConfig.isFullScreen">
          <i class="iconfont icon-qiepianpeizhi"></i>
          <span>{{ this.transformText("slices") }}</span>
        </div>
        <div class="size" v-if="defaultConfig.isShowSize">
          {{ transformText("modelSize") }}：{{ model.size.x.toFixed(2) }} *
          {{ model.size.y.toFixed(2) }} * {{ model.size.z.toFixed(2) }} mm
        </div>
        <div id="model-preview" ref="canvasDom"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TrackballControls } from "three/examples/jsm/controls//TrackballControls.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { getJumpModelFileInfo, uploadModelFromOss } from "../api/getInfo";
import axios from "../api/index";
import { STLLoader1 } from "../utils/loader/PLALoader";
export default {
  name: "PreviewModel",
  data() {
    return {
      loadingCircle: true,
      radius: null,
      loadTime: null,
      percentage: 0,
      //模型id
      modelId: "",
      loading: true,
      //用户跳转过来的模型文件信息
      modelFile: "",
      model: "",
      modelUrl: "",
      publicPath: process.env.BASE_URL,
      dialogVisible: true,
      scene: null,
      camera: null,
      renderer: null,
      orbitControls: null,
      trackballControls: null,
      loader: null,
      light: null,
      gridHelper: null,
      view: [
        {
          class: "icon-a-zhushitu1",
          activeClass: "icon-a-zhushitu2",
          name: this.transformText("frontView"),
        },
        {
          class: "icon-a-houshitu1",
          activeClass: "icon-a-houshitu2",
          name: this.transformText("backView"),
        },
        {
          class: "icon-a-zuoshitu1",
          activeClass: "icon-a-zuoshitu2",
          name: this.transformText("leftSideView"),
        },
        {
          class: "icon-a-youshitu1",
          activeClass: "icon-a-youshitu2",
          name: this.transformText("rightSideView"),
        },
        {
          class: "icon-a-fushitu1",
          activeClass: "icon-a-fushitu2",
          name: this.transformText("topView"),
        },
        {
          class: "icon-a-yangshitu1",
          activeClass: "icon-a-yangshitu2",
          name: this.transformText("bottomView"),
        },
      ],
      viewIndex: 0,
      //展开设置
      isSetUnfold: true,
      //设置
      set: {
        colour: ["#006CFF", "#00D637", "#FF2222", "#FFB400"],
        displayMode: [
          this.transformText("default"),
          this.transformText("transparent"),
          this.transformText("dashed"),
        ],
        background: [this.transformText("dark"), this.transformText("light")],
      },
      defaultConfig: {
        colorIndex: 0,
        displayModeIndex: 0,
        backgroundIndex: 1,
        isShowGrid: false,
        isAutoRotate: false,
        isFullScreen: false,
        isShowSize: false,
      },
    };
  },
  methods: {
    transformText(text) {
      return this.$t(`preview.${text}`);
    },
    //返回到原来跳过来的页面
    back() {
      window.parent.postMessage("close", "*");
    },
    //标记尺寸
    markSize() {
      this.defaultConfig.isShowSize = !this.defaultConfig.isShowSize;
    },
    //全屏
    fullScreen() {
      this.defaultConfig.isFullScreen = !this.defaultConfig.isFullScreen;
      console.log('document.documentElement.clientHeight',window.screen.height);
      console.log(
        "this.defaultConfig.isFullScreen",
        this.defaultConfig.isFullScreen
      );
      if (this.defaultConfig.isFullScreen) {
        console.log("111", this.$refs.fullScreen.style);
        this.$refs.fullScreen.style = "width: 100px;height: 100px";
      }
      const camera = this.camera;
      const renderer = this.renderer;
      // 创建全屏按钮并添加点击事件处理函数
      if (document.fullscreenElement === null) {
        if (this.$refs.viewContainer.requestFullscreen) {
          this.$refs.viewContainer.requestFullscreen();
        }
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      // 监听进入/退出全屏模式事件，并更新渲染器(renderer)和相机(camera)的大小等属性
      document.addEventListener("fullscreenchange", (e) => {
        console.log("e", e.target);
        if (document.fullscreenElement === null) {
          // console.log('full',e.key);
          this.defaultConfig.isFullScreen = false;
          // 如果退出了全屏模式
          this.setRendererSize(1200,800)
          // renderer.domElement.requestFullscreen();
        } else {
          // 如果进入了全屏模式

          if (e.target) {
            setTimeout(() => {
              this.setRendererSize(e.target.offsetWidth,window.screen.height)
            },0)
            
          }
          // },0)
          // renderer.domElement.requestFullscreen();
        }
      });
    },
    setRendererSize(width, height) {
      const camera = this.camera;
      const renderer = this.renderer;

      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setPixelRatio(window.devicePixelRatio);
       /*  renderer.setSize(1200, 800); // 重新设置渲染器的大小
          camera.aspect = 1200 / 800; // 更新相机的宽高比
          camera.updateProjectionMatrix();
          renderer.setPixelRatio(window.devicePixelRatio); // 更新渲染器的像素比例 */
    },
    //环绕展示
    autoRotate() {
      this.defaultConfig.isAutoRotate = !this.defaultConfig.isAutoRotate;
    },
    //重置模型
    resetModel() {
      this.orbitControls.reset();
      this.defaultConfig.isAutoRotate = false;
      this.model.rotation.set(0, 0, 0);
      this.viewIndex = 0;
      this.camera.position.set(0, -this.radius * 3, 0);
    },
    //缩放模型
    zoomModel(value) {
      let x = this.camera.position.x - this.orbitControls.target.x;
      let y = this.camera.position.y - this.orbitControls.target.y;
      let z = this.camera.position.z - this.orbitControls.target.z;
      x *= value;
      y *= value;
      z *= value;
      x += this.camera.position.x;
      y += this.camera.position.y;
      z += this.camera.position.z;
      this.camera.position.set(x, y, z);
    },
    //是否显示网格
    ShowGrid(val) {
      if (val) {
        this.scene.add(this.gridHelper);
      } else {
        this.scene.remove(this.gridHelper);
      }
    },
    //设置背景
    setBackground(index) {
      this.defaultConfig.backgroundIndex = index;
      let blackBg = "rgba(255,255,255,0.2)";
      switch (index) {
        case 0:
          this.scene.background = new THREE.Color("#303133");
          break;
        case 1:
          this.scene.background = new THREE.Color("#BEBEBE");
          break;
        case 2:
          this.scene.background = new THREE.Color("#ADAFB6");
          break;
      }
    },
    //设置模型显示方式
    setModeDisplay(index) {
      this.defaultConfig.displayModeIndex = index;
      switch (index) {
        case 0:
          this.model.material = new THREE.MeshLambertMaterial({
            color: this.set.colour[this.defaultConfig.colorIndex],
            side: THREE.DoubleSide,
            wireframe: false,
          });
          break;
        case 1:
          this.model.material = new THREE.MeshLambertMaterial({
            color: this.set.colour[this.defaultConfig.colorIndex],
            wireframe: false,
            side: THREE.DoubleSide,
            depthWrite: false,
          });
          this.model.material.transparent = true;
          this.model.material.opacity = 0.2;
          this.model.material.needsUpdate = true;
          break;
        case 2:
          this.model.material = new THREE.MeshToonMaterial({
            color: this.set.colour[this.defaultConfig.colorIndex],
            wireframe: true,
          });
          this.scene.add(this.model);
          break;
      }
      console.log(this.model);
    },
    //设置模型颜色
    setModelColor(index) {
      this.defaultConfig.colorIndex = index;
      console.log("mode", this.model);
      this.model.material.color.set(this.set.colour[index]);
    },
    //切换视图
    toggleView(index) {
      this.orbitControls.target = new THREE.Vector3(0, 0, 0);
      this.model.rotation.set(0, 0, 0);
      this.defaultConfig.isAutoRotate = false;
      this.viewIndex = index;
      this.$nextTick(() => {
        const camera = this.camera;
        switch (index) {
          case 0:
            camera.position.set(0, -this.radius * 3, 0);
            break;
          case 1:
            camera.position.set(0, this.radius * 3, 0);
            break;
          case 2:
            camera.position.set(-this.radius * 3, 0, 0);
            break;
          case 3:
            camera.position.set(this.radius * 3, 0, 0);
            break;
          case 4:
            camera.position.set(0, 0, this.radius * 3);
            break;
          case 5:
            camera.position.set(0, 0, -this.radius * 3);
            break;
        }
      });
    },
    init() {
      this.initScene();
      this.initCamera();
      this.initRenderer();
      this.initOrbitControls();
      // this.initTrackballControls();
      this.initLight();
      // this.initAxes();
      this.animate();
      // 监听浏览器窗口大小变化并更新renderer和camera等属性
      window.addEventListener("resize", (e) => {
        console.log("resize", e);
        /*  if(!this.defaultConfig.isFullScreen) {
          this.renderer.setSize(1200, 800);
        this.camera.aspect = 1200 / 800;
        this.camera.updateProjectionMatrix();
        this.renderer.setPixelRatio(window.devicePixelRatio);
        }   */
      });
      document.addEventListener(
        "keydown",
        (e) => {
          if (e.key === "Escape") {
            console.log(
              "this.defaultConfig.isFullScreen",
              this.defaultConfig.isFullScreen
            );
            if (this.defaultConfig.isFullScreen) {
              // this.defaultConfig.isFullScreen = false
              this.fullScreen();
            }
            e.stopPropagation();
            e.preventDefault();
            console.log("esc");
          }
        },
        true
      );
    },
    //初始化辅助坐标轴
    initAxes() {
      const axes = new THREE.AxesHelper(50);
      this.axes = axes;
      this.scene.add(axes);
    },
    //初始化场景
    initScene() {
      const scene = new THREE.Scene();
      this.scene = scene;
      scene.background = new THREE.Color("#bebebe");
    },
    //初始化相机
    initCamera() {
      const camera = new THREE.PerspectiveCamera(75, 1200 / 800, 20, 3000000);
      this.camera = camera;
      camera.position.y = -300;
      // camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
      const target = new THREE.Vector3(0, 0, camera.position.z);
      camera.up = new THREE.Vector3(0, 0, 1);
      camera.lookAt(target);
    },
    //初始化渲染器
    initRenderer() {
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer = renderer;
      renderer.setSize(1200, 800);
      this.$nextTick(() => {
        this.$refs.canvasDom.appendChild(renderer.domElement);
      });
    },
    //初始化轨道控制器
    initOrbitControls() {
      const orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      /* orbitControls.addEventListener("start", (e) => {
        console.log("e", e);
        // this.orbitControls.maxDistance -= 0.1;
      }); */

      this.orbitControls = orbitControls;
      //启用阻尼
      orbitControls.enableDamping = true;
      orbitControls.dampingFactor = 0.1;
      // orbitControls.maxDistance = 100;
      //是否开启右键拖拽
      orbitControls.enablePan = true;
      orbitControls.update();
    },
    //初始化轨道控制器
    initTrackballControls() {
      const trackballControls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      trackballControls.enabled = true;
      this.trackballControls = trackballControls;
      console.log("trackballControls", trackballControls);

      /*  trackballControls.addEventListener("start", () => {
        trackballControls.enabled = true;
      }); */
    },
    // 添加网格地面
    initGridHelper() {
      // #C3C4C9
      console.log("this.radius", this.radius);
      const gridHelper = new THREE.GridHelper(
        this.radius * 10,
        50,
        "#C3C4C9",
        "#C3C4C9"
      );
      this.gridHelper = gridHelper;
      gridHelper.rotation.x = -Math.PI / 2;
    },
    //初始化光照
    initLight() {
      const ambienLight = new THREE.AmbientLight(0xfff, 0.1);
      this.scene.add(ambienLight);
      const light = new THREE.DirectionalLight(0xffffff, 1);
      this.light = light;
      light.position.set(0, 0, 0);
      this.scene.add(light);
    },
    animate() {
      this.orbitControls.update();
      if (this.defaultConfig.isAutoRotate) {
        this.model.rotation.z += 0.01;
      }
      this.light.position.set(
        this.camera.position.x,
        this.camera.position.y,
        this.camera.position.z
      );
      this.renderer.render(this.scene, this.camera);

      requestAnimationFrame(this.animate);
    },
    //获得模型尺寸
    getModelSize(mesh) {
      // 获取模型的最大最小坐标
      const box = new THREE.Box3().setFromObject(mesh);
      const min = box.min;
      const max = box.max;
      // 计算模型的缩放比例
      const scale = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);
      const size = new THREE.Vector3();
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      return size;
    },
    loadStlModel(path) {
      if (!path) {
        path = this.modelUrl;
        console.log("path", path);
      }
      const loader = new STLLoader1();
      this.loader = loader;
      // #006CFF
      loader
        .loadAsync(path, (xhr) => {
          let percentage = Number(((xhr.loaded * 100) / xhr.total).toFixed(0));
          // console.log("percentage", percentage);
          let curTime = Date.now();
          if (curTime - this.loadTime >= 1000) {
            this.$nextTick(() => {
              this.percentage = percentage;
              this.loadTime = curTime;
            });
          }
        })
        .then((geometry) => {
          this.loading = false;
          geometry.computeVertexNormals();
          geometry.center();
          console.log(geometry);
          const material = new THREE.MeshLambertMaterial({
            color: "#006CFF",
            side: THREE.DoubleSide,
          });
          const mesh = new THREE.Mesh(geometry, material);
          this.scene.add(mesh);
          this.model = mesh;
          const size = this.getModelSize(mesh);
          this.model.size = size;
          console.log("size", size);
          const radius = Math.sqrt(
            (size.x / 2) * (size.x / 2) +
              (size.y / 2) * (size.y / 2) +
              (size.z / 2) * (size.z / 2)
          );
          this.radius = radius;
          if (radius < size.y / 2) {
            radius = size.y / 2;
          }
          this.camera.position.y = -radius * 3;
          this.initGridHelper();
          this.gridHelper.position.z = -this.model.size.z / 2;
        })
        .then(() => {
          this.loadingCircle = false;
          this.loading = false;
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: "模型加载失败",
            type: "error",
          });
          window.parent.postMessage("close", "*");
        });
    },
    async getJumpModelFileInfo() {
      const res = await getJumpModelFileInfo(this.params.modelFileIds);
      console.log("res", res);
      this.modelFile = res.rows[0];
      try {
        this.loadStlModel(this.modelFile.url);
      } catch (error) {
        this.$message.error("模型加载失败");
        window.parent.postMessage("close", "*");
      }
    },
    slicer() {
      // this.$router.push(`/slicer?params=${params}`)
      this.$router.push({ name: "slicer", query: { params: this.params } });
    },
  },
  mounted() {
    //在跳转到该页面时拿去模型id和模型文件id数组和语言
    // 51692933210112 25MB 51692933210112,36574908133376\
    // 47260308856832,79041693851648 超出打印机模型
    console.log("i18n", this.$t("preview.setting"));
    /* this.params = {
      modelId: 66819909279744,
      // , 66203750809608  ,24790496337920  24940514009088 30987046895616 43956210221056 36574908133376 54879458873344
      modelFileIds: [46462647115776],
      lang: "", 
    };  */
    this.params = JSON.parse(decodeURIComponent(this.$route.query.params));
    /* let lang = this.params.lang;
    console.log("lang", lang);
    //传过来的参数lang
    if (lang === "en_US") {
      lang = "en";
    } else if(lang === "ja_JP"){
      console.log('ja');
      lang = "ja";
    } else {
      console.log('zh');
      lang = 'zh'
    }
    let localLang = localStorage.getItem("lang") || "zh";
    if (lang !== localLang) {
      localStorage.setItem("lang", lang);
      location.reload();
    } */

    localStorage.setItem("token", this.params.token);

    this.getJumpModelFileInfo();
    //进行编码操作
    this.params = encodeURIComponent(JSON.stringify(this.params));
    ////用户跳转过来获取指定模型文件信息
    this.init();
    window.addEventListener("keydown", (e) => {
      console.log("e", e);
    });
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/deep/ .el-dialog__wrapper {
  .el-loading-spinner {
    .el-icon-loading {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
}
.iconfont {
  font-size: 20px;
}
.icon-a-shezhi2 {
  font-size: 25px;
}

.common {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  span {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
  }
}
li {
  list-style: none;
}
.preview {
  .mask-container {
    position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.6);
    .el-progress {
      position: absolute;
      z-index: 9999;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      /deep/ .el-progress__text {
        color: #fff !important;
      }
    }
  }
  /deep/ .el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/ .el-dialog {
    width: 1200px;
    height: 800px;
    border-radius: 8px;
    margin-top: 0 !important;
    margin-bottom: 0;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      border-radius: 8px;
      padding: 0;
      .view-container {
        border-radius: 8px;
        position: relative;
        // background-color: #bebebe;
        #model-preview {
          width: 1200px;
          height: 800px;
          border-radius: 8px;
        }
        //size
        .size {
          position: absolute;
          right: 30px;
          bottom: 150px;
          width: 300px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          border-radius: 5px;
          background: rgba(204, 204, 204, 0.2);
          color: #fff;
        }
        //设置
        .set {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 20px;
          top: 20px;
          width: 60px;
          height: 60px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 8px;
          color: #fff;
          cursor: pointer;
          i.icon-shezhi1 {
            margin-bottom: 5px;
          }
        }

        .set-unfold {
          position: absolute;
          left: 20px;
          top: 20px;
          width: 278px;
          height: 760px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 8px;
          color: #fffefe;
          .label {
            display: flex;
            align-items: center;
            span {
              margin-left: 10px;
            }
          }
          .top {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 55px;
            margin: 0 20px;
            border-bottom: 1px solid #ccc;
            .left {
              display: flex;
              align-items: center;
              .icon-a-shezhi2 {
                font-size: 25px;
              }
              span {
                margin-left: 10px;
                font-size: 14px;
              }
            }
            .right {
              display: flex;
              align-items: center;
              .iconfont {
                font-size: 10px;
              }
              span {
                margin-left: 5px;
                font-weight: 500;
                color: #cccccc;
                font-size: 12px;
              }
            }
          }
          .colour {
            padding-left: 20px;
            .label {
              height: 53px;
            }
            .value {
              ul {
                display: flex;
                padding-left: 24px;
                li {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                  background: #006cff;
                  border: 2px solid #ffffff;
                  border-radius: 50%;
                  cursor: pointer;
                }
              }
            }
          }
          .displayMode,
          .background {
            padding-left: 20px;
            margin-top: 31px;
            .value {
              margin: 20px 0 30px;
              ul {
                padding-left: 24px;
                display: flex;
                li {
                  width: 40px;
                  height: 24px;
                  margin-right: 10px;
                  line-height: 24px;
                  text-align: center;
                  // background: #d71518;
                  border-radius: 4px;
                  border: 1px solid #999999;
                  font-size: 12px;
                  cursor: pointer;
                }
              }
            }
          }
          .displayMode {
            li {
              width: auto !important;
              padding: 0 5px;
            }
          }
          .displayGrid {
            width: 100%;
            padding-left: 20px;
            padding-right: 36px;
            display: flex;
            justify-content: space-between;
            .el-switch {
              .el-switch__core {
                border-color: #fff !important;
              }
              .el-switch__core::after {
                background-color: #626267;
              }
            }
            .el-switch.is-checked .el-switch__core::after {
              background-color: #fff;
            }
          }
        }

        //视图
        .view {
          z-index: 999;
          position: absolute;
          left: 350px;
          top: 20px;
          border-radius: 5px;
          ul {
            display: flex;
            width: auto;
            height: 60px;
            // border-radius: 5px;
            li {
              background: rgba(0, 0, 0, 0.4) !important;
              width: 90px;
              height: 100%;
              div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #aaa;
                width: auto;
                height: 100%;
                font-size: 12px;
                font-weight: 500;
                border-radius: 5px !important;
                background-color: pink;
                cursor: pointer;
                span {
                  margin-top: 9px;
                }
              }
            }
            li:first-child {
              border-start-start-radius: 8px;
              border-end-start-radius: 8px;
            }
            li:last-child {
              border-start-end-radius: 8px;
              border-end-end-radius: 8px;
            }
          }
        }

        //关闭
        .close {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 20px;
          top: 20px;
          width: 60px;
          height: 60px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 8px;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
          i.icon-a-xingzhuang1 {
            font-size: 18px;
          }
          span {
            margin-top: 7px;
          }
        }

        //放大、缩小、重置
        .scale-reset {
          position: absolute;
          top: 305px;
          right: 20px;
          .reduce {
            margin: 5px 0;
          }
        }

        //标记尺寸、环绕、全屏
        .bottom-btns {
          display: flex;
          position: absolute;
          right: 20px;
          bottom: 20px;
          .markSize {
            margin: 0 10px;
          }
          // .fullscreen {
          //   background-color: pink;
          // }
        }

        //切片
        .slicer {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 72px;
          width: 160px;
          height: 42px;
          text-align: center;
          color: #fff;
          background: #d71518;
          border-radius: 8px;
          cursor: pointer;
          span {
            margin-left: 15px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
