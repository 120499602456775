<template>
  <div id="app">
    <!-- <HelloWorld /> -->
    <!-- <Test/> -->
    <!-- <PreviewModel/> -->
    <router-view/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Test from './components/slicer.vue'
import PreviewModel from './components/previewModel'
export default {
  name: 'App',
  components: {
    HelloWorld,Test,PreviewModel,
  },
  /* mounted() {
    this.params = JSON.parse(decodeURIComponent(this.$route.query.params));
      let lang = this.params.lang
    console.log('lang',this.params);
    //传过来的参数lang
    if(lang === 'en_US') {
      lang = 'en'
    } else {
      lang = 'zh'
    }
    let localLang = localStorage.getItem('lang') || 'zh'
    if(lang !== localLang) {
      localStorage.setItem('lang',lang)
      location.reload()
    }
  } */
}
</script>

<style lang="less">
/* ::-webkit-scrollbar {
  width: 0;
  height: 0;
  opacity: 0;
} */
.el-popper[x-placement^=bottom] {
  margin-top: 0 !important;
}
.el-popper .popper__arrow, .el-popper .popper__arrow::after {
  display: none !important;
  position: relative !important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background: #D71518 !important;
  color: #fff !important;
}
.el-scrollbar__wrap {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.el-scrollbar {
  height: 140px !important;
}
</style>
